/*

Bryntum Scheduler 2.2.4
Copyright(c) 2019 Bryntum AB
https://bryntum.com/contact
https://bryntum.com/license

*/
@charset "UTF-8";
.b-theme-info:before {
  content: '{"name":"Stockholm"}'; }

.b-icon-group-collapse:before, .b-grid-cell.b-group-title:before {
  content: '\F068' !important; }

.b-icon-group-expand:before, .b-grid-group-collapsed .b-group-title:before {
  content: '\F067' !important; }

.b-popup-close:before {
  content: '\F00D' !important; }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(/static/media/fa-solid-900.f960deb9.eot);
  src: url(/static/media/fa-solid-900.f960deb9.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-solid-900.6832e004.woff2) format("woff2"), url(/static/media/fa-solid-900.f4e059be.woff) format("woff"), url(/static/media/fa-solid-900.a5b65974.ttf) format("truetype"), url(/static/media/fa-solid-900.a2506b77.svg#fontawesome) format("svg"); }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.b-fa-500px:before {
  content: "\F26E"; }

.b-fa-abacus:before {
  content: "\F640"; }

.b-fa-accessible-icon:before {
  content: "\F368"; }

.b-fa-accusoft:before {
  content: "\F369"; }

.b-fa-acorn:before {
  content: "\F6AE"; }

.b-fa-acquisitions-incorporated:before {
  content: "\F6AF"; }

.b-fa-ad:before {
  content: "\F641"; }

.b-fa-address-book:before {
  content: "\F2B9"; }

.b-fa-address-card:before {
  content: "\F2BB"; }

.b-fa-adjust:before {
  content: "\F042"; }

.b-fa-adn:before {
  content: "\F170"; }

.b-fa-adobe:before {
  content: "\F778"; }

.b-fa-adversal:before {
  content: "\F36A"; }

.b-fa-affiliatetheme:before {
  content: "\F36B"; }

.b-fa-air-freshener:before {
  content: "\F5D0"; }

.b-fa-airbnb:before {
  content: "\F834"; }

.b-fa-alarm-clock:before {
  content: "\F34E"; }

.b-fa-algolia:before {
  content: "\F36C"; }

.b-fa-alicorn:before {
  content: "\F6B0"; }

.b-fa-align-center:before {
  content: "\F037"; }

.b-fa-align-justify:before {
  content: "\F039"; }

.b-fa-align-left:before {
  content: "\F036"; }

.b-fa-align-right:before {
  content: "\F038"; }

.b-fa-alipay:before {
  content: "\F642"; }

.b-fa-allergies:before {
  content: "\F461"; }

.b-fa-amazon:before {
  content: "\F270"; }

.b-fa-amazon-pay:before {
  content: "\F42C"; }

.b-fa-ambulance:before {
  content: "\F0F9"; }

.b-fa-american-sign-language-interpreting:before {
  content: "\F2A3"; }

.b-fa-amilia:before {
  content: "\F36D"; }

.b-fa-analytics:before {
  content: "\F643"; }

.b-fa-anchor:before {
  content: "\F13D"; }

.b-fa-android:before {
  content: "\F17B"; }

.b-fa-angel:before {
  content: "\F779"; }

.b-fa-angellist:before {
  content: "\F209"; }

.b-fa-angle-double-down:before {
  content: "\F103"; }

.b-fa-angle-double-left:before {
  content: "\F100"; }

.b-fa-angle-double-right:before {
  content: "\F101"; }

.b-fa-angle-double-up:before {
  content: "\F102"; }

.b-fa-angle-down:before {
  content: "\F107"; }

.b-fa-angle-left:before, .b-icon-angle-left:before, .b-icon-prev:before {
  content: "\F104"; }

.b-fa-angle-right:before, .b-icon-tree-expand:before, .b-icon-tree-collapse:before, .b-icon-angle-right:before, .b-icon-next:before {
  content: "\F105"; }

.b-fa-angle-up:before {
  content: "\F106"; }

.b-fa-angry:before {
  content: "\F556"; }

.b-fa-angrycreative:before {
  content: "\F36E"; }

.b-fa-angular:before {
  content: "\F420"; }

.b-fa-ankh:before {
  content: "\F644"; }

.b-fa-app-store:before {
  content: "\F36F"; }

.b-fa-app-store-ios:before {
  content: "\F370"; }

.b-fa-apper:before {
  content: "\F371"; }

.b-fa-apple:before {
  content: "\F179"; }

.b-fa-apple-alt:before {
  content: "\F5D1"; }

.b-fa-apple-crate:before {
  content: "\F6B1"; }

.b-fa-apple-pay:before {
  content: "\F415"; }

.b-fa-archive:before {
  content: "\F187"; }

.b-fa-archway:before {
  content: "\F557"; }

.b-fa-arrow-alt-circle-down:before {
  content: "\F358"; }

.b-fa-arrow-alt-circle-left:before {
  content: "\F359"; }

.b-fa-arrow-alt-circle-right:before {
  content: "\F35A"; }

.b-fa-arrow-alt-circle-up:before {
  content: "\F35B"; }

.b-fa-arrow-alt-down:before, .b-icon-group-desc:before {
  content: "\F354"; }

.b-fa-arrow-alt-from-bottom:before {
  content: "\F346"; }

.b-fa-arrow-alt-from-left:before {
  content: "\F347"; }

.b-fa-arrow-alt-from-right:before {
  content: "\F348"; }

.b-fa-arrow-alt-from-top:before {
  content: "\F349"; }

.b-fa-arrow-alt-left:before {
  content: "\F355"; }

.b-fa-arrow-alt-right:before, .b-icon-right:before, .b-row-reordering .b-row-reordering-target-parent .b-tree-cell::before {
  content: "\F356"; }

.b-fa-arrow-alt-square-down:before {
  content: "\F350"; }

.b-fa-arrow-alt-square-left:before {
  content: "\F351"; }

.b-fa-arrow-alt-square-right:before {
  content: "\F352"; }

.b-fa-arrow-alt-square-up:before {
  content: "\F353"; }

.b-fa-arrow-alt-to-bottom:before {
  content: "\F34A"; }

.b-fa-arrow-alt-to-left:before {
  content: "\F34B"; }

.b-fa-arrow-alt-to-right:before {
  content: "\F34C"; }

.b-fa-arrow-alt-to-top:before {
  content: "\F34D"; }

.b-fa-arrow-alt-up:before, .b-icon-group-asc:before, .b-grid-header.b-group .b-grid-header-text::after {
  content: "\F357"; }

.b-fa-arrow-circle-down:before, .b-icon-sort-desc:before {
  content: "\F0AB"; }

.b-fa-arrow-circle-left:before, .b-icon-column-move-left:before {
  content: "\F0A8"; }

.b-fa-arrow-circle-right:before, .b-icon-column-move-right:before {
  content: "\F0A9"; }

.b-fa-arrow-circle-up:before, .b-icon-sort-asc:before, .b-grid.b-sort .b-grid-header.b-sort .b-grid-header-text::after, .b-grid.b-sort:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header.b-sortable.b-depth-0:hover:not(.b-sort):not(.b-group) .b-grid-header-text::after,
.b-grid.b-sort:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header.b-sortable.b-depth-0:focus:not(.b-sort):not(.b-group) .b-grid-header-text::after {
  content: "\F0AA"; }

.b-fa-arrow-down:before, .b-icon-sort-desc:before, .b-icon-down:before {
  content: "\F063"; }

.b-fa-arrow-from-bottom:before {
  content: "\F342"; }

.b-fa-arrow-from-left:before {
  content: "\F343"; }

.b-fa-arrow-from-right:before {
  content: "\F344"; }

.b-fa-arrow-from-top:before {
  content: "\F345"; }

.b-fa-arrow-left:before {
  content: "\F060"; }

.b-fa-arrow-right:before {
  content: "\F061"; }

.b-fa-arrow-square-down:before {
  content: "\F339"; }

.b-fa-arrow-square-left:before {
  content: "\F33A"; }

.b-fa-arrow-square-right:before {
  content: "\F33B"; }

.b-fa-arrow-square-up:before {
  content: "\F33C"; }

.b-fa-arrow-to-bottom:before {
  content: "\F33D"; }

.b-fa-arrow-to-left:before {
  content: "\F33E"; }

.b-fa-arrow-to-right:before {
  content: "\F340"; }

.b-fa-arrow-to-top:before {
  content: "\F341"; }

.b-fa-arrow-up:before, .b-icon-sort-asc:before, .b-grid.b-sort .b-grid-header.b-sort .b-grid-header-text::after, .b-grid.b-sort:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header.b-sortable.b-depth-0:hover:not(.b-sort):not(.b-group) .b-grid-header-text::after,
.b-grid.b-sort:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header.b-sortable.b-depth-0:focus:not(.b-sort):not(.b-group) .b-grid-header-text::after, .b-icon-up:before {
  content: "\F062"; }

.b-fa-arrows:before {
  content: "\F047"; }

.b-fa-arrows-alt:before, .b-icon-fullscreen:before {
  content: "\F0B2"; }

.b-fa-arrows-alt-h:before {
  content: "\F337"; }

.b-fa-arrows-alt-v:before {
  content: "\F338"; }

.b-fa-arrows-h:before, .b-icon-resize-horizontal:before, .b-grid.b-column-resize.b-touch.b-column-resizing .b-grid-header.b-resizing::before, .b-moving-splitter.b-touch .b-grid-splitter::before {
  content: "\F07E"; }

.b-fa-arrows-v:before {
  content: "\F07D"; }

.b-fa-artstation:before {
  content: "\F77A"; }

.b-fa-assistive-listening-systems:before {
  content: "\F2A2"; }

.b-fa-asterisk:before {
  content: "\F069"; }

.b-fa-asymmetrik:before {
  content: "\F372"; }

.b-fa-at:before {
  content: "\F1FA"; }

.b-fa-atlas:before {
  content: "\F558"; }

.b-fa-atlassian:before {
  content: "\F77B"; }

.b-fa-atom:before {
  content: "\F5D2"; }

.b-fa-atom-alt:before {
  content: "\F5D3"; }

.b-fa-audible:before {
  content: "\F373"; }

.b-fa-audio-description:before {
  content: "\F29E"; }

.b-fa-autoprefixer:before {
  content: "\F41C"; }

.b-fa-avianex:before {
  content: "\F374"; }

.b-fa-aviato:before {
  content: "\F421"; }

.b-fa-award:before {
  content: "\F559"; }

.b-fa-aws:before {
  content: "\F375"; }

.b-fa-axe:before {
  content: "\F6B2"; }

.b-fa-axe-battle:before {
  content: "\F6B3"; }

.b-fa-baby:before {
  content: "\F77C"; }

.b-fa-baby-carriage:before {
  content: "\F77D"; }

.b-fa-backpack:before {
  content: "\F5D4"; }

.b-fa-backspace:before {
  content: "\F55A"; }

.b-fa-backward:before {
  content: "\F04A"; }

.b-fa-bacon:before {
  content: "\F7E5"; }

.b-fa-badge:before {
  content: "\F335"; }

.b-fa-badge-check:before {
  content: "\F336"; }

.b-fa-badge-dollar:before {
  content: "\F645"; }

.b-fa-badge-percent:before {
  content: "\F646"; }

.b-fa-badger-honey:before {
  content: "\F6B4"; }

.b-fa-balance-scale:before {
  content: "\F24E"; }

.b-fa-balance-scale-left:before {
  content: "\F515"; }

.b-fa-balance-scale-right:before {
  content: "\F516"; }

.b-fa-ball-pile:before {
  content: "\F77E"; }

.b-fa-ballot:before {
  content: "\F732"; }

.b-fa-ballot-check:before {
  content: "\F733"; }

.b-fa-ban:before, .b-icon-invalid:before {
  content: "\F05E"; }

.b-fa-band-aid:before {
  content: "\F462"; }

.b-fa-bandcamp:before {
  content: "\F2D5"; }

.b-fa-barcode:before {
  content: "\F02A"; }

.b-fa-barcode-alt:before {
  content: "\F463"; }

.b-fa-barcode-read:before {
  content: "\F464"; }

.b-fa-barcode-scan:before {
  content: "\F465"; }

.b-fa-bars:before {
  content: "\F0C9"; }

.b-fa-baseball:before {
  content: "\F432"; }

.b-fa-baseball-ball:before {
  content: "\F433"; }

.b-fa-basketball-ball:before {
  content: "\F434"; }

.b-fa-basketball-hoop:before {
  content: "\F435"; }

.b-fa-bat:before {
  content: "\F6B5"; }

.b-fa-bath:before {
  content: "\F2CD"; }

.b-fa-battery-bolt:before {
  content: "\F376"; }

.b-fa-battery-empty:before {
  content: "\F244"; }

.b-fa-battery-full:before {
  content: "\F240"; }

.b-fa-battery-half:before {
  content: "\F242"; }

.b-fa-battery-quarter:before {
  content: "\F243"; }

.b-fa-battery-slash:before {
  content: "\F377"; }

.b-fa-battery-three-quarters:before {
  content: "\F241"; }

.b-fa-battle-net:before {
  content: "\F835"; }

.b-fa-bed:before {
  content: "\F236"; }

.b-fa-beer:before {
  content: "\F0FC"; }

.b-fa-behance:before {
  content: "\F1B4"; }

.b-fa-behance-square:before {
  content: "\F1B5"; }

.b-fa-bell:before {
  content: "\F0F3"; }

.b-fa-bell-school:before {
  content: "\F5D5"; }

.b-fa-bell-school-slash:before {
  content: "\F5D6"; }

.b-fa-bell-slash:before {
  content: "\F1F6"; }

.b-fa-bells:before {
  content: "\F77F"; }

.b-fa-bezier-curve:before {
  content: "\F55B"; }

.b-fa-bible:before {
  content: "\F647"; }

.b-fa-bicycle:before {
  content: "\F206"; }

.b-fa-bimobject:before {
  content: "\F378"; }

.b-fa-binoculars:before {
  content: "\F1E5"; }

.b-fa-biohazard:before {
  content: "\F780"; }

.b-fa-birthday-cake:before {
  content: "\F1FD"; }

.b-fa-bitbucket:before {
  content: "\F171"; }

.b-fa-bitcoin:before {
  content: "\F379"; }

.b-fa-bity:before {
  content: "\F37A"; }

.b-fa-black-tie:before {
  content: "\F27E"; }

.b-fa-blackberry:before {
  content: "\F37B"; }

.b-fa-blanket:before {
  content: "\F498"; }

.b-fa-blender:before {
  content: "\F517"; }

.b-fa-blender-phone:before {
  content: "\F6B6"; }

.b-fa-blind:before {
  content: "\F29D"; }

.b-fa-blog:before {
  content: "\F781"; }

.b-fa-blogger:before {
  content: "\F37C"; }

.b-fa-blogger-b:before {
  content: "\F37D"; }

.b-fa-bluetooth:before {
  content: "\F293"; }

.b-fa-bluetooth-b:before {
  content: "\F294"; }

.b-fa-bold:before {
  content: "\F032"; }

.b-fa-bolt:before {
  content: "\F0E7"; }

.b-fa-bomb:before {
  content: "\F1E2"; }

.b-fa-bone:before {
  content: "\F5D7"; }

.b-fa-bone-break:before {
  content: "\F5D8"; }

.b-fa-bong:before {
  content: "\F55C"; }

.b-fa-book:before {
  content: "\F02D"; }

.b-fa-book-alt:before {
  content: "\F5D9"; }

.b-fa-book-dead:before {
  content: "\F6B7"; }

.b-fa-book-heart:before {
  content: "\F499"; }

.b-fa-book-medical:before {
  content: "\F7E6"; }

.b-fa-book-open:before {
  content: "\F518"; }

.b-fa-book-reader:before {
  content: "\F5DA"; }

.b-fa-book-spells:before {
  content: "\F6B8"; }

.b-fa-book-user:before {
  content: "\F7E7"; }

.b-fa-bookmark:before {
  content: "\F02E"; }

.b-fa-books:before {
  content: "\F5DB"; }

.b-fa-books-medical:before {
  content: "\F7E8"; }

.b-fa-boot:before {
  content: "\F782"; }

.b-fa-booth-curtain:before {
  content: "\F734"; }

.b-fa-bootstrap:before {
  content: "\F836"; }

.b-fa-bow-arrow:before {
  content: "\F6B9"; }

.b-fa-bowling-ball:before {
  content: "\F436"; }

.b-fa-bowling-pins:before {
  content: "\F437"; }

.b-fa-box:before {
  content: "\F466"; }

.b-fa-box-alt:before {
  content: "\F49A"; }

.b-fa-box-ballot:before {
  content: "\F735"; }

.b-fa-box-check:before {
  content: "\F467"; }

.b-fa-box-fragile:before {
  content: "\F49B"; }

.b-fa-box-full:before {
  content: "\F49C"; }

.b-fa-box-heart:before {
  content: "\F49D"; }

.b-fa-box-open:before {
  content: "\F49E"; }

.b-fa-box-up:before {
  content: "\F49F"; }

.b-fa-box-usd:before {
  content: "\F4A0"; }

.b-fa-boxes:before {
  content: "\F468"; }

.b-fa-boxes-alt:before {
  content: "\F4A1"; }

.b-fa-boxing-glove:before {
  content: "\F438"; }

.b-fa-brackets:before {
  content: "\F7E9"; }

.b-fa-brackets-curly:before {
  content: "\F7EA"; }

.b-fa-braille:before {
  content: "\F2A1"; }

.b-fa-brain:before {
  content: "\F5DC"; }

.b-fa-bread-loaf:before {
  content: "\F7EB"; }

.b-fa-bread-slice:before {
  content: "\F7EC"; }

.b-fa-briefcase:before {
  content: "\F0B1"; }

.b-fa-briefcase-medical:before {
  content: "\F469"; }

.b-fa-broadcast-tower:before {
  content: "\F519"; }

.b-fa-broom:before {
  content: "\F51A"; }

.b-fa-browser:before {
  content: "\F37E"; }

.b-fa-brush:before {
  content: "\F55D"; }

.b-fa-btc:before {
  content: "\F15A"; }

.b-fa-buffer:before {
  content: "\F837"; }

.b-fa-bug:before {
  content: "\F188"; }

.b-fa-building:before {
  content: "\F1AD"; }

.b-fa-bullhorn:before {
  content: "\F0A1"; }

.b-fa-bullseye:before {
  content: "\F140"; }

.b-fa-bullseye-arrow:before {
  content: "\F648"; }

.b-fa-bullseye-pointer:before {
  content: "\F649"; }

.b-fa-burn:before {
  content: "\F46A"; }

.b-fa-buromobelexperte:before {
  content: "\F37F"; }

.b-fa-burrito:before {
  content: "\F7ED"; }

.b-fa-bus:before {
  content: "\F207"; }

.b-fa-bus-alt:before {
  content: "\F55E"; }

.b-fa-bus-school:before {
  content: "\F5DD"; }

.b-fa-business-time:before {
  content: "\F64A"; }

.b-fa-buysellads:before {
  content: "\F20D"; }

.b-fa-cabinet-filing:before {
  content: "\F64B"; }

.b-fa-calculator:before {
  content: "\F1EC"; }

.b-fa-calculator-alt:before {
  content: "\F64C"; }

.b-fa-calendar:before, .b-icon-calendar:before {
  content: "\F133"; }

.b-fa-calendar-alt:before {
  content: "\F073"; }

.b-fa-calendar-check:before {
  content: "\F274"; }

.b-fa-calendar-day:before {
  content: "\F783"; }

.b-fa-calendar-edit:before {
  content: "\F333"; }

.b-fa-calendar-exclamation:before {
  content: "\F334"; }

.b-fa-calendar-minus:before {
  content: "\F272"; }

.b-fa-calendar-plus:before {
  content: "\F271"; }

.b-fa-calendar-star:before {
  content: "\F736"; }

.b-fa-calendar-times:before {
  content: "\F273"; }

.b-fa-calendar-week:before {
  content: "\F784"; }

.b-fa-camera:before {
  content: "\F030"; }

.b-fa-camera-alt:before {
  content: "\F332"; }

.b-fa-camera-retro:before {
  content: "\F083"; }

.b-fa-campfire:before {
  content: "\F6BA"; }

.b-fa-campground:before {
  content: "\F6BB"; }

.b-fa-canadian-maple-leaf:before {
  content: "\F785"; }

.b-fa-candle-holder:before {
  content: "\F6BC"; }

.b-fa-candy-cane:before {
  content: "\F786"; }

.b-fa-candy-corn:before {
  content: "\F6BD"; }

.b-fa-cannabis:before {
  content: "\F55F"; }

.b-fa-capsules:before {
  content: "\F46B"; }

.b-fa-car:before {
  content: "\F1B9"; }

.b-fa-car-alt:before {
  content: "\F5DE"; }

.b-fa-car-battery:before {
  content: "\F5DF"; }

.b-fa-car-bump:before {
  content: "\F5E0"; }

.b-fa-car-crash:before {
  content: "\F5E1"; }

.b-fa-car-garage:before {
  content: "\F5E2"; }

.b-fa-car-mechanic:before {
  content: "\F5E3"; }

.b-fa-car-side:before {
  content: "\F5E4"; }

.b-fa-car-tilt:before {
  content: "\F5E5"; }

.b-fa-car-wash:before {
  content: "\F5E6"; }

.b-fa-caret-circle-down:before {
  content: "\F32D"; }

.b-fa-caret-circle-left:before {
  content: "\F32E"; }

.b-fa-caret-circle-right:before {
  content: "\F330"; }

.b-fa-caret-circle-up:before {
  content: "\F331"; }

.b-fa-caret-down:before, .b-icon-picker:before {
  content: "\F0D7"; }

.b-fa-caret-left:before, .b-icon-collapse-gridregion:before {
  content: "\F0D9"; }

.b-fa-caret-right:before, .b-icon-expand-gridregion:before {
  content: "\F0DA"; }

.b-fa-caret-square-down:before {
  content: "\F150"; }

.b-fa-caret-square-left:before {
  content: "\F191"; }

.b-fa-caret-square-right:before {
  content: "\F152"; }

.b-fa-caret-square-up:before {
  content: "\F151"; }

.b-fa-caret-up:before {
  content: "\F0D8"; }

.b-fa-carrot:before {
  content: "\F787"; }

.b-fa-cart-arrow-down:before {
  content: "\F218"; }

.b-fa-cart-plus:before {
  content: "\F217"; }

.b-fa-cash-register:before {
  content: "\F788"; }

.b-fa-cat:before {
  content: "\F6BE"; }

.b-fa-cauldron:before {
  content: "\F6BF"; }

.b-fa-cc-amazon-pay:before {
  content: "\F42D"; }

.b-fa-cc-amex:before {
  content: "\F1F3"; }

.b-fa-cc-apple-pay:before {
  content: "\F416"; }

.b-fa-cc-diners-club:before {
  content: "\F24C"; }

.b-fa-cc-discover:before {
  content: "\F1F2"; }

.b-fa-cc-jcb:before {
  content: "\F24B"; }

.b-fa-cc-mastercard:before {
  content: "\F1F1"; }

.b-fa-cc-paypal:before {
  content: "\F1F4"; }

.b-fa-cc-stripe:before {
  content: "\F1F5"; }

.b-fa-cc-visa:before {
  content: "\F1F0"; }

.b-fa-centercode:before {
  content: "\F380"; }

.b-fa-centos:before {
  content: "\F789"; }

.b-fa-certificate:before {
  content: "\F0A3"; }

.b-fa-chair:before {
  content: "\F6C0"; }

.b-fa-chair-office:before {
  content: "\F6C1"; }

.b-fa-chalkboard:before {
  content: "\F51B"; }

.b-fa-chalkboard-teacher:before {
  content: "\F51C"; }

.b-fa-charging-station:before {
  content: "\F5E7"; }

.b-fa-chart-area:before {
  content: "\F1FE"; }

.b-fa-chart-bar:before {
  content: "\F080"; }

.b-fa-chart-line:before {
  content: "\F201"; }

.b-fa-chart-line-down:before {
  content: "\F64D"; }

.b-fa-chart-network:before {
  content: "\F78A"; }

.b-fa-chart-pie:before {
  content: "\F200"; }

.b-fa-chart-pie-alt:before {
  content: "\F64E"; }

.b-fa-chart-scatter:before {
  content: "\F7EE"; }

.b-fa-check:before, .b-icon-check:before, .b-checkbox .b-checkbox-label:before, .b-icon-valid:before {
  content: "\F00C"; }

.b-fa-check-circle:before {
  content: "\F058"; }

.b-fa-check-double:before {
  content: "\F560"; }

.b-fa-check-square:before, .b-icon-checked:before {
  content: "\F14A"; }

.b-fa-cheese:before {
  content: "\F7EF"; }

.b-fa-cheese-swiss:before {
  content: "\F7F0"; }

.b-fa-cheeseburger:before {
  content: "\F7F1"; }

.b-fa-chess:before {
  content: "\F439"; }

.b-fa-chess-bishop:before {
  content: "\F43A"; }

.b-fa-chess-bishop-alt:before {
  content: "\F43B"; }

.b-fa-chess-board:before {
  content: "\F43C"; }

.b-fa-chess-clock:before {
  content: "\F43D"; }

.b-fa-chess-clock-alt:before {
  content: "\F43E"; }

.b-fa-chess-king:before {
  content: "\F43F"; }

.b-fa-chess-king-alt:before {
  content: "\F440"; }

.b-fa-chess-knight:before {
  content: "\F441"; }

.b-fa-chess-knight-alt:before {
  content: "\F442"; }

.b-fa-chess-pawn:before {
  content: "\F443"; }

.b-fa-chess-pawn-alt:before {
  content: "\F444"; }

.b-fa-chess-queen:before {
  content: "\F445"; }

.b-fa-chess-queen-alt:before {
  content: "\F446"; }

.b-fa-chess-rook:before {
  content: "\F447"; }

.b-fa-chess-rook-alt:before {
  content: "\F448"; }

.b-fa-chevron-circle-down:before {
  content: "\F13A"; }

.b-fa-chevron-circle-left:before {
  content: "\F137"; }

.b-fa-chevron-circle-right:before {
  content: "\F138"; }

.b-fa-chevron-circle-up:before {
  content: "\F139"; }

.b-fa-chevron-double-down:before {
  content: "\F322"; }

.b-fa-chevron-double-left:before {
  content: "\F323"; }

.b-fa-chevron-double-right:before {
  content: "\F324"; }

.b-fa-chevron-double-up:before {
  content: "\F325"; }

.b-fa-chevron-down:before {
  content: "\F078"; }

.b-fa-chevron-left:before, .b-icon-filter-less:before,
.b-icon-filter-before:before {
  content: "\F053"; }

.b-fa-chevron-right:before, .b-icon-filter-more:before,
.b-icon-filter-after:before, .b-icon-sub-menu:before {
  content: "\F054"; }

.b-fa-chevron-square-down:before {
  content: "\F329"; }

.b-fa-chevron-square-left:before {
  content: "\F32A"; }

.b-fa-chevron-square-right:before {
  content: "\F32B"; }

.b-fa-chevron-square-up:before {
  content: "\F32C"; }

.b-fa-chevron-up:before {
  content: "\F077"; }

.b-fa-child:before {
  content: "\F1AE"; }

.b-fa-chimney:before {
  content: "\F78B"; }

.b-fa-chrome:before {
  content: "\F268"; }

.b-fa-chromecast:before {
  content: "\F838"; }

.b-fa-church:before {
  content: "\F51D"; }

.b-fa-circle:before, .b-icon-circle:before {
  content: "\F111"; }

.b-fa-circle-notch:before {
  content: "\F1CE"; }

.b-fa-city:before {
  content: "\F64F"; }

.b-fa-claw-marks:before {
  content: "\F6C2"; }

.b-fa-clinic-medical:before {
  content: "\F7F2"; }

.b-fa-clipboard:before, .b-icon-clipboard:before {
  content: "\F328"; }

.b-fa-clipboard-check:before {
  content: "\F46C"; }

.b-fa-clipboard-list:before {
  content: "\F46D"; }

.b-fa-clipboard-list-check:before {
  content: "\F737"; }

.b-fa-clipboard-prescription:before {
  content: "\F5E8"; }

.b-fa-clipboard-user:before {
  content: "\F7F3"; }

.b-fa-clock:before, .b-icon-clock:before {
  content: "\F017"; }

.b-fa-clone:before {
  content: "\F24D"; }

.b-fa-closed-captioning:before {
  content: "\F20A"; }

.b-fa-cloud:before {
  content: "\F0C2"; }

.b-fa-cloud-download:before {
  content: "\F0ED"; }

.b-fa-cloud-download-alt:before {
  content: "\F381"; }

.b-fa-cloud-drizzle:before {
  content: "\F738"; }

.b-fa-cloud-hail:before {
  content: "\F739"; }

.b-fa-cloud-hail-mixed:before {
  content: "\F73A"; }

.b-fa-cloud-meatball:before {
  content: "\F73B"; }

.b-fa-cloud-moon:before {
  content: "\F6C3"; }

.b-fa-cloud-moon-rain:before {
  content: "\F73C"; }

.b-fa-cloud-rain:before {
  content: "\F73D"; }

.b-fa-cloud-rainbow:before {
  content: "\F73E"; }

.b-fa-cloud-showers:before {
  content: "\F73F"; }

.b-fa-cloud-showers-heavy:before {
  content: "\F740"; }

.b-fa-cloud-sleet:before {
  content: "\F741"; }

.b-fa-cloud-snow:before {
  content: "\F742"; }

.b-fa-cloud-sun:before {
  content: "\F6C4"; }

.b-fa-cloud-sun-rain:before {
  content: "\F743"; }

.b-fa-cloud-upload:before {
  content: "\F0EE"; }

.b-fa-cloud-upload-alt:before {
  content: "\F382"; }

.b-fa-clouds:before {
  content: "\F744"; }

.b-fa-clouds-moon:before {
  content: "\F745"; }

.b-fa-clouds-sun:before {
  content: "\F746"; }

.b-fa-cloudscale:before {
  content: "\F383"; }

.b-fa-cloudsmith:before {
  content: "\F384"; }

.b-fa-cloudversify:before {
  content: "\F385"; }

.b-fa-club:before {
  content: "\F327"; }

.b-fa-cocktail:before {
  content: "\F561"; }

.b-fa-code:before, .b-icon-code:before {
  content: "\F121"; }

.b-fa-code-branch:before {
  content: "\F126"; }

.b-fa-code-commit:before {
  content: "\F386"; }

.b-fa-code-merge:before {
  content: "\F387"; }

.b-fa-codepen:before {
  content: "\F1CB"; }

.b-fa-codiepie:before {
  content: "\F284"; }

.b-fa-coffee:before {
  content: "\F0F4"; }

.b-fa-coffee-togo:before {
  content: "\F6C5"; }

.b-fa-coffin:before {
  content: "\F6C6"; }

.b-fa-cog:before {
  content: "\F013"; }

.b-fa-cogs:before {
  content: "\F085"; }

.b-fa-coins:before {
  content: "\F51E"; }

.b-fa-columns:before, .b-icon-columns:before {
  content: "\F0DB"; }

.b-fa-comment:before {
  content: "\F075"; }

.b-fa-comment-alt:before {
  content: "\F27A"; }

.b-fa-comment-alt-check:before {
  content: "\F4A2"; }

.b-fa-comment-alt-dollar:before {
  content: "\F650"; }

.b-fa-comment-alt-dots:before {
  content: "\F4A3"; }

.b-fa-comment-alt-edit:before {
  content: "\F4A4"; }

.b-fa-comment-alt-exclamation:before {
  content: "\F4A5"; }

.b-fa-comment-alt-lines:before {
  content: "\F4A6"; }

.b-fa-comment-alt-medical:before {
  content: "\F7F4"; }

.b-fa-comment-alt-minus:before {
  content: "\F4A7"; }

.b-fa-comment-alt-plus:before {
  content: "\F4A8"; }

.b-fa-comment-alt-slash:before {
  content: "\F4A9"; }

.b-fa-comment-alt-smile:before {
  content: "\F4AA"; }

.b-fa-comment-alt-times:before {
  content: "\F4AB"; }

.b-fa-comment-check:before {
  content: "\F4AC"; }

.b-fa-comment-dollar:before {
  content: "\F651"; }

.b-fa-comment-dots:before {
  content: "\F4AD"; }

.b-fa-comment-edit:before {
  content: "\F4AE"; }

.b-fa-comment-exclamation:before {
  content: "\F4AF"; }

.b-fa-comment-lines:before {
  content: "\F4B0"; }

.b-fa-comment-medical:before {
  content: "\F7F5"; }

.b-fa-comment-minus:before {
  content: "\F4B1"; }

.b-fa-comment-plus:before {
  content: "\F4B2"; }

.b-fa-comment-slash:before {
  content: "\F4B3"; }

.b-fa-comment-smile:before {
  content: "\F4B4"; }

.b-fa-comment-times:before {
  content: "\F4B5"; }

.b-fa-comments:before {
  content: "\F086"; }

.b-fa-comments-alt:before {
  content: "\F4B6"; }

.b-fa-comments-alt-dollar:before {
  content: "\F652"; }

.b-fa-comments-dollar:before {
  content: "\F653"; }

.b-fa-compact-disc:before {
  content: "\F51F"; }

.b-fa-compass:before {
  content: "\F14E"; }

.b-fa-compass-slash:before {
  content: "\F5E9"; }

.b-fa-compress:before {
  content: "\F066"; }

.b-fa-compress-alt:before {
  content: "\F422"; }

.b-fa-compress-arrows-alt:before {
  content: "\F78C"; }

.b-fa-compress-wide:before {
  content: "\F326"; }

.b-fa-concierge-bell:before {
  content: "\F562"; }

.b-fa-confluence:before {
  content: "\F78D"; }

.b-fa-connectdevelop:before {
  content: "\F20E"; }

.b-fa-container-storage:before {
  content: "\F4B7"; }

.b-fa-contao:before {
  content: "\F26D"; }

.b-fa-conveyor-belt:before {
  content: "\F46E"; }

.b-fa-conveyor-belt-alt:before {
  content: "\F46F"; }

.b-fa-cookie:before {
  content: "\F563"; }

.b-fa-cookie-bite:before {
  content: "\F564"; }

.b-fa-copy:before {
  content: "\F0C5"; }

.b-fa-copyright:before {
  content: "\F1F9"; }

.b-fa-corn:before {
  content: "\F6C7"; }

.b-fa-couch:before {
  content: "\F4B8"; }

.b-fa-cow:before {
  content: "\F6C8"; }

.b-fa-cpanel:before {
  content: "\F388"; }

.b-fa-creative-commons:before {
  content: "\F25E"; }

.b-fa-creative-commons-by:before {
  content: "\F4E7"; }

.b-fa-creative-commons-nc:before {
  content: "\F4E8"; }

.b-fa-creative-commons-nc-eu:before {
  content: "\F4E9"; }

.b-fa-creative-commons-nc-jp:before {
  content: "\F4EA"; }

.b-fa-creative-commons-nd:before {
  content: "\F4EB"; }

.b-fa-creative-commons-pd:before {
  content: "\F4EC"; }

.b-fa-creative-commons-pd-alt:before {
  content: "\F4ED"; }

.b-fa-creative-commons-remix:before {
  content: "\F4EE"; }

.b-fa-creative-commons-sa:before {
  content: "\F4EF"; }

.b-fa-creative-commons-sampling:before {
  content: "\F4F0"; }

.b-fa-creative-commons-sampling-plus:before {
  content: "\F4F1"; }

.b-fa-creative-commons-share:before {
  content: "\F4F2"; }

.b-fa-creative-commons-zero:before {
  content: "\F4F3"; }

.b-fa-credit-card:before {
  content: "\F09D"; }

.b-fa-credit-card-blank:before {
  content: "\F389"; }

.b-fa-credit-card-front:before {
  content: "\F38A"; }

.b-fa-cricket:before {
  content: "\F449"; }

.b-fa-critical-role:before {
  content: "\F6C9"; }

.b-fa-croissant:before {
  content: "\F7F6"; }

.b-fa-crop:before {
  content: "\F125"; }

.b-fa-crop-alt:before {
  content: "\F565"; }

.b-fa-cross:before {
  content: "\F654"; }

.b-fa-crosshairs:before {
  content: "\F05B"; }

.b-fa-crow:before {
  content: "\F520"; }

.b-fa-crown:before {
  content: "\F521"; }

.b-fa-crutch:before {
  content: "\F7F7"; }

.b-fa-crutches:before {
  content: "\F7F8"; }

.b-fa-css3:before {
  content: "\F13C"; }

.b-fa-css3-alt:before {
  content: "\F38B"; }

.b-fa-cube:before {
  content: "\F1B2"; }

.b-fa-cubes:before {
  content: "\F1B3"; }

.b-fa-curling:before {
  content: "\F44A"; }

.b-fa-cut:before {
  content: "\F0C4"; }

.b-fa-cuttlefish:before {
  content: "\F38C"; }

.b-fa-d-and-d:before {
  content: "\F38D"; }

.b-fa-d-and-d-beyond:before {
  content: "\F6CA"; }

.b-fa-dagger:before {
  content: "\F6CB"; }

.b-fa-dashcube:before {
  content: "\F210"; }

.b-fa-database:before {
  content: "\F1C0"; }

.b-fa-deaf:before {
  content: "\F2A4"; }

.b-fa-debug:before {
  content: "\F7F9"; }

.b-fa-deer:before {
  content: "\F78E"; }

.b-fa-deer-rudolph:before {
  content: "\F78F"; }

.b-fa-delicious:before {
  content: "\F1A5"; }

.b-fa-democrat:before {
  content: "\F747"; }

.b-fa-deploydog:before {
  content: "\F38E"; }

.b-fa-deskpro:before {
  content: "\F38F"; }

.b-fa-desktop:before {
  content: "\F108"; }

.b-fa-desktop-alt:before {
  content: "\F390"; }

.b-fa-dev:before {
  content: "\F6CC"; }

.b-fa-deviantart:before {
  content: "\F1BD"; }

.b-fa-dewpoint:before {
  content: "\F748"; }

.b-fa-dharmachakra:before {
  content: "\F655"; }

.b-fa-dhl:before {
  content: "\F790"; }

.b-fa-diagnoses:before {
  content: "\F470"; }

.b-fa-diamond:before {
  content: "\F219"; }

.b-fa-diaspora:before {
  content: "\F791"; }

.b-fa-dice:before {
  content: "\F522"; }

.b-fa-dice-d10:before {
  content: "\F6CD"; }

.b-fa-dice-d12:before {
  content: "\F6CE"; }

.b-fa-dice-d20:before {
  content: "\F6CF"; }

.b-fa-dice-d4:before {
  content: "\F6D0"; }

.b-fa-dice-d6:before {
  content: "\F6D1"; }

.b-fa-dice-d8:before {
  content: "\F6D2"; }

.b-fa-dice-five:before {
  content: "\F523"; }

.b-fa-dice-four:before {
  content: "\F524"; }

.b-fa-dice-one:before {
  content: "\F525"; }

.b-fa-dice-six:before {
  content: "\F526"; }

.b-fa-dice-three:before {
  content: "\F527"; }

.b-fa-dice-two:before {
  content: "\F528"; }

.b-fa-digg:before {
  content: "\F1A6"; }

.b-fa-digital-ocean:before {
  content: "\F391"; }

.b-fa-digital-tachograph:before {
  content: "\F566"; }

.b-fa-diploma:before {
  content: "\F5EA"; }

.b-fa-directions:before {
  content: "\F5EB"; }

.b-fa-discord:before {
  content: "\F392"; }

.b-fa-discourse:before {
  content: "\F393"; }

.b-fa-disease:before {
  content: "\F7FA"; }

.b-fa-divide:before {
  content: "\F529"; }

.b-fa-dizzy:before {
  content: "\F567"; }

.b-fa-dna:before {
  content: "\F471"; }

.b-fa-do-not-enter:before {
  content: "\F5EC"; }

.b-fa-dochub:before {
  content: "\F394"; }

.b-fa-docker:before {
  content: "\F395"; }

.b-fa-dog:before {
  content: "\F6D3"; }

.b-fa-dog-leashed:before {
  content: "\F6D4"; }

.b-fa-dollar-sign:before {
  content: "\F155"; }

.b-fa-dolly:before {
  content: "\F472"; }

.b-fa-dolly-empty:before {
  content: "\F473"; }

.b-fa-dolly-flatbed:before {
  content: "\F474"; }

.b-fa-dolly-flatbed-alt:before {
  content: "\F475"; }

.b-fa-dolly-flatbed-empty:before {
  content: "\F476"; }

.b-fa-donate:before {
  content: "\F4B9"; }

.b-fa-door-closed:before {
  content: "\F52A"; }

.b-fa-door-open:before {
  content: "\F52B"; }

.b-fa-dot-circle:before {
  content: "\F192"; }

.b-fa-dove:before {
  content: "\F4BA"; }

.b-fa-download:before {
  content: "\F019"; }

.b-fa-draft2digital:before {
  content: "\F396"; }

.b-fa-drafting-compass:before {
  content: "\F568"; }

.b-fa-dragon:before {
  content: "\F6D5"; }

.b-fa-draw-circle:before {
  content: "\F5ED"; }

.b-fa-draw-polygon:before {
  content: "\F5EE"; }

.b-fa-draw-square:before {
  content: "\F5EF"; }

.b-fa-dreidel:before {
  content: "\F792"; }

.b-fa-dribbble:before {
  content: "\F17D"; }

.b-fa-dribbble-square:before {
  content: "\F397"; }

.b-fa-dropbox:before {
  content: "\F16B"; }

.b-fa-drum:before {
  content: "\F569"; }

.b-fa-drum-steelpan:before {
  content: "\F56A"; }

.b-fa-drumstick:before {
  content: "\F6D6"; }

.b-fa-drumstick-bite:before {
  content: "\F6D7"; }

.b-fa-drupal:before {
  content: "\F1A9"; }

.b-fa-duck:before {
  content: "\F6D8"; }

.b-fa-dumbbell:before {
  content: "\F44B"; }

.b-fa-dumpster:before {
  content: "\F793"; }

.b-fa-dumpster-fire:before {
  content: "\F794"; }

.b-fa-dungeon:before {
  content: "\F6D9"; }

.b-fa-dyalog:before {
  content: "\F399"; }

.b-fa-ear:before {
  content: "\F5F0"; }

.b-fa-ear-muffs:before {
  content: "\F795"; }

.b-fa-earlybirds:before {
  content: "\F39A"; }

.b-fa-ebay:before {
  content: "\F4F4"; }

.b-fa-eclipse:before {
  content: "\F749"; }

.b-fa-eclipse-alt:before {
  content: "\F74A"; }

.b-fa-edge:before {
  content: "\F282"; }

.b-fa-edit:before {
  content: "\F044"; }

.b-fa-egg:before {
  content: "\F7FB"; }

.b-fa-egg-fried:before {
  content: "\F7FC"; }

.b-fa-eject:before {
  content: "\F052"; }

.b-fa-elementor:before {
  content: "\F430"; }

.b-fa-elephant:before {
  content: "\F6DA"; }

.b-fa-ellipsis-h:before {
  content: "\F141"; }

.b-fa-ellipsis-h-alt:before {
  content: "\F39B"; }

.b-fa-ellipsis-v:before {
  content: "\F142"; }

.b-fa-ellipsis-v-alt:before {
  content: "\F39C"; }

.b-fa-ello:before {
  content: "\F5F1"; }

.b-fa-ember:before {
  content: "\F423"; }

.b-fa-empire:before {
  content: "\F1D1"; }

.b-fa-empty-set:before {
  content: "\F656"; }

.b-fa-engine-warning:before {
  content: "\F5F2"; }

.b-fa-envelope:before {
  content: "\F0E0"; }

.b-fa-envelope-open:before {
  content: "\F2B6"; }

.b-fa-envelope-open-dollar:before {
  content: "\F657"; }

.b-fa-envelope-open-text:before {
  content: "\F658"; }

.b-fa-envelope-square:before {
  content: "\F199"; }

.b-fa-envira:before {
  content: "\F299"; }

.b-fa-equals:before {
  content: "\F52C"; }

.b-fa-eraser:before {
  content: "\F12D"; }

.b-fa-erlang:before {
  content: "\F39D"; }

.b-fa-ethereum:before {
  content: "\F42E"; }

.b-fa-ethernet:before {
  content: "\F796"; }

.b-fa-etsy:before {
  content: "\F2D7"; }

.b-fa-euro-sign:before {
  content: "\F153"; }

.b-fa-evernote:before {
  content: "\F839"; }

.b-fa-exchange:before {
  content: "\F0EC"; }

.b-fa-exchange-alt:before {
  content: "\F362"; }

.b-fa-exclamation:before {
  content: "\F12A"; }

.b-fa-exclamation-circle:before {
  content: "\F06A"; }

.b-fa-exclamation-square:before {
  content: "\F321"; }

.b-fa-exclamation-triangle:before, .b-icon-warning:before {
  content: "\F071"; }

.b-fa-expand:before {
  content: "\F065"; }

.b-fa-expand-alt:before {
  content: "\F424"; }

.b-fa-expand-arrows:before {
  content: "\F31D"; }

.b-fa-expand-arrows-alt:before {
  content: "\F31E"; }

.b-fa-expand-wide:before {
  content: "\F320"; }

.b-fa-expeditedssl:before {
  content: "\F23E"; }

.b-fa-external-link:before {
  content: "\F08E"; }

.b-fa-external-link-alt:before {
  content: "\F35D"; }

.b-fa-external-link-square:before {
  content: "\F14C"; }

.b-fa-external-link-square-alt:before {
  content: "\F360"; }

.b-fa-eye:before {
  content: "\F06E"; }

.b-fa-eye-dropper:before {
  content: "\F1FB"; }

.b-fa-eye-evil:before {
  content: "\F6DB"; }

.b-fa-eye-slash:before {
  content: "\F070"; }

.b-fa-facebook:before {
  content: "\F09A"; }

.b-fa-facebook-f:before {
  content: "\F39E"; }

.b-fa-facebook-messenger:before {
  content: "\F39F"; }

.b-fa-facebook-square:before {
  content: "\F082"; }

.b-fa-fantasy-flight-games:before {
  content: "\F6DC"; }

.b-fa-fast-backward:before {
  content: "\F049"; }

.b-fa-fast-forward:before {
  content: "\F050"; }

.b-fa-fax:before {
  content: "\F1AC"; }

.b-fa-feather:before {
  content: "\F52D"; }

.b-fa-feather-alt:before {
  content: "\F56B"; }

.b-fa-fedex:before {
  content: "\F797"; }

.b-fa-fedora:before {
  content: "\F798"; }

.b-fa-female:before {
  content: "\F182"; }

.b-fa-field-hockey:before {
  content: "\F44C"; }

.b-fa-fighter-jet:before {
  content: "\F0FB"; }

.b-fa-figma:before {
  content: "\F799"; }

.b-fa-file:before {
  content: "\F15B"; }

.b-fa-file-alt:before {
  content: "\F15C"; }

.b-fa-file-archive:before {
  content: "\F1C6"; }

.b-fa-file-audio:before {
  content: "\F1C7"; }

.b-fa-file-certificate:before {
  content: "\F5F3"; }

.b-fa-file-chart-line:before {
  content: "\F659"; }

.b-fa-file-chart-pie:before {
  content: "\F65A"; }

.b-fa-file-check:before {
  content: "\F316"; }

.b-fa-file-code:before {
  content: "\F1C9"; }

.b-fa-file-contract:before {
  content: "\F56C"; }

.b-fa-file-csv:before {
  content: "\F6DD"; }

.b-fa-file-download:before {
  content: "\F56D"; }

.b-fa-file-edit:before {
  content: "\F31C"; }

.b-fa-file-excel:before {
  content: "\F1C3"; }

.b-fa-file-exclamation:before {
  content: "\F31A"; }

.b-fa-file-export:before {
  content: "\F56E"; }

.b-fa-file-image:before {
  content: "\F1C5"; }

.b-fa-file-import:before {
  content: "\F56F"; }

.b-fa-file-invoice:before {
  content: "\F570"; }

.b-fa-file-invoice-dollar:before {
  content: "\F571"; }

.b-fa-file-medical:before {
  content: "\F477"; }

.b-fa-file-medical-alt:before {
  content: "\F478"; }

.b-fa-file-minus:before {
  content: "\F318"; }

.b-fa-file-pdf:before {
  content: "\F1C1"; }

.b-fa-file-plus:before {
  content: "\F319"; }

.b-fa-file-powerpoint:before {
  content: "\F1C4"; }

.b-fa-file-prescription:before {
  content: "\F572"; }

.b-fa-file-signature:before {
  content: "\F573"; }

.b-fa-file-spreadsheet:before {
  content: "\F65B"; }

.b-fa-file-times:before {
  content: "\F317"; }

.b-fa-file-upload:before {
  content: "\F574"; }

.b-fa-file-user:before {
  content: "\F65C"; }

.b-fa-file-video:before {
  content: "\F1C8"; }

.b-fa-file-word:before {
  content: "\F1C2"; }

.b-fa-files-medical:before {
  content: "\F7FD"; }

.b-fa-fill:before {
  content: "\F575"; }

.b-fa-fill-drip:before {
  content: "\F576"; }

.b-fa-film:before {
  content: "\F008"; }

.b-fa-film-alt:before {
  content: "\F3A0"; }

.b-fa-filter:before, .b-icon-filter:before, .b-grid-header .b-filter-icon::after,
.b-icon-filter-equal:before {
  content: "\F0B0"; }

.b-fa-fingerprint:before {
  content: "\F577"; }

.b-fa-fire:before {
  content: "\F06D"; }

.b-fa-fire-alt:before {
  content: "\F7E4"; }

.b-fa-fire-extinguisher:before {
  content: "\F134"; }

.b-fa-fire-smoke:before {
  content: "\F74B"; }

.b-fa-firefox:before {
  content: "\F269"; }

.b-fa-fireplace:before {
  content: "\F79A"; }

.b-fa-first-aid:before {
  content: "\F479"; }

.b-fa-first-order:before {
  content: "\F2B0"; }

.b-fa-first-order-alt:before {
  content: "\F50A"; }

.b-fa-firstdraft:before {
  content: "\F3A1"; }

.b-fa-fish:before {
  content: "\F578"; }

.b-fa-fish-cooked:before {
  content: "\F7FE"; }

.b-fa-fist-raised:before {
  content: "\F6DE"; }

.b-fa-flag:before {
  content: "\F024"; }

.b-fa-flag-alt:before {
  content: "\F74C"; }

.b-fa-flag-checkered:before {
  content: "\F11E"; }

.b-fa-flag-usa:before {
  content: "\F74D"; }

.b-fa-flame:before {
  content: "\F6DF"; }

.b-fa-flask:before {
  content: "\F0C3"; }

.b-fa-flask-poison:before {
  content: "\F6E0"; }

.b-fa-flask-potion:before {
  content: "\F6E1"; }

.b-fa-flickr:before {
  content: "\F16E"; }

.b-fa-flipboard:before {
  content: "\F44D"; }

.b-fa-flower:before {
  content: "\F7FF"; }

.b-fa-flower-daffodil:before {
  content: "\F800"; }

.b-fa-flower-tulip:before {
  content: "\F801"; }

.b-fa-flushed:before {
  content: "\F579"; }

.b-fa-fly:before {
  content: "\F417"; }

.b-fa-fog:before {
  content: "\F74E"; }

.b-fa-folder:before, .b-icon-tree-folder:before {
  content: "\F07B"; }

.b-fa-folder-minus:before {
  content: "\F65D"; }

.b-fa-folder-open:before, .b-icon-tree-folder-open:before {
  content: "\F07C"; }

.b-fa-folder-plus:before {
  content: "\F65E"; }

.b-fa-folder-times:before {
  content: "\F65F"; }

.b-fa-folder-tree:before {
  content: "\F802"; }

.b-fa-folders:before {
  content: "\F660"; }

.b-fa-font:before {
  content: "\F031"; }

.b-fa-font-awesome:before {
  content: "\F2B4"; }

.b-fa-font-awesome-alt:before {
  content: "\F35C"; }

.b-fa-font-awesome-flag:before {
  content: "\F425"; }

.b-fa-font-awesome-logo-full:before {
  content: "\F4E6"; }

.b-fa-fonticons:before {
  content: "\F280"; }

.b-fa-fonticons-fi:before {
  content: "\F3A2"; }

.b-fa-football-ball:before {
  content: "\F44E"; }

.b-fa-football-helmet:before {
  content: "\F44F"; }

.b-fa-forklift:before {
  content: "\F47A"; }

.b-fa-fort-awesome:before {
  content: "\F286"; }

.b-fa-fort-awesome-alt:before {
  content: "\F3A3"; }

.b-fa-forumbee:before {
  content: "\F211"; }

.b-fa-forward:before {
  content: "\F04E"; }

.b-fa-foursquare:before {
  content: "\F180"; }

.b-fa-fragile:before {
  content: "\F4BB"; }

.b-fa-free-code-camp:before {
  content: "\F2C5"; }

.b-fa-freebsd:before {
  content: "\F3A4"; }

.b-fa-french-fries:before {
  content: "\F803"; }

.b-fa-frog:before {
  content: "\F52E"; }

.b-fa-frosty-head:before {
  content: "\F79B"; }

.b-fa-frown:before {
  content: "\F119"; }

.b-fa-frown-open:before {
  content: "\F57A"; }

.b-fa-fulcrum:before {
  content: "\F50B"; }

.b-fa-function:before {
  content: "\F661"; }

.b-fa-funnel-dollar:before {
  content: "\F662"; }

.b-fa-futbol:before {
  content: "\F1E3"; }

.b-fa-galactic-republic:before {
  content: "\F50C"; }

.b-fa-galactic-senate:before {
  content: "\F50D"; }

.b-fa-gamepad:before {
  content: "\F11B"; }

.b-fa-gas-pump:before {
  content: "\F52F"; }

.b-fa-gas-pump-slash:before {
  content: "\F5F4"; }

.b-fa-gavel:before {
  content: "\F0E3"; }

.b-fa-gem:before {
  content: "\F3A5"; }

.b-fa-genderless:before {
  content: "\F22D"; }

.b-fa-get-pocket:before {
  content: "\F265"; }

.b-fa-gg:before {
  content: "\F260"; }

.b-fa-gg-circle:before {
  content: "\F261"; }

.b-fa-ghost:before {
  content: "\F6E2"; }

.b-fa-gift:before {
  content: "\F06B"; }

.b-fa-gift-card:before {
  content: "\F663"; }

.b-fa-gifts:before {
  content: "\F79C"; }

.b-fa-gingerbread-man:before {
  content: "\F79D"; }

.b-fa-git:before {
  content: "\F1D3"; }

.b-fa-git-alt:before {
  content: "\F841"; }

.b-fa-git-square:before {
  content: "\F1D2"; }

.b-fa-github:before {
  content: "\F09B"; }

.b-fa-github-alt:before {
  content: "\F113"; }

.b-fa-github-square:before {
  content: "\F092"; }

.b-fa-gitkraken:before {
  content: "\F3A6"; }

.b-fa-gitlab:before {
  content: "\F296"; }

.b-fa-gitter:before {
  content: "\F426"; }

.b-fa-glass:before {
  content: "\F804"; }

.b-fa-glass-champagne:before {
  content: "\F79E"; }

.b-fa-glass-cheers:before {
  content: "\F79F"; }

.b-fa-glass-martini:before {
  content: "\F000"; }

.b-fa-glass-martini-alt:before {
  content: "\F57B"; }

.b-fa-glass-whiskey:before {
  content: "\F7A0"; }

.b-fa-glass-whiskey-rocks:before {
  content: "\F7A1"; }

.b-fa-glasses:before {
  content: "\F530"; }

.b-fa-glasses-alt:before {
  content: "\F5F5"; }

.b-fa-glide:before {
  content: "\F2A5"; }

.b-fa-glide-g:before {
  content: "\F2A6"; }

.b-fa-globe:before {
  content: "\F0AC"; }

.b-fa-globe-africa:before {
  content: "\F57C"; }

.b-fa-globe-americas:before {
  content: "\F57D"; }

.b-fa-globe-asia:before {
  content: "\F57E"; }

.b-fa-globe-europe:before {
  content: "\F7A2"; }

.b-fa-globe-snow:before {
  content: "\F7A3"; }

.b-fa-globe-stand:before {
  content: "\F5F6"; }

.b-fa-gofore:before {
  content: "\F3A7"; }

.b-fa-golf-ball:before {
  content: "\F450"; }

.b-fa-golf-club:before {
  content: "\F451"; }

.b-fa-goodreads:before {
  content: "\F3A8"; }

.b-fa-goodreads-g:before {
  content: "\F3A9"; }

.b-fa-google:before {
  content: "\F1A0"; }

.b-fa-google-drive:before {
  content: "\F3AA"; }

.b-fa-google-play:before {
  content: "\F3AB"; }

.b-fa-google-plus:before {
  content: "\F2B3"; }

.b-fa-google-plus-g:before {
  content: "\F0D5"; }

.b-fa-google-plus-square:before {
  content: "\F0D4"; }

.b-fa-google-wallet:before {
  content: "\F1EE"; }

.b-fa-gopuram:before {
  content: "\F664"; }

.b-fa-graduation-cap:before {
  content: "\F19D"; }

.b-fa-gratipay:before {
  content: "\F184"; }

.b-fa-grav:before {
  content: "\F2D6"; }

.b-fa-greater-than:before {
  content: "\F531"; }

.b-fa-greater-than-equal:before {
  content: "\F532"; }

.b-fa-grimace:before {
  content: "\F57F"; }

.b-fa-grin:before {
  content: "\F580"; }

.b-fa-grin-alt:before {
  content: "\F581"; }

.b-fa-grin-beam:before {
  content: "\F582"; }

.b-fa-grin-beam-sweat:before {
  content: "\F583"; }

.b-fa-grin-hearts:before {
  content: "\F584"; }

.b-fa-grin-squint:before {
  content: "\F585"; }

.b-fa-grin-squint-tears:before {
  content: "\F586"; }

.b-fa-grin-stars:before {
  content: "\F587"; }

.b-fa-grin-tears:before {
  content: "\F588"; }

.b-fa-grin-tongue:before {
  content: "\F589"; }

.b-fa-grin-tongue-squint:before {
  content: "\F58A"; }

.b-fa-grin-tongue-wink:before {
  content: "\F58B"; }

.b-fa-grin-wink:before {
  content: "\F58C"; }

.b-fa-grip-horizontal:before {
  content: "\F58D"; }

.b-fa-grip-lines:before {
  content: "\F7A4"; }

.b-fa-grip-lines-vertical:before {
  content: "\F7A5"; }

.b-fa-grip-vertical:before {
  content: "\F58E"; }

.b-fa-gripfire:before {
  content: "\F3AC"; }

.b-fa-grunt:before {
  content: "\F3AD"; }

.b-fa-guitar:before {
  content: "\F7A6"; }

.b-fa-gulp:before {
  content: "\F3AE"; }

.b-fa-h-square:before {
  content: "\F0FD"; }

.b-fa-h1:before {
  content: "\F313"; }

.b-fa-h2:before {
  content: "\F314"; }

.b-fa-h3:before {
  content: "\F315"; }

.b-fa-hacker-news:before {
  content: "\F1D4"; }

.b-fa-hacker-news-square:before {
  content: "\F3AF"; }

.b-fa-hackerrank:before {
  content: "\F5F7"; }

.b-fa-hamburger:before {
  content: "\F805"; }

.b-fa-hammer:before {
  content: "\F6E3"; }

.b-fa-hammer-war:before {
  content: "\F6E4"; }

.b-fa-hamsa:before {
  content: "\F665"; }

.b-fa-hand-heart:before {
  content: "\F4BC"; }

.b-fa-hand-holding:before {
  content: "\F4BD"; }

.b-fa-hand-holding-box:before {
  content: "\F47B"; }

.b-fa-hand-holding-heart:before {
  content: "\F4BE"; }

.b-fa-hand-holding-magic:before {
  content: "\F6E5"; }

.b-fa-hand-holding-seedling:before {
  content: "\F4BF"; }

.b-fa-hand-holding-usd:before {
  content: "\F4C0"; }

.b-fa-hand-holding-water:before {
  content: "\F4C1"; }

.b-fa-hand-lizard:before {
  content: "\F258"; }

.b-fa-hand-middle-finger:before {
  content: "\F806"; }

.b-fa-hand-paper:before {
  content: "\F256"; }

.b-fa-hand-peace:before {
  content: "\F25B"; }

.b-fa-hand-point-down:before {
  content: "\F0A7"; }

.b-fa-hand-point-left:before {
  content: "\F0A5"; }

.b-fa-hand-point-right:before {
  content: "\F0A4"; }

.b-fa-hand-point-up:before {
  content: "\F0A6"; }

.b-fa-hand-pointer:before {
  content: "\F25A"; }

.b-fa-hand-receiving:before {
  content: "\F47C"; }

.b-fa-hand-rock:before {
  content: "\F255"; }

.b-fa-hand-scissors:before {
  content: "\F257"; }

.b-fa-hand-spock:before {
  content: "\F259"; }

.b-fa-hands:before {
  content: "\F4C2"; }

.b-fa-hands-heart:before {
  content: "\F4C3"; }

.b-fa-hands-helping:before {
  content: "\F4C4"; }

.b-fa-hands-usd:before {
  content: "\F4C5"; }

.b-fa-handshake:before {
  content: "\F2B5"; }

.b-fa-handshake-alt:before {
  content: "\F4C6"; }

.b-fa-hanukiah:before {
  content: "\F6E6"; }

.b-fa-hard-hat:before {
  content: "\F807"; }

.b-fa-hashtag:before {
  content: "\F292"; }

.b-fa-hat-santa:before {
  content: "\F7A7"; }

.b-fa-hat-winter:before {
  content: "\F7A8"; }

.b-fa-hat-witch:before {
  content: "\F6E7"; }

.b-fa-hat-wizard:before {
  content: "\F6E8"; }

.b-fa-haykal:before {
  content: "\F666"; }

.b-fa-hdd:before {
  content: "\F0A0"; }

.b-fa-head-side:before {
  content: "\F6E9"; }

.b-fa-head-side-brain:before {
  content: "\F808"; }

.b-fa-head-side-medical:before {
  content: "\F809"; }

.b-fa-head-vr:before {
  content: "\F6EA"; }

.b-fa-heading:before {
  content: "\F1DC"; }

.b-fa-headphones:before {
  content: "\F025"; }

.b-fa-headphones-alt:before {
  content: "\F58F"; }

.b-fa-headset:before {
  content: "\F590"; }

.b-fa-heart:before {
  content: "\F004"; }

.b-fa-heart-broken:before {
  content: "\F7A9"; }

.b-fa-heart-circle:before {
  content: "\F4C7"; }

.b-fa-heart-rate:before {
  content: "\F5F8"; }

.b-fa-heart-square:before {
  content: "\F4C8"; }

.b-fa-heartbeat:before {
  content: "\F21E"; }

.b-fa-helicopter:before {
  content: "\F533"; }

.b-fa-helmet-battle:before {
  content: "\F6EB"; }

.b-fa-hexagon:before {
  content: "\F312"; }

.b-fa-highlighter:before {
  content: "\F591"; }

.b-fa-hiking:before {
  content: "\F6EC"; }

.b-fa-hippo:before {
  content: "\F6ED"; }

.b-fa-hips:before {
  content: "\F452"; }

.b-fa-hire-a-helper:before {
  content: "\F3B0"; }

.b-fa-history:before {
  content: "\F1DA"; }

.b-fa-hockey-mask:before {
  content: "\F6EE"; }

.b-fa-hockey-puck:before {
  content: "\F453"; }

.b-fa-hockey-sticks:before {
  content: "\F454"; }

.b-fa-holly-berry:before {
  content: "\F7AA"; }

.b-fa-home:before {
  content: "\F015"; }

.b-fa-home-alt:before {
  content: "\F80A"; }

.b-fa-home-heart:before {
  content: "\F4C9"; }

.b-fa-home-lg:before {
  content: "\F80B"; }

.b-fa-home-lg-alt:before {
  content: "\F80C"; }

.b-fa-hood-cloak:before {
  content: "\F6EF"; }

.b-fa-hooli:before {
  content: "\F427"; }

.b-fa-hornbill:before {
  content: "\F592"; }

.b-fa-horse:before {
  content: "\F6F0"; }

.b-fa-horse-head:before {
  content: "\F7AB"; }

.b-fa-hospital:before {
  content: "\F0F8"; }

.b-fa-hospital-alt:before {
  content: "\F47D"; }

.b-fa-hospital-symbol:before {
  content: "\F47E"; }

.b-fa-hospital-user:before {
  content: "\F80D"; }

.b-fa-hospitals:before {
  content: "\F80E"; }

.b-fa-hot-tub:before {
  content: "\F593"; }

.b-fa-hotdog:before {
  content: "\F80F"; }

.b-fa-hotel:before {
  content: "\F594"; }

.b-fa-hotjar:before {
  content: "\F3B1"; }

.b-fa-hourglass:before {
  content: "\F254"; }

.b-fa-hourglass-end:before {
  content: "\F253"; }

.b-fa-hourglass-half:before {
  content: "\F252"; }

.b-fa-hourglass-start:before {
  content: "\F251"; }

.b-fa-house-damage:before {
  content: "\F6F1"; }

.b-fa-house-flood:before {
  content: "\F74F"; }

.b-fa-houzz:before {
  content: "\F27C"; }

.b-fa-hryvnia:before {
  content: "\F6F2"; }

.b-fa-html5:before {
  content: "\F13B"; }

.b-fa-hubspot:before {
  content: "\F3B2"; }

.b-fa-humidity:before {
  content: "\F750"; }

.b-fa-hurricane:before {
  content: "\F751"; }

.b-fa-i-cursor:before {
  content: "\F246"; }

.b-fa-ice-cream:before {
  content: "\F810"; }

.b-fa-ice-skate:before {
  content: "\F7AC"; }

.b-fa-icicles:before {
  content: "\F7AD"; }

.b-fa-id-badge:before {
  content: "\F2C1"; }

.b-fa-id-card:before {
  content: "\F2C2"; }

.b-fa-id-card-alt:before {
  content: "\F47F"; }

.b-fa-igloo:before {
  content: "\F7AE"; }

.b-fa-image:before {
  content: "\F03E"; }

.b-fa-images:before {
  content: "\F302"; }

.b-fa-imdb:before {
  content: "\F2D8"; }

.b-fa-inbox:before {
  content: "\F01C"; }

.b-fa-inbox-in:before {
  content: "\F310"; }

.b-fa-inbox-out:before {
  content: "\F311"; }

.b-fa-indent:before {
  content: "\F03C"; }

.b-fa-industry:before {
  content: "\F275"; }

.b-fa-industry-alt:before {
  content: "\F3B3"; }

.b-fa-infinity:before {
  content: "\F534"; }

.b-fa-info:before, .b-icon-info:before {
  content: "\F129"; }

.b-fa-info-circle:before {
  content: "\F05A"; }

.b-fa-info-square:before {
  content: "\F30F"; }

.b-fa-inhaler:before {
  content: "\F5F9"; }

.b-fa-instagram:before {
  content: "\F16D"; }

.b-fa-integral:before {
  content: "\F667"; }

.b-fa-intercom:before {
  content: "\F7AF"; }

.b-fa-internet-explorer:before {
  content: "\F26B"; }

.b-fa-intersection:before {
  content: "\F668"; }

.b-fa-inventory:before {
  content: "\F480"; }

.b-fa-invision:before {
  content: "\F7B0"; }

.b-fa-ioxhost:before {
  content: "\F208"; }

.b-fa-island-tropical:before {
  content: "\F811"; }

.b-fa-italic:before {
  content: "\F033"; }

.b-fa-itch-io:before {
  content: "\F83A"; }

.b-fa-itunes:before {
  content: "\F3B4"; }

.b-fa-itunes-note:before {
  content: "\F3B5"; }

.b-fa-jack-o-lantern:before {
  content: "\F30E"; }

.b-fa-java:before {
  content: "\F4E4"; }

.b-fa-jedi:before {
  content: "\F669"; }

.b-fa-jedi-order:before {
  content: "\F50E"; }

.b-fa-jenkins:before {
  content: "\F3B6"; }

.b-fa-jira:before {
  content: "\F7B1"; }

.b-fa-joget:before {
  content: "\F3B7"; }

.b-fa-joint:before {
  content: "\F595"; }

.b-fa-joomla:before {
  content: "\F1AA"; }

.b-fa-journal-whills:before {
  content: "\F66A"; }

.b-fa-js:before {
  content: "\F3B8"; }

.b-fa-js-square:before {
  content: "\F3B9"; }

.b-fa-jsfiddle:before {
  content: "\F1CC"; }

.b-fa-kaaba:before {
  content: "\F66B"; }

.b-fa-kaggle:before {
  content: "\F5FA"; }

.b-fa-key:before {
  content: "\F084"; }

.b-fa-key-skeleton:before {
  content: "\F6F3"; }

.b-fa-keybase:before {
  content: "\F4F5"; }

.b-fa-keyboard:before {
  content: "\F11C"; }

.b-fa-keycdn:before {
  content: "\F3BA"; }

.b-fa-keynote:before {
  content: "\F66C"; }

.b-fa-khanda:before {
  content: "\F66D"; }

.b-fa-kickstarter:before {
  content: "\F3BB"; }

.b-fa-kickstarter-k:before {
  content: "\F3BC"; }

.b-fa-kidneys:before {
  content: "\F5FB"; }

.b-fa-kiss:before {
  content: "\F596"; }

.b-fa-kiss-beam:before {
  content: "\F597"; }

.b-fa-kiss-wink-heart:before {
  content: "\F598"; }

.b-fa-kite:before {
  content: "\F6F4"; }

.b-fa-kiwi-bird:before {
  content: "\F535"; }

.b-fa-knife-kitchen:before {
  content: "\F6F5"; }

.b-fa-korvue:before {
  content: "\F42F"; }

.b-fa-lambda:before {
  content: "\F66E"; }

.b-fa-lamp:before {
  content: "\F4CA"; }

.b-fa-landmark:before {
  content: "\F66F"; }

.b-fa-landmark-alt:before {
  content: "\F752"; }

.b-fa-language:before {
  content: "\F1AB"; }

.b-fa-laptop:before {
  content: "\F109"; }

.b-fa-laptop-code:before {
  content: "\F5FC"; }

.b-fa-laptop-medical:before {
  content: "\F812"; }

.b-fa-laravel:before {
  content: "\F3BD"; }

.b-fa-lastfm:before {
  content: "\F202"; }

.b-fa-lastfm-square:before {
  content: "\F203"; }

.b-fa-laugh:before {
  content: "\F599"; }

.b-fa-laugh-beam:before {
  content: "\F59A"; }

.b-fa-laugh-squint:before {
  content: "\F59B"; }

.b-fa-laugh-wink:before {
  content: "\F59C"; }

.b-fa-layer-group:before {
  content: "\F5FD"; }

.b-fa-layer-minus:before {
  content: "\F5FE"; }

.b-fa-layer-plus:before {
  content: "\F5FF"; }

.b-fa-leaf:before {
  content: "\F06C"; }

.b-fa-leaf-heart:before {
  content: "\F4CB"; }

.b-fa-leaf-maple:before {
  content: "\F6F6"; }

.b-fa-leaf-oak:before {
  content: "\F6F7"; }

.b-fa-leanpub:before {
  content: "\F212"; }

.b-fa-lemon:before {
  content: "\F094"; }

.b-fa-less:before {
  content: "\F41D"; }

.b-fa-less-than:before {
  content: "\F536"; }

.b-fa-less-than-equal:before {
  content: "\F537"; }

.b-fa-level-down:before {
  content: "\F149"; }

.b-fa-level-down-alt:before {
  content: "\F3BE"; }

.b-fa-level-up:before {
  content: "\F148"; }

.b-fa-level-up-alt:before {
  content: "\F3BF"; }

.b-fa-life-ring:before {
  content: "\F1CD"; }

.b-fa-lightbulb:before {
  content: "\F0EB"; }

.b-fa-lightbulb-dollar:before {
  content: "\F670"; }

.b-fa-lightbulb-exclamation:before {
  content: "\F671"; }

.b-fa-lightbulb-on:before {
  content: "\F672"; }

.b-fa-lightbulb-slash:before {
  content: "\F673"; }

.b-fa-lights-holiday:before {
  content: "\F7B2"; }

.b-fa-line:before {
  content: "\F3C0"; }

.b-fa-link:before {
  content: "\F0C1"; }

.b-fa-linkedin:before {
  content: "\F08C"; }

.b-fa-linkedin-in:before {
  content: "\F0E1"; }

.b-fa-linode:before {
  content: "\F2B8"; }

.b-fa-linux:before {
  content: "\F17C"; }

.b-fa-lips:before {
  content: "\F600"; }

.b-fa-lira-sign:before {
  content: "\F195"; }

.b-fa-list:before {
  content: "\F03A"; }

.b-fa-list-alt:before {
  content: "\F022"; }

.b-fa-list-ol:before {
  content: "\F0CB"; }

.b-fa-list-ul:before {
  content: "\F0CA"; }

.b-fa-location:before {
  content: "\F601"; }

.b-fa-location-arrow:before {
  content: "\F124"; }

.b-fa-location-circle:before {
  content: "\F602"; }

.b-fa-location-slash:before {
  content: "\F603"; }

.b-fa-lock:before {
  content: "\F023"; }

.b-fa-lock-alt:before {
  content: "\F30D"; }

.b-fa-lock-open:before {
  content: "\F3C1"; }

.b-fa-lock-open-alt:before {
  content: "\F3C2"; }

.b-fa-long-arrow-alt-down:before {
  content: "\F309"; }

.b-fa-long-arrow-alt-left:before {
  content: "\F30A"; }

.b-fa-long-arrow-alt-right:before {
  content: "\F30B"; }

.b-fa-long-arrow-alt-up:before {
  content: "\F30C"; }

.b-fa-long-arrow-down:before {
  content: "\F175"; }

.b-fa-long-arrow-left:before {
  content: "\F177"; }

.b-fa-long-arrow-right:before {
  content: "\F178"; }

.b-fa-long-arrow-up:before {
  content: "\F176"; }

.b-fa-loveseat:before {
  content: "\F4CC"; }

.b-fa-low-vision:before {
  content: "\F2A8"; }

.b-fa-luchador:before {
  content: "\F455"; }

.b-fa-luggage-cart:before {
  content: "\F59D"; }

.b-fa-lungs:before {
  content: "\F604"; }

.b-fa-lyft:before {
  content: "\F3C3"; }

.b-fa-mace:before {
  content: "\F6F8"; }

.b-fa-magento:before {
  content: "\F3C4"; }

.b-fa-magic:before {
  content: "\F0D0"; }

.b-fa-magnet:before {
  content: "\F076"; }

.b-fa-mail-bulk:before {
  content: "\F674"; }

.b-fa-mailbox:before {
  content: "\F813"; }

.b-fa-mailchimp:before {
  content: "\F59E"; }

.b-fa-male:before {
  content: "\F183"; }

.b-fa-mandalorian:before {
  content: "\F50F"; }

.b-fa-mandolin:before {
  content: "\F6F9"; }

.b-fa-map:before {
  content: "\F279"; }

.b-fa-map-marked:before {
  content: "\F59F"; }

.b-fa-map-marked-alt:before {
  content: "\F5A0"; }

.b-fa-map-marker:before {
  content: "\F041"; }

.b-fa-map-marker-alt:before {
  content: "\F3C5"; }

.b-fa-map-marker-alt-slash:before {
  content: "\F605"; }

.b-fa-map-marker-check:before {
  content: "\F606"; }

.b-fa-map-marker-edit:before {
  content: "\F607"; }

.b-fa-map-marker-exclamation:before {
  content: "\F608"; }

.b-fa-map-marker-minus:before {
  content: "\F609"; }

.b-fa-map-marker-plus:before {
  content: "\F60A"; }

.b-fa-map-marker-question:before {
  content: "\F60B"; }

.b-fa-map-marker-slash:before {
  content: "\F60C"; }

.b-fa-map-marker-smile:before {
  content: "\F60D"; }

.b-fa-map-marker-times:before {
  content: "\F60E"; }

.b-fa-map-pin:before {
  content: "\F276"; }

.b-fa-map-signs:before {
  content: "\F277"; }

.b-fa-markdown:before {
  content: "\F60F"; }

.b-fa-marker:before {
  content: "\F5A1"; }

.b-fa-mars:before {
  content: "\F222"; }

.b-fa-mars-double:before {
  content: "\F227"; }

.b-fa-mars-stroke:before {
  content: "\F229"; }

.b-fa-mars-stroke-h:before {
  content: "\F22B"; }

.b-fa-mars-stroke-v:before {
  content: "\F22A"; }

.b-fa-mask:before {
  content: "\F6FA"; }

.b-fa-mastodon:before {
  content: "\F4F6"; }

.b-fa-maxcdn:before {
  content: "\F136"; }

.b-fa-meat:before {
  content: "\F814"; }

.b-fa-medal:before {
  content: "\F5A2"; }

.b-fa-medapps:before {
  content: "\F3C6"; }

.b-fa-medium:before {
  content: "\F23A"; }

.b-fa-medium-m:before {
  content: "\F3C7"; }

.b-fa-medkit:before {
  content: "\F0FA"; }

.b-fa-medrt:before {
  content: "\F3C8"; }

.b-fa-meetup:before {
  content: "\F2E0"; }

.b-fa-megaphone:before {
  content: "\F675"; }

.b-fa-megaport:before {
  content: "\F5A3"; }

.b-fa-meh:before {
  content: "\F11A"; }

.b-fa-meh-blank:before {
  content: "\F5A4"; }

.b-fa-meh-rolling-eyes:before {
  content: "\F5A5"; }

.b-fa-memory:before {
  content: "\F538"; }

.b-fa-mendeley:before {
  content: "\F7B3"; }

.b-fa-menorah:before {
  content: "\F676"; }

.b-fa-mercury:before {
  content: "\F223"; }

.b-fa-meteor:before {
  content: "\F753"; }

.b-fa-microchip:before {
  content: "\F2DB"; }

.b-fa-microphone:before {
  content: "\F130"; }

.b-fa-microphone-alt:before {
  content: "\F3C9"; }

.b-fa-microphone-alt-slash:before {
  content: "\F539"; }

.b-fa-microphone-slash:before {
  content: "\F131"; }

.b-fa-microscope:before {
  content: "\F610"; }

.b-fa-microsoft:before {
  content: "\F3CA"; }

.b-fa-mind-share:before {
  content: "\F677"; }

.b-fa-minus:before {
  content: "\F068"; }

.b-fa-minus-circle:before, .b-icon-group-collapse:before, .b-grid-cell.b-group-title:before {
  content: "\F056"; }

.b-fa-minus-hexagon:before {
  content: "\F307"; }

.b-fa-minus-octagon:before {
  content: "\F308"; }

.b-fa-minus-square:before {
  content: "\F146"; }

.b-fa-mistletoe:before {
  content: "\F7B4"; }

.b-fa-mitten:before {
  content: "\F7B5"; }

.b-fa-mix:before {
  content: "\F3CB"; }

.b-fa-mixcloud:before {
  content: "\F289"; }

.b-fa-mizuni:before {
  content: "\F3CC"; }

.b-fa-mobile:before {
  content: "\F10B"; }

.b-fa-mobile-alt:before {
  content: "\F3CD"; }

.b-fa-mobile-android:before {
  content: "\F3CE"; }

.b-fa-mobile-android-alt:before {
  content: "\F3CF"; }

.b-fa-modx:before {
  content: "\F285"; }

.b-fa-monero:before {
  content: "\F3D0"; }

.b-fa-money-bill:before {
  content: "\F0D6"; }

.b-fa-money-bill-alt:before {
  content: "\F3D1"; }

.b-fa-money-bill-wave:before {
  content: "\F53A"; }

.b-fa-money-bill-wave-alt:before {
  content: "\F53B"; }

.b-fa-money-check:before {
  content: "\F53C"; }

.b-fa-money-check-alt:before {
  content: "\F53D"; }

.b-fa-monitor-heart-rate:before {
  content: "\F611"; }

.b-fa-monkey:before {
  content: "\F6FB"; }

.b-fa-monument:before {
  content: "\F5A6"; }

.b-fa-moon:before {
  content: "\F186"; }

.b-fa-moon-cloud:before {
  content: "\F754"; }

.b-fa-moon-stars:before {
  content: "\F755"; }

.b-fa-mortar-pestle:before {
  content: "\F5A7"; }

.b-fa-mosque:before {
  content: "\F678"; }

.b-fa-motorcycle:before {
  content: "\F21C"; }

.b-fa-mountain:before {
  content: "\F6FC"; }

.b-fa-mountains:before {
  content: "\F6FD"; }

.b-fa-mouse-pointer:before {
  content: "\F245"; }

.b-fa-mug-hot:before {
  content: "\F7B6"; }

.b-fa-mug-marshmallows:before {
  content: "\F7B7"; }

.b-fa-music:before {
  content: "\F001"; }

.b-fa-napster:before {
  content: "\F3D2"; }

.b-fa-narwhal:before {
  content: "\F6FE"; }

.b-fa-neos:before {
  content: "\F612"; }

.b-fa-network-wired:before {
  content: "\F6FF"; }

.b-fa-neuter:before {
  content: "\F22C"; }

.b-fa-newspaper:before {
  content: "\F1EA"; }

.b-fa-nimblr:before {
  content: "\F5A8"; }

.b-fa-nintendo-switch:before {
  content: "\F418"; }

.b-fa-node:before {
  content: "\F419"; }

.b-fa-node-js:before {
  content: "\F3D3"; }

.b-fa-not-equal:before {
  content: "\F53E"; }

.b-fa-notes-medical:before {
  content: "\F481"; }

.b-fa-npm:before {
  content: "\F3D4"; }

.b-fa-ns8:before {
  content: "\F3D5"; }

.b-fa-nutritionix:before {
  content: "\F3D6"; }

.b-fa-object-group:before {
  content: "\F247"; }

.b-fa-object-ungroup:before {
  content: "\F248"; }

.b-fa-octagon:before {
  content: "\F306"; }

.b-fa-odnoklassniki:before {
  content: "\F263"; }

.b-fa-odnoklassniki-square:before {
  content: "\F264"; }

.b-fa-oil-can:before {
  content: "\F613"; }

.b-fa-oil-temp:before {
  content: "\F614"; }

.b-fa-old-republic:before {
  content: "\F510"; }

.b-fa-om:before {
  content: "\F679"; }

.b-fa-omega:before {
  content: "\F67A"; }

.b-fa-opencart:before {
  content: "\F23D"; }

.b-fa-openid:before {
  content: "\F19B"; }

.b-fa-opera:before {
  content: "\F26A"; }

.b-fa-optin-monster:before {
  content: "\F23C"; }

.b-fa-ornament:before {
  content: "\F7B8"; }

.b-fa-osi:before {
  content: "\F41A"; }

.b-fa-otter:before {
  content: "\F700"; }

.b-fa-outdent:before {
  content: "\F03B"; }

.b-fa-page4:before {
  content: "\F3D7"; }

.b-fa-pagelines:before {
  content: "\F18C"; }

.b-fa-pager:before {
  content: "\F815"; }

.b-fa-paint-brush:before {
  content: "\F1FC"; }

.b-fa-paint-brush-alt:before {
  content: "\F5A9"; }

.b-fa-paint-roller:before {
  content: "\F5AA"; }

.b-fa-palette:before {
  content: "\F53F"; }

.b-fa-palfed:before {
  content: "\F3D8"; }

.b-fa-pallet:before {
  content: "\F482"; }

.b-fa-pallet-alt:before {
  content: "\F483"; }

.b-fa-paper-plane:before {
  content: "\F1D8"; }

.b-fa-paperclip:before {
  content: "\F0C6"; }

.b-fa-parachute-box:before {
  content: "\F4CD"; }

.b-fa-paragraph:before {
  content: "\F1DD"; }

.b-fa-parking:before {
  content: "\F540"; }

.b-fa-parking-circle:before {
  content: "\F615"; }

.b-fa-parking-circle-slash:before {
  content: "\F616"; }

.b-fa-parking-slash:before {
  content: "\F617"; }

.b-fa-passport:before {
  content: "\F5AB"; }

.b-fa-pastafarianism:before {
  content: "\F67B"; }

.b-fa-paste:before {
  content: "\F0EA"; }

.b-fa-patreon:before {
  content: "\F3D9"; }

.b-fa-pause:before {
  content: "\F04C"; }

.b-fa-pause-circle:before {
  content: "\F28B"; }

.b-fa-paw:before {
  content: "\F1B0"; }

.b-fa-paw-alt:before {
  content: "\F701"; }

.b-fa-paw-claws:before {
  content: "\F702"; }

.b-fa-paypal:before {
  content: "\F1ED"; }

.b-fa-peace:before {
  content: "\F67C"; }

.b-fa-pegasus:before {
  content: "\F703"; }

.b-fa-pen:before {
  content: "\F304"; }

.b-fa-pen-alt:before {
  content: "\F305"; }

.b-fa-pen-fancy:before {
  content: "\F5AC"; }

.b-fa-pen-nib:before {
  content: "\F5AD"; }

.b-fa-pen-square:before {
  content: "\F14B"; }

.b-fa-pencil:before, .b-icon-edit:before {
  content: "\F040"; }

.b-fa-pencil-alt:before {
  content: "\F303"; }

.b-fa-pencil-paintbrush:before {
  content: "\F618"; }

.b-fa-pencil-ruler:before {
  content: "\F5AE"; }

.b-fa-pennant:before {
  content: "\F456"; }

.b-fa-penny-arcade:before {
  content: "\F704"; }

.b-fa-people-carry:before {
  content: "\F4CE"; }

.b-fa-pepper-hot:before {
  content: "\F816"; }

.b-fa-percent:before {
  content: "\F295"; }

.b-fa-percentage:before {
  content: "\F541"; }

.b-fa-periscope:before {
  content: "\F3DA"; }

.b-fa-person-booth:before {
  content: "\F756"; }

.b-fa-person-carry:before {
  content: "\F4CF"; }

.b-fa-person-dolly:before {
  content: "\F4D0"; }

.b-fa-person-dolly-empty:before {
  content: "\F4D1"; }

.b-fa-person-sign:before {
  content: "\F757"; }

.b-fa-phabricator:before {
  content: "\F3DB"; }

.b-fa-phoenix-framework:before {
  content: "\F3DC"; }

.b-fa-phoenix-squadron:before {
  content: "\F511"; }

.b-fa-phone:before {
  content: "\F095"; }

.b-fa-phone-office:before {
  content: "\F67D"; }

.b-fa-phone-plus:before {
  content: "\F4D2"; }

.b-fa-phone-slash:before {
  content: "\F3DD"; }

.b-fa-phone-square:before {
  content: "\F098"; }

.b-fa-phone-volume:before {
  content: "\F2A0"; }

.b-fa-php:before {
  content: "\F457"; }

.b-fa-pi:before {
  content: "\F67E"; }

.b-fa-pie:before {
  content: "\F705"; }

.b-fa-pied-piper:before {
  content: "\F2AE"; }

.b-fa-pied-piper-alt:before {
  content: "\F1A8"; }

.b-fa-pied-piper-hat:before {
  content: "\F4E5"; }

.b-fa-pied-piper-pp:before {
  content: "\F1A7"; }

.b-fa-pig:before {
  content: "\F706"; }

.b-fa-piggy-bank:before {
  content: "\F4D3"; }

.b-fa-pills:before {
  content: "\F484"; }

.b-fa-pinterest:before {
  content: "\F0D2"; }

.b-fa-pinterest-p:before {
  content: "\F231"; }

.b-fa-pinterest-square:before {
  content: "\F0D3"; }

.b-fa-pizza:before {
  content: "\F817"; }

.b-fa-pizza-slice:before {
  content: "\F818"; }

.b-fa-place-of-worship:before {
  content: "\F67F"; }

.b-fa-plane:before {
  content: "\F072"; }

.b-fa-plane-alt:before {
  content: "\F3DE"; }

.b-fa-plane-arrival:before {
  content: "\F5AF"; }

.b-fa-plane-departure:before {
  content: "\F5B0"; }

.b-fa-play:before {
  content: "\F04B"; }

.b-fa-play-circle:before {
  content: "\F144"; }

.b-fa-playstation:before {
  content: "\F3DF"; }

.b-fa-plug:before {
  content: "\F1E6"; }

.b-fa-plus:before, .b-icon-add:before {
  content: "\F067"; }

.b-fa-plus-circle:before, .b-icon-group-expand:before, .b-grid-group-collapsed .b-group-title:before {
  content: "\F055"; }

.b-fa-plus-hexagon:before {
  content: "\F300"; }

.b-fa-plus-octagon:before {
  content: "\F301"; }

.b-fa-plus-square:before {
  content: "\F0FE"; }

.b-fa-podcast:before {
  content: "\F2CE"; }

.b-fa-podium:before {
  content: "\F680"; }

.b-fa-podium-star:before {
  content: "\F758"; }

.b-fa-poll:before {
  content: "\F681"; }

.b-fa-poll-h:before {
  content: "\F682"; }

.b-fa-poll-people:before {
  content: "\F759"; }

.b-fa-poo:before {
  content: "\F2FE"; }

.b-fa-poo-storm:before {
  content: "\F75A"; }

.b-fa-poop:before {
  content: "\F619"; }

.b-fa-popcorn:before {
  content: "\F819"; }

.b-fa-portrait:before {
  content: "\F3E0"; }

.b-fa-pound-sign:before {
  content: "\F154"; }

.b-fa-power-off:before {
  content: "\F011"; }

.b-fa-pray:before {
  content: "\F683"; }

.b-fa-praying-hands:before {
  content: "\F684"; }

.b-fa-prescription:before {
  content: "\F5B1"; }

.b-fa-prescription-bottle:before {
  content: "\F485"; }

.b-fa-prescription-bottle-alt:before {
  content: "\F486"; }

.b-fa-presentation:before {
  content: "\F685"; }

.b-fa-print:before {
  content: "\F02F"; }

.b-fa-print-search:before {
  content: "\F81A"; }

.b-fa-print-slash:before {
  content: "\F686"; }

.b-fa-procedures:before {
  content: "\F487"; }

.b-fa-product-hunt:before {
  content: "\F288"; }

.b-fa-project-diagram:before {
  content: "\F542"; }

.b-fa-pumpkin:before {
  content: "\F707"; }

.b-fa-pushed:before {
  content: "\F3E1"; }

.b-fa-puzzle-piece:before {
  content: "\F12E"; }

.b-fa-python:before {
  content: "\F3E2"; }

.b-fa-qq:before {
  content: "\F1D6"; }

.b-fa-qrcode:before {
  content: "\F029"; }

.b-fa-question:before {
  content: "\F128"; }

.b-fa-question-circle:before {
  content: "\F059"; }

.b-fa-question-square:before {
  content: "\F2FD"; }

.b-fa-quidditch:before {
  content: "\F458"; }

.b-fa-quinscape:before {
  content: "\F459"; }

.b-fa-quora:before {
  content: "\F2C4"; }

.b-fa-quote-left:before {
  content: "\F10D"; }

.b-fa-quote-right:before {
  content: "\F10E"; }

.b-fa-quran:before {
  content: "\F687"; }

.b-fa-r-project:before {
  content: "\F4F7"; }

.b-fa-rabbit:before {
  content: "\F708"; }

.b-fa-rabbit-fast:before {
  content: "\F709"; }

.b-fa-racquet:before {
  content: "\F45A"; }

.b-fa-radiation:before {
  content: "\F7B9"; }

.b-fa-radiation-alt:before {
  content: "\F7BA"; }

.b-fa-rainbow:before {
  content: "\F75B"; }

.b-fa-raindrops:before {
  content: "\F75C"; }

.b-fa-ram:before {
  content: "\F70A"; }

.b-fa-ramp-loading:before {
  content: "\F4D4"; }

.b-fa-random:before {
  content: "\F074"; }

.b-fa-raspberry-pi:before {
  content: "\F7BB"; }

.b-fa-ravelry:before {
  content: "\F2D9"; }

.b-fa-react:before {
  content: "\F41B"; }

.b-fa-reacteurope:before {
  content: "\F75D"; }

.b-fa-readme:before {
  content: "\F4D5"; }

.b-fa-rebel:before {
  content: "\F1D0"; }

.b-fa-receipt:before {
  content: "\F543"; }

.b-fa-rectangle-landscape:before {
  content: "\F2FA"; }

.b-fa-rectangle-portrait:before {
  content: "\F2FB"; }

.b-fa-rectangle-wide:before {
  content: "\F2FC"; }

.b-fa-recycle:before {
  content: "\F1B8"; }

.b-fa-red-river:before {
  content: "\F3E3"; }

.b-fa-reddit:before {
  content: "\F1A1"; }

.b-fa-reddit-alien:before {
  content: "\F281"; }

.b-fa-reddit-square:before {
  content: "\F1A2"; }

.b-fa-redhat:before {
  content: "\F7BC"; }

.b-fa-redo:before {
  content: "\F01E"; }

.b-fa-redo-alt:before {
  content: "\F2F9"; }

.b-fa-registered:before {
  content: "\F25D"; }

.b-fa-renren:before {
  content: "\F18B"; }

.b-fa-repeat:before {
  content: "\F363"; }

.b-fa-repeat-1:before {
  content: "\F365"; }

.b-fa-repeat-1-alt:before {
  content: "\F366"; }

.b-fa-repeat-alt:before {
  content: "\F364"; }

.b-fa-reply:before {
  content: "\F3E5"; }

.b-fa-reply-all:before {
  content: "\F122"; }

.b-fa-replyd:before {
  content: "\F3E6"; }

.b-fa-republican:before {
  content: "\F75E"; }

.b-fa-researchgate:before {
  content: "\F4F8"; }

.b-fa-resolving:before {
  content: "\F3E7"; }

.b-fa-restroom:before {
  content: "\F7BD"; }

.b-fa-retweet:before {
  content: "\F079"; }

.b-fa-retweet-alt:before {
  content: "\F361"; }

.b-fa-rev:before {
  content: "\F5B2"; }

.b-fa-ribbon:before {
  content: "\F4D6"; }

.b-fa-ring:before {
  content: "\F70B"; }

.b-fa-rings-wedding:before {
  content: "\F81B"; }

.b-fa-road:before {
  content: "\F018"; }

.b-fa-robot:before {
  content: "\F544"; }

.b-fa-rocket:before {
  content: "\F135"; }

.b-fa-rocketchat:before {
  content: "\F3E8"; }

.b-fa-rockrms:before {
  content: "\F3E9"; }

.b-fa-route:before {
  content: "\F4D7"; }

.b-fa-route-highway:before {
  content: "\F61A"; }

.b-fa-route-interstate:before {
  content: "\F61B"; }

.b-fa-rss:before {
  content: "\F09E"; }

.b-fa-rss-square:before {
  content: "\F143"; }

.b-fa-ruble-sign:before {
  content: "\F158"; }

.b-fa-ruler:before {
  content: "\F545"; }

.b-fa-ruler-combined:before {
  content: "\F546"; }

.b-fa-ruler-horizontal:before {
  content: "\F547"; }

.b-fa-ruler-triangle:before {
  content: "\F61C"; }

.b-fa-ruler-vertical:before {
  content: "\F548"; }

.b-fa-running:before {
  content: "\F70C"; }

.b-fa-rupee-sign:before {
  content: "\F156"; }

.b-fa-rv:before {
  content: "\F7BE"; }

.b-fa-sack:before {
  content: "\F81C"; }

.b-fa-sack-dollar:before {
  content: "\F81D"; }

.b-fa-sad-cry:before {
  content: "\F5B3"; }

.b-fa-sad-tear:before {
  content: "\F5B4"; }

.b-fa-safari:before {
  content: "\F267"; }

.b-fa-salad:before {
  content: "\F81E"; }

.b-fa-salesforce:before {
  content: "\F83B"; }

.b-fa-sandwich:before {
  content: "\F81F"; }

.b-fa-sass:before {
  content: "\F41E"; }

.b-fa-satellite:before {
  content: "\F7BF"; }

.b-fa-satellite-dish:before {
  content: "\F7C0"; }

.b-fa-sausage:before {
  content: "\F820"; }

.b-fa-save:before {
  content: "\F0C7"; }

.b-fa-scalpel:before {
  content: "\F61D"; }

.b-fa-scalpel-path:before {
  content: "\F61E"; }

.b-fa-scanner:before {
  content: "\F488"; }

.b-fa-scanner-keyboard:before {
  content: "\F489"; }

.b-fa-scanner-touchscreen:before {
  content: "\F48A"; }

.b-fa-scarecrow:before {
  content: "\F70D"; }

.b-fa-scarf:before {
  content: "\F7C1"; }

.b-fa-schlix:before {
  content: "\F3EA"; }

.b-fa-school:before {
  content: "\F549"; }

.b-fa-screwdriver:before {
  content: "\F54A"; }

.b-fa-scribd:before {
  content: "\F28A"; }

.b-fa-scroll:before {
  content: "\F70E"; }

.b-fa-scroll-old:before {
  content: "\F70F"; }

.b-fa-scrubber:before {
  content: "\F2F8"; }

.b-fa-scythe:before {
  content: "\F710"; }

.b-fa-sd-card:before {
  content: "\F7C2"; }

.b-fa-search:before, .b-icon-search:before {
  content: "\F002"; }

.b-fa-search-dollar:before {
  content: "\F688"; }

.b-fa-search-location:before {
  content: "\F689"; }

.b-fa-search-minus:before, .b-icon-search-minus:before {
  content: "\F010"; }

.b-fa-search-plus:before, .b-icon-search-plus:before {
  content: "\F00E"; }

.b-fa-searchengin:before {
  content: "\F3EB"; }

.b-fa-seedling:before {
  content: "\F4D8"; }

.b-fa-sellcast:before {
  content: "\F2DA"; }

.b-fa-sellsy:before {
  content: "\F213"; }

.b-fa-server:before {
  content: "\F233"; }

.b-fa-servicestack:before {
  content: "\F3EC"; }

.b-fa-shapes:before {
  content: "\F61F"; }

.b-fa-share:before {
  content: "\F064"; }

.b-fa-share-all:before {
  content: "\F367"; }

.b-fa-share-alt:before {
  content: "\F1E0"; }

.b-fa-share-alt-square:before {
  content: "\F1E1"; }

.b-fa-share-square:before {
  content: "\F14D"; }

.b-fa-sheep:before {
  content: "\F711"; }

.b-fa-shekel-sign:before {
  content: "\F20B"; }

.b-fa-shield:before {
  content: "\F132"; }

.b-fa-shield-alt:before {
  content: "\F3ED"; }

.b-fa-shield-check:before {
  content: "\F2F7"; }

.b-fa-shield-cross:before {
  content: "\F712"; }

.b-fa-ship:before {
  content: "\F21A"; }

.b-fa-shipping-fast:before {
  content: "\F48B"; }

.b-fa-shipping-timed:before {
  content: "\F48C"; }

.b-fa-shirtsinbulk:before {
  content: "\F214"; }

.b-fa-shish-kebab:before {
  content: "\F821"; }

.b-fa-shoe-prints:before {
  content: "\F54B"; }

.b-fa-shopping-bag:before {
  content: "\F290"; }

.b-fa-shopping-basket:before {
  content: "\F291"; }

.b-fa-shopping-cart:before {
  content: "\F07A"; }

.b-fa-shopware:before {
  content: "\F5B5"; }

.b-fa-shovel:before {
  content: "\F713"; }

.b-fa-shovel-snow:before {
  content: "\F7C3"; }

.b-fa-shower:before {
  content: "\F2CC"; }

.b-fa-shredder:before {
  content: "\F68A"; }

.b-fa-shuttle-van:before {
  content: "\F5B6"; }

.b-fa-shuttlecock:before {
  content: "\F45B"; }

.b-fa-sickle:before {
  content: "\F822"; }

.b-fa-sigma:before {
  content: "\F68B"; }

.b-fa-sign:before {
  content: "\F4D9"; }

.b-fa-sign-in:before {
  content: "\F090"; }

.b-fa-sign-in-alt:before {
  content: "\F2F6"; }

.b-fa-sign-language:before {
  content: "\F2A7"; }

.b-fa-sign-out:before {
  content: "\F08B"; }

.b-fa-sign-out-alt:before {
  content: "\F2F5"; }

.b-fa-signal:before {
  content: "\F012"; }

.b-fa-signal-1:before {
  content: "\F68C"; }

.b-fa-signal-2:before {
  content: "\F68D"; }

.b-fa-signal-3:before {
  content: "\F68E"; }

.b-fa-signal-4:before {
  content: "\F68F"; }

.b-fa-signal-alt:before {
  content: "\F690"; }

.b-fa-signal-alt-1:before {
  content: "\F691"; }

.b-fa-signal-alt-2:before {
  content: "\F692"; }

.b-fa-signal-alt-3:before {
  content: "\F693"; }

.b-fa-signal-alt-slash:before {
  content: "\F694"; }

.b-fa-signal-slash:before {
  content: "\F695"; }

.b-fa-signature:before {
  content: "\F5B7"; }

.b-fa-sim-card:before {
  content: "\F7C4"; }

.b-fa-simplybuilt:before {
  content: "\F215"; }

.b-fa-sistrix:before {
  content: "\F3EE"; }

.b-fa-sitemap:before {
  content: "\F0E8"; }

.b-fa-sith:before {
  content: "\F512"; }

.b-fa-skating:before {
  content: "\F7C5"; }

.b-fa-skeleton:before {
  content: "\F620"; }

.b-fa-sketch:before {
  content: "\F7C6"; }

.b-fa-ski-jump:before {
  content: "\F7C7"; }

.b-fa-ski-lift:before {
  content: "\F7C8"; }

.b-fa-skiing:before {
  content: "\F7C9"; }

.b-fa-skiing-nordic:before {
  content: "\F7CA"; }

.b-fa-skull:before {
  content: "\F54C"; }

.b-fa-skull-crossbones:before {
  content: "\F714"; }

.b-fa-skyatlas:before {
  content: "\F216"; }

.b-fa-skype:before {
  content: "\F17E"; }

.b-fa-slack:before {
  content: "\F198"; }

.b-fa-slack-hash:before {
  content: "\F3EF"; }

.b-fa-slash:before {
  content: "\F715"; }

.b-fa-sledding:before {
  content: "\F7CB"; }

.b-fa-sleigh:before {
  content: "\F7CC"; }

.b-fa-sliders-h:before {
  content: "\F1DE"; }

.b-fa-sliders-h-square:before {
  content: "\F3F0"; }

.b-fa-sliders-v:before {
  content: "\F3F1"; }

.b-fa-sliders-v-square:before {
  content: "\F3F2"; }

.b-fa-slideshare:before {
  content: "\F1E7"; }

.b-fa-smile:before {
  content: "\F118"; }

.b-fa-smile-beam:before {
  content: "\F5B8"; }

.b-fa-smile-plus:before {
  content: "\F5B9"; }

.b-fa-smile-wink:before {
  content: "\F4DA"; }

.b-fa-smog:before {
  content: "\F75F"; }

.b-fa-smoke:before {
  content: "\F760"; }

.b-fa-smoking:before {
  content: "\F48D"; }

.b-fa-smoking-ban:before {
  content: "\F54D"; }

.b-fa-sms:before {
  content: "\F7CD"; }

.b-fa-snake:before {
  content: "\F716"; }

.b-fa-snapchat:before {
  content: "\F2AB"; }

.b-fa-snapchat-ghost:before {
  content: "\F2AC"; }

.b-fa-snapchat-square:before {
  content: "\F2AD"; }

.b-fa-snow-blowing:before {
  content: "\F761"; }

.b-fa-snowboarding:before {
  content: "\F7CE"; }

.b-fa-snowflake:before {
  content: "\F2DC"; }

.b-fa-snowflakes:before {
  content: "\F7CF"; }

.b-fa-snowman:before {
  content: "\F7D0"; }

.b-fa-snowmobile:before {
  content: "\F7D1"; }

.b-fa-snowplow:before {
  content: "\F7D2"; }

.b-fa-socks:before {
  content: "\F696"; }

.b-fa-solar-panel:before {
  content: "\F5BA"; }

.b-fa-sort:before, .b-icon-sort:before {
  content: "\F0DC"; }

.b-fa-sort-alpha-down:before {
  content: "\F15D"; }

.b-fa-sort-alpha-up:before {
  content: "\F15E"; }

.b-fa-sort-amount-down:before {
  content: "\F160"; }

.b-fa-sort-amount-up:before {
  content: "\F161"; }

.b-fa-sort-down:before {
  content: "\F0DD"; }

.b-fa-sort-numeric-down:before {
  content: "\F162"; }

.b-fa-sort-numeric-up:before {
  content: "\F163"; }

.b-fa-sort-up:before {
  content: "\F0DE"; }

.b-fa-soundcloud:before {
  content: "\F1BE"; }

.b-fa-soup:before {
  content: "\F823"; }

.b-fa-sourcetree:before {
  content: "\F7D3"; }

.b-fa-spa:before {
  content: "\F5BB"; }

.b-fa-space-shuttle:before {
  content: "\F197"; }

.b-fa-spade:before {
  content: "\F2F4"; }

.b-fa-speakap:before {
  content: "\F3F3"; }

.b-fa-speaker-deck:before {
  content: "\F83C"; }

.b-fa-spider:before {
  content: "\F717"; }

.b-fa-spider-black-widow:before {
  content: "\F718"; }

.b-fa-spider-web:before {
  content: "\F719"; }

.b-fa-spinner:before, .b-icon-spinner:before, .b-icon-checking:before {
  content: "\F110"; }

.b-fa-spinner-third:before {
  content: "\F3F4"; }

.b-fa-splotch:before {
  content: "\F5BC"; }

.b-fa-spotify:before {
  content: "\F1BC"; }

.b-fa-spray-can:before {
  content: "\F5BD"; }

.b-fa-square:before, .b-icon-unchecked:before {
  content: "\F0C8"; }

.b-fa-square-full:before {
  content: "\F45C"; }

.b-fa-square-root:before {
  content: "\F697"; }

.b-fa-square-root-alt:before {
  content: "\F698"; }

.b-fa-squarespace:before {
  content: "\F5BE"; }

.b-fa-squirrel:before {
  content: "\F71A"; }

.b-fa-stack-exchange:before {
  content: "\F18D"; }

.b-fa-stack-overflow:before {
  content: "\F16C"; }

.b-fa-stackpath:before {
  content: "\F842"; }

.b-fa-staff:before {
  content: "\F71B"; }

.b-fa-stamp:before {
  content: "\F5BF"; }

.b-fa-star:before, .b-icon-star:before {
  content: "\F005"; }

.b-fa-star-and-crescent:before {
  content: "\F699"; }

.b-fa-star-christmas:before {
  content: "\F7D4"; }

.b-fa-star-exclamation:before {
  content: "\F2F3"; }

.b-fa-star-half:before {
  content: "\F089"; }

.b-fa-star-half-alt:before {
  content: "\F5C0"; }

.b-fa-star-of-david:before {
  content: "\F69A"; }

.b-fa-star-of-life:before {
  content: "\F621"; }

.b-fa-stars:before {
  content: "\F762"; }

.b-fa-staylinked:before {
  content: "\F3F5"; }

.b-fa-steak:before {
  content: "\F824"; }

.b-fa-steam:before {
  content: "\F1B6"; }

.b-fa-steam-square:before {
  content: "\F1B7"; }

.b-fa-steam-symbol:before {
  content: "\F3F6"; }

.b-fa-steering-wheel:before {
  content: "\F622"; }

.b-fa-step-backward:before {
  content: "\F048"; }

.b-fa-step-forward:before {
  content: "\F051"; }

.b-fa-stethoscope:before {
  content: "\F0F1"; }

.b-fa-sticker-mule:before {
  content: "\F3F7"; }

.b-fa-sticky-note:before {
  content: "\F249"; }

.b-fa-stocking:before {
  content: "\F7D5"; }

.b-fa-stomach:before {
  content: "\F623"; }

.b-fa-stop:before {
  content: "\F04D"; }

.b-fa-stop-circle:before {
  content: "\F28D"; }

.b-fa-stopwatch:before {
  content: "\F2F2"; }

.b-fa-store:before {
  content: "\F54E"; }

.b-fa-store-alt:before {
  content: "\F54F"; }

.b-fa-strava:before {
  content: "\F428"; }

.b-fa-stream:before {
  content: "\F550"; }

.b-fa-street-view:before {
  content: "\F21D"; }

.b-fa-stretcher:before {
  content: "\F825"; }

.b-fa-strikethrough:before {
  content: "\F0CC"; }

.b-fa-stripe:before {
  content: "\F429"; }

.b-fa-stripe-s:before {
  content: "\F42A"; }

.b-fa-stroopwafel:before {
  content: "\F551"; }

.b-fa-studiovinari:before {
  content: "\F3F8"; }

.b-fa-stumbleupon:before {
  content: "\F1A4"; }

.b-fa-stumbleupon-circle:before {
  content: "\F1A3"; }

.b-fa-subscript:before {
  content: "\F12C"; }

.b-fa-subway:before {
  content: "\F239"; }

.b-fa-suitcase:before {
  content: "\F0F2"; }

.b-fa-suitcase-rolling:before {
  content: "\F5C1"; }

.b-fa-sun:before {
  content: "\F185"; }

.b-fa-sun-cloud:before {
  content: "\F763"; }

.b-fa-sun-dust:before {
  content: "\F764"; }

.b-fa-sun-haze:before {
  content: "\F765"; }

.b-fa-sunrise:before {
  content: "\F766"; }

.b-fa-sunset:before {
  content: "\F767"; }

.b-fa-superpowers:before {
  content: "\F2DD"; }

.b-fa-superscript:before {
  content: "\F12B"; }

.b-fa-supple:before {
  content: "\F3F9"; }

.b-fa-surprise:before {
  content: "\F5C2"; }

.b-fa-suse:before {
  content: "\F7D6"; }

.b-fa-swatchbook:before {
  content: "\F5C3"; }

.b-fa-swimmer:before {
  content: "\F5C4"; }

.b-fa-swimming-pool:before {
  content: "\F5C5"; }

.b-fa-sword:before {
  content: "\F71C"; }

.b-fa-swords:before {
  content: "\F71D"; }

.b-fa-symfony:before {
  content: "\F83D"; }

.b-fa-synagogue:before {
  content: "\F69B"; }

.b-fa-sync:before {
  content: "\F021"; }

.b-fa-sync-alt:before {
  content: "\F2F1"; }

.b-fa-syringe:before {
  content: "\F48E"; }

.b-fa-table:before {
  content: "\F0CE"; }

.b-fa-table-tennis:before {
  content: "\F45D"; }

.b-fa-tablet:before {
  content: "\F10A"; }

.b-fa-tablet-alt:before {
  content: "\F3FA"; }

.b-fa-tablet-android:before {
  content: "\F3FB"; }

.b-fa-tablet-android-alt:before {
  content: "\F3FC"; }

.b-fa-tablet-rugged:before {
  content: "\F48F"; }

.b-fa-tablets:before {
  content: "\F490"; }

.b-fa-tachometer:before {
  content: "\F0E4"; }

.b-fa-tachometer-alt:before {
  content: "\F3FD"; }

.b-fa-tachometer-alt-average:before {
  content: "\F624"; }

.b-fa-tachometer-alt-fast:before {
  content: "\F625"; }

.b-fa-tachometer-alt-fastest:before {
  content: "\F626"; }

.b-fa-tachometer-alt-slow:before {
  content: "\F627"; }

.b-fa-tachometer-alt-slowest:before {
  content: "\F628"; }

.b-fa-tachometer-average:before {
  content: "\F629"; }

.b-fa-tachometer-fast:before {
  content: "\F62A"; }

.b-fa-tachometer-fastest:before {
  content: "\F62B"; }

.b-fa-tachometer-slow:before {
  content: "\F62C"; }

.b-fa-tachometer-slowest:before {
  content: "\F62D"; }

.b-fa-taco:before {
  content: "\F826"; }

.b-fa-tag:before {
  content: "\F02B"; }

.b-fa-tags:before {
  content: "\F02C"; }

.b-fa-tally:before {
  content: "\F69C"; }

.b-fa-tanakh:before {
  content: "\F827"; }

.b-fa-tape:before {
  content: "\F4DB"; }

.b-fa-tasks:before {
  content: "\F0AE"; }

.b-fa-tasks-alt:before {
  content: "\F828"; }

.b-fa-taxi:before {
  content: "\F1BA"; }

.b-fa-teamspeak:before {
  content: "\F4F9"; }

.b-fa-teeth:before {
  content: "\F62E"; }

.b-fa-teeth-open:before {
  content: "\F62F"; }

.b-fa-telegram:before {
  content: "\F2C6"; }

.b-fa-telegram-plane:before {
  content: "\F3FE"; }

.b-fa-temperature-frigid:before {
  content: "\F768"; }

.b-fa-temperature-high:before {
  content: "\F769"; }

.b-fa-temperature-hot:before {
  content: "\F76A"; }

.b-fa-temperature-low:before {
  content: "\F76B"; }

.b-fa-tencent-weibo:before {
  content: "\F1D5"; }

.b-fa-tenge:before {
  content: "\F7D7"; }

.b-fa-tennis-ball:before {
  content: "\F45E"; }

.b-fa-terminal:before {
  content: "\F120"; }

.b-fa-text-height:before {
  content: "\F034"; }

.b-fa-text-width:before {
  content: "\F035"; }

.b-fa-th:before {
  content: "\F00A"; }

.b-fa-th-large:before {
  content: "\F009"; }

.b-fa-th-list:before {
  content: "\F00B"; }

.b-fa-the-red-yeti:before {
  content: "\F69D"; }

.b-fa-theater-masks:before {
  content: "\F630"; }

.b-fa-themeco:before {
  content: "\F5C6"; }

.b-fa-themeisle:before {
  content: "\F2B2"; }

.b-fa-thermometer:before {
  content: "\F491"; }

.b-fa-thermometer-empty:before {
  content: "\F2CB"; }

.b-fa-thermometer-full:before {
  content: "\F2C7"; }

.b-fa-thermometer-half:before {
  content: "\F2C9"; }

.b-fa-thermometer-quarter:before {
  content: "\F2CA"; }

.b-fa-thermometer-three-quarters:before {
  content: "\F2C8"; }

.b-fa-theta:before {
  content: "\F69E"; }

.b-fa-think-peaks:before {
  content: "\F731"; }

.b-fa-thumbs-down:before {
  content: "\F165"; }

.b-fa-thumbs-up:before {
  content: "\F164"; }

.b-fa-thumbtack:before {
  content: "\F08D"; }

.b-fa-thunderstorm:before {
  content: "\F76C"; }

.b-fa-thunderstorm-moon:before {
  content: "\F76D"; }

.b-fa-thunderstorm-sun:before {
  content: "\F76E"; }

.b-fa-ticket:before {
  content: "\F145"; }

.b-fa-ticket-alt:before {
  content: "\F3FF"; }

.b-fa-tilde:before {
  content: "\F69F"; }

.b-fa-times:before, .b-icon-remove:before, .b-icon-clear:before {
  content: "\F00D"; }

.b-fa-times-circle:before, .b-icon-hide-column:before,
.b-icon-hide:before {
  content: "\F057"; }

.b-fa-times-hexagon:before {
  content: "\F2EE"; }

.b-fa-times-octagon:before {
  content: "\F2F0"; }

.b-fa-times-square:before {
  content: "\F2D3"; }

.b-fa-tint:before {
  content: "\F043"; }

.b-fa-tint-slash:before {
  content: "\F5C7"; }

.b-fa-tire:before {
  content: "\F631"; }

.b-fa-tire-flat:before {
  content: "\F632"; }

.b-fa-tire-pressure-warning:before {
  content: "\F633"; }

.b-fa-tire-rugged:before {
  content: "\F634"; }

.b-fa-tired:before {
  content: "\F5C8"; }

.b-fa-toggle-off:before {
  content: "\F204"; }

.b-fa-toggle-on:before {
  content: "\F205"; }

.b-fa-toilet:before {
  content: "\F7D8"; }

.b-fa-toilet-paper:before {
  content: "\F71E"; }

.b-fa-toilet-paper-alt:before {
  content: "\F71F"; }

.b-fa-tombstone:before {
  content: "\F720"; }

.b-fa-tombstone-alt:before {
  content: "\F721"; }

.b-fa-toolbox:before {
  content: "\F552"; }

.b-fa-tools:before {
  content: "\F7D9"; }

.b-fa-tooth:before {
  content: "\F5C9"; }

.b-fa-toothbrush:before {
  content: "\F635"; }

.b-fa-torah:before {
  content: "\F6A0"; }

.b-fa-torii-gate:before {
  content: "\F6A1"; }

.b-fa-tornado:before {
  content: "\F76F"; }

.b-fa-tractor:before {
  content: "\F722"; }

.b-fa-trade-federation:before {
  content: "\F513"; }

.b-fa-trademark:before {
  content: "\F25C"; }

.b-fa-traffic-cone:before {
  content: "\F636"; }

.b-fa-traffic-light:before {
  content: "\F637"; }

.b-fa-traffic-light-go:before {
  content: "\F638"; }

.b-fa-traffic-light-slow:before {
  content: "\F639"; }

.b-fa-traffic-light-stop:before {
  content: "\F63A"; }

.b-fa-train:before {
  content: "\F238"; }

.b-fa-tram:before {
  content: "\F7DA"; }

.b-fa-transgender:before {
  content: "\F224"; }

.b-fa-transgender-alt:before {
  content: "\F225"; }

.b-fa-trash:before, .b-icon-trash:before {
  content: "\F1F8"; }

.b-fa-trash-alt:before {
  content: "\F2ED"; }

.b-fa-trash-restore:before {
  content: "\F829"; }

.b-fa-trash-restore-alt:before {
  content: "\F82A"; }

.b-fa-treasure-chest:before {
  content: "\F723"; }

.b-fa-tree:before {
  content: "\F1BB"; }

.b-fa-tree-alt:before {
  content: "\F400"; }

.b-fa-tree-christmas:before {
  content: "\F7DB"; }

.b-fa-tree-decorated:before {
  content: "\F7DC"; }

.b-fa-tree-large:before {
  content: "\F7DD"; }

.b-fa-tree-palm:before {
  content: "\F82B"; }

.b-fa-trees:before {
  content: "\F724"; }

.b-fa-trello:before {
  content: "\F181"; }

.b-fa-triangle:before {
  content: "\F2EC"; }

.b-fa-tripadvisor:before {
  content: "\F262"; }

.b-fa-trophy:before {
  content: "\F091"; }

.b-fa-trophy-alt:before {
  content: "\F2EB"; }

.b-fa-truck:before {
  content: "\F0D1"; }

.b-fa-truck-container:before {
  content: "\F4DC"; }

.b-fa-truck-couch:before {
  content: "\F4DD"; }

.b-fa-truck-loading:before {
  content: "\F4DE"; }

.b-fa-truck-monster:before {
  content: "\F63B"; }

.b-fa-truck-moving:before {
  content: "\F4DF"; }

.b-fa-truck-pickup:before {
  content: "\F63C"; }

.b-fa-truck-plow:before {
  content: "\F7DE"; }

.b-fa-truck-ramp:before {
  content: "\F4E0"; }

.b-fa-tshirt:before {
  content: "\F553"; }

.b-fa-tty:before {
  content: "\F1E4"; }

.b-fa-tumblr:before {
  content: "\F173"; }

.b-fa-tumblr-square:before {
  content: "\F174"; }

.b-fa-turkey:before {
  content: "\F725"; }

.b-fa-turtle:before {
  content: "\F726"; }

.b-fa-tv:before {
  content: "\F26C"; }

.b-fa-tv-retro:before {
  content: "\F401"; }

.b-fa-twitch:before {
  content: "\F1E8"; }

.b-fa-twitter:before {
  content: "\F099"; }

.b-fa-twitter-square:before {
  content: "\F081"; }

.b-fa-typo3:before {
  content: "\F42B"; }

.b-fa-uber:before {
  content: "\F402"; }

.b-fa-ubuntu:before {
  content: "\F7DF"; }

.b-fa-uikit:before {
  content: "\F403"; }

.b-fa-umbrella:before {
  content: "\F0E9"; }

.b-fa-umbrella-beach:before {
  content: "\F5CA"; }

.b-fa-underline:before {
  content: "\F0CD"; }

.b-fa-undo:before {
  content: "\F0E2"; }

.b-fa-undo-alt:before {
  content: "\F2EA"; }

.b-fa-unicorn:before {
  content: "\F727"; }

.b-fa-union:before {
  content: "\F6A2"; }

.b-fa-uniregistry:before {
  content: "\F404"; }

.b-fa-universal-access:before {
  content: "\F29A"; }

.b-fa-university:before {
  content: "\F19C"; }

.b-fa-unlink:before, .b-icon-unassign:before {
  content: "\F127"; }

.b-fa-unlock:before {
  content: "\F09C"; }

.b-fa-unlock-alt:before {
  content: "\F13E"; }

.b-fa-untappd:before {
  content: "\F405"; }

.b-fa-upload:before {
  content: "\F093"; }

.b-fa-ups:before {
  content: "\F7E0"; }

.b-fa-usb:before {
  content: "\F287"; }

.b-fa-usd-circle:before {
  content: "\F2E8"; }

.b-fa-usd-square:before {
  content: "\F2E9"; }

.b-fa-user:before {
  content: "\F007"; }

.b-fa-user-alt:before {
  content: "\F406"; }

.b-fa-user-alt-slash:before {
  content: "\F4FA"; }

.b-fa-user-astronaut:before {
  content: "\F4FB"; }

.b-fa-user-chart:before {
  content: "\F6A3"; }

.b-fa-user-check:before {
  content: "\F4FC"; }

.b-fa-user-circle:before {
  content: "\F2BD"; }

.b-fa-user-clock:before {
  content: "\F4FD"; }

.b-fa-user-cog:before {
  content: "\F4FE"; }

.b-fa-user-crown:before {
  content: "\F6A4"; }

.b-fa-user-edit:before {
  content: "\F4FF"; }

.b-fa-user-friends:before {
  content: "\F500"; }

.b-fa-user-graduate:before {
  content: "\F501"; }

.b-fa-user-hard-hat:before {
  content: "\F82C"; }

.b-fa-user-headset:before {
  content: "\F82D"; }

.b-fa-user-injured:before {
  content: "\F728"; }

.b-fa-user-lock:before {
  content: "\F502"; }

.b-fa-user-md:before {
  content: "\F0F0"; }

.b-fa-user-md-chat:before {
  content: "\F82E"; }

.b-fa-user-minus:before {
  content: "\F503"; }

.b-fa-user-ninja:before {
  content: "\F504"; }

.b-fa-user-nurse:before {
  content: "\F82F"; }

.b-fa-user-plus:before {
  content: "\F234"; }

.b-fa-user-secret:before {
  content: "\F21B"; }

.b-fa-user-shield:before {
  content: "\F505"; }

.b-fa-user-slash:before {
  content: "\F506"; }

.b-fa-user-tag:before {
  content: "\F507"; }

.b-fa-user-tie:before {
  content: "\F508"; }

.b-fa-user-times:before {
  content: "\F235"; }

.b-fa-users:before {
  content: "\F0C0"; }

.b-fa-users-class:before {
  content: "\F63D"; }

.b-fa-users-cog:before {
  content: "\F509"; }

.b-fa-users-crown:before {
  content: "\F6A5"; }

.b-fa-users-medical:before {
  content: "\F830"; }

.b-fa-usps:before {
  content: "\F7E1"; }

.b-fa-ussunnah:before {
  content: "\F407"; }

.b-fa-utensil-fork:before {
  content: "\F2E3"; }

.b-fa-utensil-knife:before {
  content: "\F2E4"; }

.b-fa-utensil-spoon:before {
  content: "\F2E5"; }

.b-fa-utensils:before {
  content: "\F2E7"; }

.b-fa-utensils-alt:before {
  content: "\F2E6"; }

.b-fa-vaadin:before {
  content: "\F408"; }

.b-fa-value-absolute:before {
  content: "\F6A6"; }

.b-fa-vector-square:before {
  content: "\F5CB"; }

.b-fa-venus:before {
  content: "\F221"; }

.b-fa-venus-double:before {
  content: "\F226"; }

.b-fa-venus-mars:before {
  content: "\F228"; }

.b-fa-viacoin:before {
  content: "\F237"; }

.b-fa-viadeo:before {
  content: "\F2A9"; }

.b-fa-viadeo-square:before {
  content: "\F2AA"; }

.b-fa-vial:before {
  content: "\F492"; }

.b-fa-vials:before {
  content: "\F493"; }

.b-fa-viber:before {
  content: "\F409"; }

.b-fa-video:before {
  content: "\F03D"; }

.b-fa-video-plus:before {
  content: "\F4E1"; }

.b-fa-video-slash:before {
  content: "\F4E2"; }

.b-fa-vihara:before {
  content: "\F6A7"; }

.b-fa-vimeo:before {
  content: "\F40A"; }

.b-fa-vimeo-square:before {
  content: "\F194"; }

.b-fa-vimeo-v:before {
  content: "\F27D"; }

.b-fa-vine:before {
  content: "\F1CA"; }

.b-fa-vk:before {
  content: "\F189"; }

.b-fa-vnv:before {
  content: "\F40B"; }

.b-fa-volcano:before {
  content: "\F770"; }

.b-fa-volleyball-ball:before {
  content: "\F45F"; }

.b-fa-volume:before {
  content: "\F6A8"; }

.b-fa-volume-down:before {
  content: "\F027"; }

.b-fa-volume-mute:before {
  content: "\F6A9"; }

.b-fa-volume-off:before {
  content: "\F026"; }

.b-fa-volume-slash:before {
  content: "\F2E2"; }

.b-fa-volume-up:before {
  content: "\F028"; }

.b-fa-vote-nay:before {
  content: "\F771"; }

.b-fa-vote-yea:before {
  content: "\F772"; }

.b-fa-vr-cardboard:before {
  content: "\F729"; }

.b-fa-vuejs:before {
  content: "\F41F"; }

.b-fa-walker:before {
  content: "\F831"; }

.b-fa-walking:before {
  content: "\F554"; }

.b-fa-wallet:before {
  content: "\F555"; }

.b-fa-wand:before {
  content: "\F72A"; }

.b-fa-wand-magic:before {
  content: "\F72B"; }

.b-fa-warehouse:before {
  content: "\F494"; }

.b-fa-warehouse-alt:before {
  content: "\F495"; }

.b-fa-watch:before {
  content: "\F2E1"; }

.b-fa-watch-fitness:before {
  content: "\F63E"; }

.b-fa-water:before {
  content: "\F773"; }

.b-fa-water-lower:before {
  content: "\F774"; }

.b-fa-water-rise:before {
  content: "\F775"; }

.b-fa-wave-square:before {
  content: "\F83E"; }

.b-fa-waze:before {
  content: "\F83F"; }

.b-fa-webcam:before {
  content: "\F832"; }

.b-fa-webcam-slash:before {
  content: "\F833"; }

.b-fa-weebly:before {
  content: "\F5CC"; }

.b-fa-weibo:before {
  content: "\F18A"; }

.b-fa-weight:before {
  content: "\F496"; }

.b-fa-weight-hanging:before {
  content: "\F5CD"; }

.b-fa-weixin:before {
  content: "\F1D7"; }

.b-fa-whale:before {
  content: "\F72C"; }

.b-fa-whatsapp:before {
  content: "\F232"; }

.b-fa-whatsapp-square:before {
  content: "\F40C"; }

.b-fa-wheat:before {
  content: "\F72D"; }

.b-fa-wheelchair:before {
  content: "\F193"; }

.b-fa-whistle:before {
  content: "\F460"; }

.b-fa-whmcs:before {
  content: "\F40D"; }

.b-fa-wifi:before {
  content: "\F1EB"; }

.b-fa-wifi-1:before {
  content: "\F6AA"; }

.b-fa-wifi-2:before {
  content: "\F6AB"; }

.b-fa-wifi-slash:before {
  content: "\F6AC"; }

.b-fa-wikipedia-w:before {
  content: "\F266"; }

.b-fa-wind:before {
  content: "\F72E"; }

.b-fa-wind-warning:before {
  content: "\F776"; }

.b-fa-window:before {
  content: "\F40E"; }

.b-fa-window-alt:before {
  content: "\F40F"; }

.b-fa-window-close:before {
  content: "\F410"; }

.b-fa-window-maximize:before {
  content: "\F2D0"; }

.b-fa-window-minimize:before {
  content: "\F2D1"; }

.b-fa-window-restore:before {
  content: "\F2D2"; }

.b-fa-windows:before {
  content: "\F17A"; }

.b-fa-windsock:before {
  content: "\F777"; }

.b-fa-wine-bottle:before {
  content: "\F72F"; }

.b-fa-wine-glass:before {
  content: "\F4E3"; }

.b-fa-wine-glass-alt:before {
  content: "\F5CE"; }

.b-fa-wix:before {
  content: "\F5CF"; }

.b-fa-wizards-of-the-coast:before {
  content: "\F730"; }

.b-fa-wolf-pack-battalion:before {
  content: "\F514"; }

.b-fa-won-sign:before {
  content: "\F159"; }

.b-fa-wordpress:before {
  content: "\F19A"; }

.b-fa-wordpress-simple:before {
  content: "\F411"; }

.b-fa-wpbeginner:before {
  content: "\F297"; }

.b-fa-wpexplorer:before {
  content: "\F2DE"; }

.b-fa-wpforms:before {
  content: "\F298"; }

.b-fa-wpressr:before {
  content: "\F3E4"; }

.b-fa-wreath:before {
  content: "\F7E2"; }

.b-fa-wrench:before {
  content: "\F0AD"; }

.b-fa-x-ray:before {
  content: "\F497"; }

.b-fa-xbox:before {
  content: "\F412"; }

.b-fa-xing:before {
  content: "\F168"; }

.b-fa-xing-square:before {
  content: "\F169"; }

.b-fa-y-combinator:before {
  content: "\F23B"; }

.b-fa-yahoo:before {
  content: "\F19E"; }

.b-fa-yammer:before {
  content: "\F840"; }

.b-fa-yandex:before {
  content: "\F413"; }

.b-fa-yandex-international:before {
  content: "\F414"; }

.b-fa-yarn:before {
  content: "\F7E3"; }

.b-fa-yelp:before {
  content: "\F1E9"; }

.b-fa-yen-sign:before {
  content: "\F157"; }

.b-fa-yin-yang:before {
  content: "\F6AD"; }

.b-fa-yoast:before {
  content: "\F2B1"; }

.b-fa-youtube:before {
  content: "\F167"; }

.b-fa-youtube-square:before {
  content: "\F431"; }

.b-fa-zhihu:before {
  content: "\F63F"; }

.b-fa-fw, .b-fw-icon:before {
  text-align: center;
  width: 1.25em; }

.b-fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.b-fa {
  font-family: inherit;
  line-height: inherit;
  -webkit-font-smoothing: inherit; }

.b-fa:before {
  font-family: "Font Awesome 5 Pro"; }

.b-icon:before, .b-row-reordering .b-row-reordering-target-parent .b-tree-cell::before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  vertical-align: 0; }

.b-fw-icon:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  vertical-align: 0; }

.b-content-icon, .b-checkbox .b-checkbox-label:before, .b-grid.b-column-resize.b-touch.b-column-resizing .b-grid-header.b-resizing::before, .b-moving-splitter.b-touch .b-grid-splitter::before, .b-grid-cell.b-group-title:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  vertical-align: 0; }

.b-icon-tree-collapse:before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.b-icon-tree-leaf:before {
  content: '\2022'; }

.b-icon-clock-live {
  background-color: #b0b1b2;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  position: relative;
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
  .b-icon-clock-live:before, .b-icon-clock-live:after {
    position: absolute;
    display: block;
    font-size: 1em !important;
    content: "";
    width: .10em !important;
    left: .44em;
    /* half width of clock - half width of hand */
    background: #fff;
    border-radius: .5em; }
  .b-icon-clock-live:before {
    top: .15em;
    height: .4em !important;
    /* half the width/height of the clock */
    -webkit-transform-origin: .05em .35em;
            transform-origin: .05em .35em;
    -webkit-animation: rotate 6s infinite linear;
            animation: rotate 6s infinite linear;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
    -webkit-animation-delay: inherit;
            animation-delay: inherit; }
  .b-icon-clock-live:after {
    top: .22em;
    height: .33em;
    /* top + height for little hand must = top + height for big hand */
    -webkit-transform-origin: .05em .3em;
            transform-origin: .05em .3em;
    -webkit-animation: rotate 72s infinite linear;
            animation: rotate 72s infinite linear;
    /* time should be big-hand time * 12 */
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
    -webkit-animation-delay: inherit;
            animation-delay: inherit; }

/* Animations */
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.b-icon-spinner:before {
  -webkit-animation: rotate 2s infinite linear;
          animation: rotate 2s infinite linear; }

.b-color-blue {
  background-color: #3183fe; }

.b-color-deep-orange {
  background-color: #ff5722; }

.b-color-orange {
  background-color: #feac31; }

.b-color-amber {
  background-color: #ffc107; }

.b-color-indigo {
  background-color: #4531fe; }

.b-color-lime {
  background-color: #83fe31; }

.b-color-teal {
  background-color: #31feac; }

.b-color-green {
  background-color: #27ca37; }

.b-color-light-green {
  background-color: #9efea7; }

.b-color-purple {
  background-color: #fe31ea; }

.b-color-yellow {
  background-color: #ecfe26; }

.b-color-red {
  background-color: #e53f2c; }

.b-color-dark-gray {
  background-color: #474849; }

.b-color-gray {
  background-color: #b0b0b7; }

.b-color-light-gray {
  background-color: #e0e0e7; }

.b-color-white {
  background-color: #fff; }

.b-noselect {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.b-released,
.b-hide-display {
  display: none !important; }

.b-fx-highlight {
  -webkit-animation: b-fx-highlight-animation 1s ease 0s 1;
          animation: b-fx-highlight-animation 1s ease 0s 1; }

@-webkit-keyframes b-fx-highlight-animation {
  0% {
    -webkit-box-shadow: none;
            box-shadow: none; }
  50% {
    -webkit-box-shadow: 0 0 10px 5px rgba(253, 126, 20, 0.5);
            box-shadow: 0 0 10px 5px rgba(253, 126, 20, 0.5); }
  100% {
    -webkit-box-shadow: none;
            box-shadow: none; } }

@keyframes b-fx-highlight-animation {
  0% {
    -webkit-box-shadow: none;
            box-shadow: none; }
  50% {
    -webkit-box-shadow: 0 0 10px 5px rgba(253, 126, 20, 0.5);
            box-shadow: 0 0 10px 5px rgba(253, 126, 20, 0.5); }
  100% {
    -webkit-box-shadow: none;
            box-shadow: none; } }

/**
 * DomHelper size monitoring polyfill for when ResizeObserver not available
 */
.b-resize-monitored {
  position: relative; }
  .b-resize-monitored .b-resize-monitors {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    overflow: hidden; }
    .b-resize-monitored .b-resize-monitors > * {
      width: 100%;
      height: 100%;
      overflow: hidden; }
    .b-resize-monitored .b-resize-monitors > .b-resize-monitor-expand:after {
      content: '';
      display: block;
      width: 100000px;
      height: 100000px; }
    .b-resize-monitored .b-resize-monitors > .b-resize-monitor-shrink:after {
      content: '';
      display: block;
      width: 200%;
      height: 200%;
      min-width: 1px;
      min-height: 1px; }

.b-float-root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 10;
  contain: strict;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .b-float-root > .b-floating {
    position: absolute;
    contain: layout style;
    pointer-events: all;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
    top: 0;
    left: 0;
    overflow: visible; }
    .b-float-root > .b-floating.b-hidden {
      opacity: 0; }
    .b-float-root > .b-floating.b-measure {
      display: block !important; }
  .b-float-root > .b-floating.b-centered {
    position: static; }

.b-dragging {
  position: absolute !important;
  z-index: 10000;
  pointer-events: none;
  opacity: 0.8;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

/*
 Used when Drag code is creating a separate proxy element representing the dragged element,
   as opposed to the 'inline' drag of the actual element
*/
.b-drag-proxy {
  top: 0;
  left: 0;
  pointer-events: none !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .b-drag-proxy * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }

.b-aborting {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s; }

.b-drag-original.b-hidden {
  display: none !important; }

.simulated-mouse {
  position: absolute;
  z-index: 100;
  top: 10px;
  left: 10px;
  -webkit-transition: top .5s, left .5s;
  transition: top .5s, left .5s;
  pointer-events: none;
  font-size: 16px; }
  .simulated-mouse.quick {
    -webkit-transition: top .05s, left .05s;
    transition: top .05s, left .05s; }
  .simulated-mouse:after {
    position: absolute;
    content: '\F245';
    font-family: "Font Awesome 5 Pro";
    z-index: 102; }
  .simulated-mouse.drag:before, .simulated-mouse.mousedown:before, .simulated-mouse.dblclick:before, .simulated-mouse.click:before {
    position: absolute;
    content: '';
    border: 2px solid transparent;
    border-radius: 50%;
    -webkit-animation-name: click;
            animation-name: click;
    -webkit-animation-duration: .2s;
            animation-duration: .2s;
    top: 0;
    left: 0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 101;
    opacity: 0.7; }
  .simulated-mouse.drag:after {
    content: '\F25A';
    left: -3px; }
  .simulated-mouse.dblclick:before {
    -webkit-animation-name: dblclick;
            animation-name: dblclick;
    -webkit-animation-duration: .3s;
            animation-duration: .3s; }
  .simulated-mouse.mousedown:before, .simulated-mouse.drag:before {
    -webkit-animation-name: none;
            animation-name: none;
    width: 1.5em;
    height: 1.5em;
    border-color: red; }

@-webkit-keyframes click {
  0% {
    width: 0;
    height: 0; }
  90% {
    width: 1.5em;
    height: 1.5em;
    border-color: red; }
  100% {
    width: 0;
    height: 0;
    border-color: transparent; } }

@keyframes click {
  0% {
    width: 0;
    height: 0; }
  90% {
    width: 1.5em;
    height: 1.5em;
    border-color: red; }
  100% {
    width: 0;
    height: 0;
    border-color: transparent; } }

@-webkit-keyframes dblclick {
  0% {
    width: 0;
    height: 0; }
  40% {
    width: 1.5em;
    height: 1.5em;
    border-color: red; }
  50% {
    width: 0;
    height: 0; }
  90% {
    width: 1.5em;
    height: 1.5em;
    border-color: red; }
  100% {
    width: 0;
    height: 0;
    border-color: transparent; } }

@keyframes dblclick {
  0% {
    width: 0;
    height: 0; }
  40% {
    width: 1.5em;
    height: 1.5em;
    border-color: red; }
  50% {
    width: 0;
    height: 0; }
  90% {
    width: 1.5em;
    height: 1.5em;
    border-color: red; }
  100% {
    width: 0;
    height: 0;
    border-color: transparent; } }

.b-widget {
  display: -ms-inline-flexbox;
  display: inline-flex;
  overflow: hidden;
  line-height: normal;
  line-height: initial;
  font-weight: 400;
  font-size: 1em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .b-widget * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .b-widget:before, .b-widget:after,
  .b-widget *:before,
  .b-widget *:after {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .b-widget.b-positioned {
    position: absolute;
    top: 0;
    left: 0; }
  .b-widget .b-focus-trap {
    position: absolute;
    display: none;
    clip: rect(0, 0, 0, 0); }
  .b-widget.b-focus-trapped.b-contains-focus .b-focus-trap {
    display: inherit; }
  .b-widget.b-hidden {
    display: none !important; }
  .b-widget.b-hiding {
    pointer-events: none; }
  .b-widget .b-widget-inner {
    width: 100%;
    position: relative;
    -ms-flex: 1 1;
        flex: 1 1; }
  .b-widget .b-anchor {
    width: 16px;
    height: 8px;
    position: absolute;
    z-index: -1; }
    .b-widget .b-anchor svg {
      position: absolute;
      top: 0;
      overflow: visible; }
    .b-widget .b-anchor.b-anchor-top {
      left: 0;
      bottom: calc(100% - 1px); }
    .b-widget .b-anchor.b-anchor-bottom {
      left: 0;
      top: calc(100% - 1px); }
      .b-widget .b-anchor.b-anchor-bottom svg {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
    .b-widget .b-anchor.b-anchor-left {
      top: 0;
      width: 8px;
      height: 16px;
      right: calc(100% - 1px); }
      .b-widget .b-anchor.b-anchor-left svg {
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg);
        -webkit-transform-origin: 8px 8px;
                transform-origin: 8px 8px; }
    .b-widget .b-anchor.b-anchor-right {
      top: 0;
      width: 8px;
      height: 16px;
      left: calc(100% - 1px); }
      .b-widget .b-anchor.b-anchor-right svg {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        -webkit-transform-origin: 4px 4px;
                transform-origin: 4px 4px; }

.b-widget-scroller {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-anchor: none;
  /* without this we see weird issues in Mac Chrome <= 63 with no scrollbar, covered in Tree.t.js */ }
  .b-widget-scroller.b-hide-scroll {
    -ms-overflow-style: none; }
    .b-widget-scroller.b-hide-scroll::-webkit-scrollbar {
      display: none; }
    .b-firefox .b-widget-scroller.b-hide-scroll {
      overflow: hidden !important; }

.b-scroller-stretcher {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 1px; }

.b-widget.b-badge {
  overflow: visible; }

.b-badge:before {
  content: attr(data-badge);
  position: absolute;
  min-width: 2.223em;
  min-height: 2.223em;
  right: -1em;
  top: -1em;
  padding: .5em;
  background-color: #e53f2c;
  color: #fff;
  border-radius: 50%;
  font-size: .6em;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -webkit-animation-name: b-badge-show;
          animation-name: b-badge-show;
  -webkit-animation-duration: .3s;
          animation-duration: .3s; }

.b-button.b-badge:before {
  font-size: .7em; }

@-webkit-keyframes b-badge-show {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(180deg);
            transform: scale(0.1) rotate(180deg); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg); } }

@keyframes b-badge-show {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(180deg);
            transform: scale(0.1) rotate(180deg); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg); } }

button.b-button {
  position: relative;
  padding: 0 1em;
  border-radius: 2px;
  min-width: 3em;
  min-height: 2.7em;
  border: 1px solid;
  cursor: pointer;
  font-weight: 400;
  -webkit-transition: background-color 200ms, color 200ms, border 200ms;
  transition: background-color 200ms, color 200ms, border 200ms;
  white-space: nowrap;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  text-transform: uppercase; }
  button.b-button.b-ie {
    height: 2.7em; }
  button.b-button::-moz-focus-inner {
    border: 0; }
  button.b-button.b-text.b-icon-align-start i {
    -ms-flex-order: -100;
        order: -100;
    margin-right: 0.5em; }
  button.b-button.b-text.b-icon-align-end i {
    -ms-flex-order: 100;
        order: 100;
    margin-left: 0.5em; }
  button.b-button i {
    font-size: 1em; }
  button.b-button.b-badge, button.b-button.b-badge.b-text {
    overflow: visible; }
  button.b-button:focus {
    outline: none; }
  button.b-button:disabled {
    cursor: default; }
  .b-toolbar button.b-button {
    font-size: 0.9em; }

.b-button.b-white {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.03); }
  body.b-using-keyboard .b-button.b-white:focus {
    background-color: rgba(255, 255, 255, 0.3); }
  .b-button.b-white.b-pressed {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.8); }
  .b-button.b-white:hover:not([disabled]) {
    color: #fff;
    background-color: #fff; }
    .b-button.b-white:hover:not([disabled]):not(.b-pressed) {
      border-color: #fff; }
  .b-button.b-white.b-pressed:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.7); }
  .b-button.b-white:active:not([disabled]) {
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .b-button.b-white.b-disabled {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3); }

.b-button.b-blue {
  color: #3183fe;
  border-color: rgba(49, 131, 254, 0.5);
  background-color: rgba(49, 131, 254, 0.03); }
  body.b-using-keyboard .b-button.b-blue:focus {
    background-color: rgba(49, 131, 254, 0.3); }
  .b-button.b-blue.b-pressed {
    color: #fff;
    background-color: rgba(49, 131, 254, 0.8); }
  .b-button.b-blue:hover:not([disabled]) {
    color: #fff;
    background-color: #3183fe; }
    .b-button.b-blue:hover:not([disabled]):not(.b-pressed) {
      border-color: #3183fe; }
  .b-button.b-blue.b-pressed:hover {
    color: #fff;
    background-color: rgba(49, 131, 254, 0.7); }
  .b-button.b-blue:active:not([disabled]) {
    background-color: #1874fe;
    border-color: #1874fe; }
  .b-button.b-blue.b-disabled {
    background-color: transparent;
    color: rgba(49, 131, 254, 0.3);
    border-color: rgba(49, 131, 254, 0.3); }

.b-button.b-deep-orange {
  color: #ff5722;
  border-color: rgba(255, 87, 34, 0.5);
  background-color: rgba(255, 87, 34, 0.03); }
  body.b-using-keyboard .b-button.b-deep-orange:focus {
    background-color: rgba(255, 87, 34, 0.3); }
  .b-button.b-deep-orange.b-pressed {
    color: #fff;
    background-color: rgba(255, 87, 34, 0.8); }
  .b-button.b-deep-orange:hover:not([disabled]) {
    color: #fff;
    background-color: #ff5722; }
    .b-button.b-deep-orange:hover:not([disabled]):not(.b-pressed) {
      border-color: #ff5722; }
  .b-button.b-deep-orange.b-pressed:hover {
    color: #fff;
    background-color: rgba(255, 87, 34, 0.7); }
  .b-button.b-deep-orange:active:not([disabled]) {
    background-color: #ff4409;
    border-color: #ff4409; }
  .b-button.b-deep-orange.b-disabled {
    background-color: transparent;
    color: rgba(255, 87, 34, 0.3);
    border-color: rgba(255, 87, 34, 0.3); }

.b-button.b-orange {
  color: #feac31;
  border-color: rgba(254, 172, 49, 0.5);
  background-color: rgba(254, 172, 49, 0.03); }
  body.b-using-keyboard .b-button.b-orange:focus {
    background-color: rgba(254, 172, 49, 0.3); }
  .b-button.b-orange.b-pressed {
    color: #fff;
    background-color: rgba(254, 172, 49, 0.8); }
  .b-button.b-orange:hover:not([disabled]) {
    color: #fff;
    background-color: #feac31; }
    .b-button.b-orange:hover:not([disabled]):not(.b-pressed) {
      border-color: #feac31; }
  .b-button.b-orange.b-pressed:hover {
    color: #fff;
    background-color: rgba(254, 172, 49, 0.7); }
  .b-button.b-orange:active:not([disabled]) {
    background-color: #fea218;
    border-color: #fea218; }
  .b-button.b-orange.b-disabled {
    background-color: transparent;
    color: rgba(254, 172, 49, 0.3);
    border-color: rgba(254, 172, 49, 0.3); }

.b-button.b-amber {
  color: #ffc107;
  border-color: rgba(255, 193, 7, 0.5);
  background-color: rgba(255, 193, 7, 0.03); }
  body.b-using-keyboard .b-button.b-amber:focus {
    background-color: rgba(255, 193, 7, 0.3); }
  .b-button.b-amber.b-pressed {
    color: #fff;
    background-color: rgba(255, 193, 7, 0.8); }
  .b-button.b-amber:hover:not([disabled]) {
    color: #fff;
    background-color: #ffc107; }
    .b-button.b-amber:hover:not([disabled]):not(.b-pressed) {
      border-color: #ffc107; }
  .b-button.b-amber.b-pressed:hover {
    color: #fff;
    background-color: rgba(255, 193, 7, 0.7); }
  .b-button.b-amber:active:not([disabled]) {
    background-color: #edb100;
    border-color: #edb100; }
  .b-button.b-amber.b-disabled {
    background-color: transparent;
    color: rgba(255, 193, 7, 0.3);
    border-color: rgba(255, 193, 7, 0.3); }

.b-button.b-indigo {
  color: #4531fe;
  border-color: rgba(69, 49, 254, 0.5);
  background-color: rgba(69, 49, 254, 0.03); }
  body.b-using-keyboard .b-button.b-indigo:focus {
    background-color: rgba(69, 49, 254, 0.3); }
  .b-button.b-indigo.b-pressed {
    color: #fff;
    background-color: rgba(69, 49, 254, 0.8); }
  .b-button.b-indigo:hover:not([disabled]) {
    color: #fff;
    background-color: #4531fe; }
    .b-button.b-indigo:hover:not([disabled]):not(.b-pressed) {
      border-color: #4531fe; }
  .b-button.b-indigo.b-pressed:hover {
    color: #fff;
    background-color: rgba(69, 49, 254, 0.7); }
  .b-button.b-indigo:active:not([disabled]) {
    background-color: #2e18fe;
    border-color: #2e18fe; }
  .b-button.b-indigo.b-disabled {
    background-color: transparent;
    color: rgba(69, 49, 254, 0.3);
    border-color: rgba(69, 49, 254, 0.3); }

.b-button.b-lime {
  color: #83fe31;
  border-color: rgba(131, 254, 49, 0.5);
  background-color: rgba(131, 254, 49, 0.03); }
  body.b-using-keyboard .b-button.b-lime:focus {
    background-color: rgba(131, 254, 49, 0.3); }
  .b-button.b-lime.b-pressed {
    color: #fff;
    background-color: rgba(131, 254, 49, 0.8); }
  .b-button.b-lime:hover:not([disabled]) {
    color: #fff;
    background-color: #83fe31; }
    .b-button.b-lime:hover:not([disabled]):not(.b-pressed) {
      border-color: #83fe31; }
  .b-button.b-lime.b-pressed:hover {
    color: #fff;
    background-color: rgba(131, 254, 49, 0.7); }
  .b-button.b-lime:active:not([disabled]) {
    background-color: #74fe18;
    border-color: #74fe18; }
  .b-button.b-lime.b-disabled {
    background-color: transparent;
    color: rgba(131, 254, 49, 0.3);
    border-color: rgba(131, 254, 49, 0.3); }

.b-button.b-teal {
  color: #31feac;
  border-color: rgba(49, 254, 172, 0.5);
  background-color: rgba(49, 254, 172, 0.03); }
  body.b-using-keyboard .b-button.b-teal:focus {
    background-color: rgba(49, 254, 172, 0.3); }
  .b-button.b-teal.b-pressed {
    color: #fff;
    background-color: rgba(49, 254, 172, 0.8); }
  .b-button.b-teal:hover:not([disabled]) {
    color: #fff;
    background-color: #31feac; }
    .b-button.b-teal:hover:not([disabled]):not(.b-pressed) {
      border-color: #31feac; }
  .b-button.b-teal.b-pressed:hover {
    color: #fff;
    background-color: rgba(49, 254, 172, 0.7); }
  .b-button.b-teal:active:not([disabled]) {
    background-color: #18fea2;
    border-color: #18fea2; }
  .b-button.b-teal.b-disabled {
    background-color: transparent;
    color: rgba(49, 254, 172, 0.3);
    border-color: rgba(49, 254, 172, 0.3); }

.b-button.b-green {
  color: #27ca37;
  border-color: rgba(39, 202, 55, 0.5);
  background-color: rgba(39, 202, 55, 0.03); }
  body.b-using-keyboard .b-button.b-green:focus {
    background-color: rgba(39, 202, 55, 0.3); }
  .b-button.b-green.b-pressed {
    color: #fff;
    background-color: rgba(39, 202, 55, 0.8); }
  .b-button.b-green:hover:not([disabled]) {
    color: #fff;
    background-color: #27ca37; }
    .b-button.b-green:hover:not([disabled]):not(.b-pressed) {
      border-color: #27ca37; }
  .b-button.b-green.b-pressed:hover {
    color: #fff;
    background-color: rgba(39, 202, 55, 0.7); }
  .b-button.b-green:active:not([disabled]) {
    background-color: #23b531;
    border-color: #23b531; }
  .b-button.b-green.b-disabled {
    background-color: transparent;
    color: rgba(39, 202, 55, 0.3);
    border-color: rgba(39, 202, 55, 0.3); }

.b-button.b-light-green {
  color: #9efea7;
  border-color: rgba(158, 254, 167, 0.5);
  background-color: rgba(158, 254, 167, 0.03); }
  body.b-using-keyboard .b-button.b-light-green:focus {
    background-color: rgba(158, 254, 167, 0.3); }
  .b-button.b-light-green.b-pressed {
    color: #fff;
    background-color: rgba(158, 254, 167, 0.8); }
  .b-button.b-light-green:hover:not([disabled]) {
    color: #fff;
    background-color: #9efea7; }
    .b-button.b-light-green:hover:not([disabled]):not(.b-pressed) {
      border-color: #9efea7; }
  .b-button.b-light-green.b-pressed:hover {
    color: #fff;
    background-color: rgba(158, 254, 167, 0.7); }
  .b-button.b-light-green:active:not([disabled]) {
    background-color: #85fe90;
    border-color: #85fe90; }
  .b-button.b-light-green.b-disabled {
    background-color: transparent;
    color: rgba(158, 254, 167, 0.3);
    border-color: rgba(158, 254, 167, 0.3); }

.b-button.b-purple {
  color: #fe31ea;
  border-color: rgba(254, 49, 234, 0.5);
  background-color: rgba(254, 49, 234, 0.03); }
  body.b-using-keyboard .b-button.b-purple:focus {
    background-color: rgba(254, 49, 234, 0.3); }
  .b-button.b-purple.b-pressed {
    color: #fff;
    background-color: rgba(254, 49, 234, 0.8); }
  .b-button.b-purple:hover:not([disabled]) {
    color: #fff;
    background-color: #fe31ea; }
    .b-button.b-purple:hover:not([disabled]):not(.b-pressed) {
      border-color: #fe31ea; }
  .b-button.b-purple.b-pressed:hover {
    color: #fff;
    background-color: rgba(254, 49, 234, 0.7); }
  .b-button.b-purple:active:not([disabled]) {
    background-color: #fe18e7;
    border-color: #fe18e7; }
  .b-button.b-purple.b-disabled {
    background-color: transparent;
    color: rgba(254, 49, 234, 0.3);
    border-color: rgba(254, 49, 234, 0.3); }

.b-button.b-yellow {
  color: #ecfe26;
  border-color: rgba(236, 254, 38, 0.5);
  background-color: rgba(236, 254, 38, 0.03); }
  body.b-using-keyboard .b-button.b-yellow:focus {
    background-color: rgba(236, 254, 38, 0.3); }
  .b-button.b-yellow.b-pressed {
    color: #fff;
    background-color: rgba(236, 254, 38, 0.8); }
  .b-button.b-yellow:hover:not([disabled]) {
    color: #fff;
    background-color: #ecfe26; }
    .b-button.b-yellow:hover:not([disabled]):not(.b-pressed) {
      border-color: #ecfe26; }
  .b-button.b-yellow.b-pressed:hover {
    color: #fff;
    background-color: rgba(236, 254, 38, 0.7); }
  .b-button.b-yellow:active:not([disabled]) {
    background-color: #eafe0d;
    border-color: #eafe0d; }
  .b-button.b-yellow.b-disabled {
    background-color: transparent;
    color: rgba(236, 254, 38, 0.3);
    border-color: rgba(236, 254, 38, 0.3); }

.b-button.b-red {
  color: #e53f2c;
  border-color: rgba(229, 63, 44, 0.5);
  background-color: rgba(229, 63, 44, 0.03); }
  body.b-using-keyboard .b-button.b-red:focus {
    background-color: rgba(229, 63, 44, 0.3); }
  .b-button.b-red.b-pressed {
    color: #fff;
    background-color: rgba(229, 63, 44, 0.8); }
  .b-button.b-red:hover:not([disabled]) {
    color: #fff;
    background-color: #e53f2c; }
    .b-button.b-red:hover:not([disabled]):not(.b-pressed) {
      border-color: #e53f2c; }
  .b-button.b-red.b-pressed:hover {
    color: #fff;
    background-color: rgba(229, 63, 44, 0.7); }
  .b-button.b-red:active:not([disabled]) {
    background-color: #dc2f1b;
    border-color: #dc2f1b; }
  .b-button.b-red.b-disabled {
    background-color: transparent;
    color: rgba(229, 63, 44, 0.3);
    border-color: rgba(229, 63, 44, 0.3); }

.b-button.b-dark-gray {
  color: #474849;
  border-color: rgba(71, 72, 73, 0.5);
  background-color: rgba(71, 72, 73, 0.03); }
  body.b-using-keyboard .b-button.b-dark-gray:focus {
    background-color: rgba(71, 72, 73, 0.3); }
  .b-button.b-dark-gray.b-pressed {
    color: #fff;
    background-color: rgba(71, 72, 73, 0.8); }
  .b-button.b-dark-gray:hover:not([disabled]) {
    color: #fff;
    background-color: #474849; }
    .b-button.b-dark-gray:hover:not([disabled]):not(.b-pressed) {
      border-color: #474849; }
  .b-button.b-dark-gray.b-pressed:hover {
    color: #fff;
    background-color: rgba(71, 72, 73, 0.7); }
  .b-button.b-dark-gray:active:not([disabled]) {
    background-color: #3a3b3c;
    border-color: #3a3b3c; }
  .b-button.b-dark-gray.b-disabled {
    background-color: transparent;
    color: rgba(71, 72, 73, 0.3);
    border-color: rgba(71, 72, 73, 0.3); }

.b-button.b-gray {
  color: #b0b0b7;
  border-color: rgba(176, 176, 183, 0.5);
  background-color: rgba(176, 176, 183, 0.03); }
  body.b-using-keyboard .b-button.b-gray:focus {
    background-color: rgba(176, 176, 183, 0.3); }
  .b-button.b-gray.b-pressed {
    color: #fff;
    background-color: rgba(176, 176, 183, 0.8); }
  .b-button.b-gray:hover:not([disabled]) {
    color: #fff;
    background-color: #b0b0b7; }
    .b-button.b-gray:hover:not([disabled]):not(.b-pressed) {
      border-color: #b0b0b7; }
  .b-button.b-gray.b-pressed:hover {
    color: #fff;
    background-color: rgba(176, 176, 183, 0.7); }
  .b-button.b-gray:active:not([disabled]) {
    background-color: #a3a3ab;
    border-color: #a3a3ab; }
  .b-button.b-gray.b-disabled {
    background-color: transparent;
    color: rgba(176, 176, 183, 0.3);
    border-color: rgba(176, 176, 183, 0.3); }

.b-button.b-light-gray {
  color: #e0e0e7;
  border-color: rgba(224, 224, 231, 0.5);
  background-color: rgba(224, 224, 231, 0.03); }
  body.b-using-keyboard .b-button.b-light-gray:focus {
    background-color: rgba(224, 224, 231, 0.3); }
  .b-button.b-light-gray.b-pressed {
    color: #fff;
    background-color: rgba(224, 224, 231, 0.8); }
  .b-button.b-light-gray:hover:not([disabled]) {
    color: #fff;
    background-color: #e0e0e7; }
    .b-button.b-light-gray:hover:not([disabled]):not(.b-pressed) {
      border-color: #e0e0e7; }
  .b-button.b-light-gray.b-pressed:hover {
    color: #fff;
    background-color: rgba(224, 224, 231, 0.7); }
  .b-button.b-light-gray:active:not([disabled]) {
    background-color: #d2d2dc;
    border-color: #d2d2dc; }
  .b-button.b-light-gray.b-disabled {
    background-color: transparent;
    color: rgba(224, 224, 231, 0.3);
    border-color: rgba(224, 224, 231, 0.3); }

.b-button {
  color: #b0b0b7;
  border-color: rgba(176, 176, 183, 0.5);
  background-color: rgba(176, 176, 183, 0.03); }
  body.b-using-keyboard .b-button:focus {
    background-color: rgba(176, 176, 183, 0.3); }
  .b-button.b-pressed {
    color: #fff;
    background-color: rgba(176, 176, 183, 0.8); }
  .b-button:hover:not([disabled]) {
    color: #fff;
    background-color: #b0b0b7; }
    .b-button:hover:not([disabled]):not(.b-pressed) {
      border-color: #b0b0b7; }
  .b-button.b-pressed:hover {
    color: #fff;
    background-color: rgba(176, 176, 183, 0.7); }
  .b-button:active:not([disabled]) {
    background-color: #a3a3ab;
    border-color: #a3a3ab; }
  .b-button.b-disabled {
    background-color: transparent;
    color: rgba(176, 176, 183, 0.3);
    border-color: rgba(176, 176, 183, 0.3); }

button.b-button.b-raised.b-blue {
  color: #fff;
  border: none;
  background-color: #3183fe; }
  body.b-using-keyboard button.b-button.b-raised.b-blue:focus {
    background-color: #5f9efe; }
  body.b-using-keyboard button.b-button.b-raised.b-blue:focus:hover:not([disabled]), button.b-button.b-raised.b-blue:hover:not([disabled]) {
    background-color: #468ffe; }
  button.b-button.b-raised.b-blue.b-pressed, button.b-button.b-raised.b-blue.b-pressed[data-group]:hover {
    background-color: #0468fe;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-blue.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-blue.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-blue.b-pressed:focus {
    background-color: #227afe; }
  button.b-button.b-raised.b-blue.b-pressed:active {
    background-color: #014fc4; }
  button.b-button.b-raised.b-blue.b-pressed:active:hover {
    background-color: #015be2; }
  button.b-button.b-raised.b-blue:active:not([disabled]) {
    background-color: #0161f1;
    border-color: #0161f1;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-blue:active:hover:not([disabled]) {
    background-color: #1371fe;
    border-color: #1371fe;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-blue:disabled:hover, button.b-button.b-raised.b-blue:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #0151c8;
    cursor: default; }

button.b-button.b-raised.b-deep-orange {
  color: #fff;
  border: none;
  background-color: #ff5722; }
  body.b-using-keyboard button.b-button.b-raised.b-deep-orange:focus {
    background-color: #ff7a50; }
  body.b-using-keyboard button.b-button.b-raised.b-deep-orange:focus:hover:not([disabled]), button.b-button.b-raised.b-deep-orange:hover:not([disabled]) {
    background-color: #ff6838; }
  button.b-button.b-raised.b-deep-orange.b-pressed, button.b-button.b-raised.b-deep-orange.b-pressed[data-group]:hover {
    background-color: #f63b00;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-deep-orange.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-deep-orange.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-deep-orange.b-pressed:focus {
    background-color: #ff4c14; }
  button.b-button.b-raised.b-deep-orange.b-pressed:active {
    background-color: #bc2d00; }
  button.b-button.b-raised.b-deep-orange.b-pressed:active:hover {
    background-color: #d93400; }
  button.b-button.b-raised.b-deep-orange:active:not([disabled]) {
    background-color: #e73700;
    border-color: #e73700;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-deep-orange:active:hover:not([disabled]) {
    background-color: #ff4105;
    border-color: #ff4105;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-deep-orange:disabled:hover, button.b-button.b-raised.b-deep-orange:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #bb2d00;
    cursor: default; }

button.b-button.b-raised.b-orange {
  color: #fff;
  border: none;
  background-color: #feac31; }
  body.b-using-keyboard button.b-button.b-raised.b-orange:focus {
    background-color: #febe5f; }
  body.b-using-keyboard button.b-button.b-raised.b-orange:focus:hover:not([disabled]), button.b-button.b-raised.b-orange:hover:not([disabled]) {
    background-color: #feb446; }
  button.b-button.b-raised.b-orange.b-pressed, button.b-button.b-raised.b-orange.b-pressed[data-group]:hover {
    background-color: #fe9a04;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-orange.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-orange.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-orange.b-pressed:focus {
    background-color: #fea622; }
  button.b-button.b-raised.b-orange.b-pressed:active {
    background-color: #c47601; }
  button.b-button.b-raised.b-orange.b-pressed:active:hover {
    background-color: #e28801; }
  button.b-button.b-raised.b-orange:active:not([disabled]) {
    background-color: #f19101;
    border-color: #f19101;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-orange:active:hover:not([disabled]) {
    background-color: #fea013;
    border-color: #fea013;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-orange:disabled:hover, button.b-button.b-raised.b-orange:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #c87801;
    cursor: default; }

button.b-button.b-raised.b-amber {
  color: #fff;
  border: none;
  background-color: #ffc107; }
  body.b-using-keyboard button.b-button.b-raised.b-amber:focus {
    background-color: #ffcc35; }
  body.b-using-keyboard button.b-button.b-raised.b-amber:focus:hover:not([disabled]), button.b-button.b-raised.b-amber:hover:not([disabled]) {
    background-color: #ffc720; }
  button.b-button.b-raised.b-amber.b-pressed, button.b-button.b-raised.b-amber.b-pressed[data-group]:hover {
    background-color: #dfa700;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-amber.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-amber.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-amber.b-pressed:focus {
    background-color: #f9bb00; }
  button.b-button.b-raised.b-amber.b-pressed:active {
    background-color: #aa8000; }
  button.b-button.b-raised.b-amber.b-pressed:active:hover {
    background-color: #c59300; }
  button.b-button.b-raised.b-amber:active:not([disabled]) {
    background-color: #d29d00;
    border-color: #d29d00;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-amber:active:hover:not([disabled]) {
    background-color: #ecb100;
    border-color: #ecb100;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-amber:disabled:hover, button.b-button.b-raised.b-amber:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #a07800;
    cursor: default; }

button.b-button.b-raised.b-indigo {
  color: #fff;
  border: none;
  background-color: #4531fe; }
  body.b-using-keyboard button.b-button.b-raised.b-indigo:focus {
    background-color: #6e5ffe; }
  body.b-using-keyboard button.b-button.b-raised.b-indigo:focus:hover:not([disabled]), button.b-button.b-raised.b-indigo:hover:not([disabled]) {
    background-color: #5846fe; }
  button.b-button.b-raised.b-indigo.b-pressed, button.b-button.b-raised.b-indigo.b-pressed[data-group]:hover {
    background-color: #1c04fe;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-indigo.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-indigo.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-indigo.b-pressed:focus {
    background-color: #3722fe; }
  button.b-button.b-raised.b-indigo.b-pressed:active {
    background-color: #1401c4; }
  button.b-button.b-raised.b-indigo.b-pressed:active:hover {
    background-color: #1701e2; }
  button.b-button.b-raised.b-indigo:active:not([disabled]) {
    background-color: #1901f1;
    border-color: #1901f1;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-indigo:active:hover:not([disabled]) {
    background-color: #2a13fe;
    border-color: #2a13fe;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-indigo:disabled:hover, button.b-button.b-raised.b-indigo:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #1401c8;
    cursor: default; }

button.b-button.b-raised.b-lime {
  color: #fff;
  border: none;
  background-color: #83fe31; }
  body.b-using-keyboard button.b-button.b-raised.b-lime:focus {
    background-color: #9efe5f; }
  body.b-using-keyboard button.b-button.b-raised.b-lime:focus:hover:not([disabled]), button.b-button.b-raised.b-lime:hover:not([disabled]) {
    background-color: #8ffe46; }
  button.b-button.b-raised.b-lime.b-pressed, button.b-button.b-raised.b-lime.b-pressed[data-group]:hover {
    background-color: #68fe04;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-lime.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-lime.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-lime.b-pressed:focus {
    background-color: #7afe22; }
  button.b-button.b-raised.b-lime.b-pressed:active {
    background-color: #4fc401; }
  button.b-button.b-raised.b-lime.b-pressed:active:hover {
    background-color: #5be201; }
  button.b-button.b-raised.b-lime:active:not([disabled]) {
    background-color: #61f101;
    border-color: #61f101;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-lime:active:hover:not([disabled]) {
    background-color: #71fe13;
    border-color: #71fe13;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-lime:disabled:hover, button.b-button.b-raised.b-lime:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #51c801;
    cursor: default; }

button.b-button.b-raised.b-teal {
  color: #fff;
  border: none;
  background-color: #31feac; }
  body.b-using-keyboard button.b-button.b-raised.b-teal:focus {
    background-color: #5ffebe; }
  body.b-using-keyboard button.b-button.b-raised.b-teal:focus:hover:not([disabled]), button.b-button.b-raised.b-teal:hover:not([disabled]) {
    background-color: #46feb4; }
  button.b-button.b-raised.b-teal.b-pressed, button.b-button.b-raised.b-teal.b-pressed[data-group]:hover {
    background-color: #04fe9a;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-teal.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-teal.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-teal.b-pressed:focus {
    background-color: #22fea6; }
  button.b-button.b-raised.b-teal.b-pressed:active {
    background-color: #01c476; }
  button.b-button.b-raised.b-teal.b-pressed:active:hover {
    background-color: #01e288; }
  button.b-button.b-raised.b-teal:active:not([disabled]) {
    background-color: #01f191;
    border-color: #01f191;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-teal:active:hover:not([disabled]) {
    background-color: #13fea0;
    border-color: #13fea0;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-teal:disabled:hover, button.b-button.b-raised.b-teal:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #01c878;
    cursor: default; }

button.b-button.b-raised.b-green {
  color: #fff;
  border: none;
  background-color: #27ca37; }
  body.b-using-keyboard button.b-button.b-raised.b-green:focus {
    background-color: #44db53; }
  body.b-using-keyboard button.b-button.b-raised.b-green:focus:hover:not([disabled]), button.b-button.b-raised.b-green:hover:not([disabled]) {
    background-color: #34d844; }
  button.b-button.b-raised.b-green.b-pressed, button.b-button.b-raised.b-green.b-pressed[data-group]:hover {
    background-color: #21ac2f;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-green.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-green.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-green.b-pressed:focus {
    background-color: #25c034; }
  button.b-button.b-raised.b-green.b-pressed:active {
    background-color: #198324; }
  button.b-button.b-raised.b-green.b-pressed:active:hover {
    background-color: #1d9829; }
  button.b-button.b-raised.b-green:active:not([disabled]) {
    background-color: #1fa22c;
    border-color: #1fa22c;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-green:active:hover:not([disabled]) {
    background-color: #23b632;
    border-color: #23b632;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-green:disabled:hover, button.b-button.b-raised.b-green:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #167520;
    cursor: default; }

button.b-button.b-raised.b-light-green {
  color: #fff;
  border: none;
  background-color: #9efea7; }
  body.b-using-keyboard button.b-button.b-raised.b-light-green:focus {
    background-color: #cbfed0; }
  body.b-using-keyboard button.b-button.b-raised.b-light-green:focus:hover:not([disabled]), button.b-button.b-raised.b-light-green:hover:not([disabled]) {
    background-color: #a8feb0; }
  button.b-button.b-raised.b-light-green.b-pressed, button.b-button.b-raised.b-light-green.b-pressed[data-group]:hover {
    background-color: #61fd70;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-light-green.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-light-green.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-light-green.b-pressed:focus {
    background-color: #8afe95; }
  button.b-button.b-raised.b-light-green.b-pressed:active {
    background-color: #0ffd26; }
  button.b-button.b-raised.b-light-green.b-pressed:active:hover {
    background-color: #38fd4b; }
  button.b-button.b-raised.b-light-green:active:not([disabled]) {
    background-color: #4cfd5d;
    border-color: #4cfd5d;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-light-green:active:hover:not([disabled]) {
    background-color: #75fe82;
    border-color: #75fe82;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-light-green:disabled:hover, button.b-button.b-raised.b-light-green:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #39fd4b;
    cursor: default; }

button.b-button.b-raised.b-purple {
  color: #fff;
  border: none;
  background-color: #fe31ea; }
  body.b-using-keyboard button.b-button.b-raised.b-purple:focus {
    background-color: #fe5fef; }
  body.b-using-keyboard button.b-button.b-raised.b-purple:focus:hover:not([disabled]), button.b-button.b-raised.b-purple:hover:not([disabled]) {
    background-color: #fe46ec; }
  button.b-button.b-raised.b-purple.b-pressed, button.b-button.b-raised.b-purple.b-pressed[data-group]:hover {
    background-color: #fe04e5;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-purple.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-purple.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-purple.b-pressed:focus {
    background-color: #fe22e8; }
  button.b-button.b-raised.b-purple.b-pressed:active {
    background-color: #c401b1; }
  button.b-button.b-raised.b-purple.b-pressed:active:hover {
    background-color: #e201cc; }
  button.b-button.b-raised.b-purple:active:not([disabled]) {
    background-color: #f101da;
    border-color: #f101da;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-purple:active:hover:not([disabled]) {
    background-color: #fe13e7;
    border-color: #fe13e7;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-purple:disabled:hover, button.b-button.b-raised.b-purple:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #c801b5;
    cursor: default; }

button.b-button.b-raised.b-yellow {
  color: #fff;
  border: none;
  background-color: #ecfe26; }
  body.b-using-keyboard button.b-button.b-raised.b-yellow:focus {
    background-color: #f0fe54; }
  body.b-using-keyboard button.b-button.b-raised.b-yellow:focus:hover:not([disabled]), button.b-button.b-raised.b-yellow:hover:not([disabled]) {
    background-color: #eefe3c; }
  button.b-button.b-raised.b-yellow.b-pressed, button.b-button.b-raised.b-yellow.b-pressed[data-group]:hover {
    background-color: #e3f701;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-yellow.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-yellow.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-yellow.b-pressed:focus {
    background-color: #ebfe17; }
  button.b-button.b-raised.b-yellow.b-pressed:active {
    background-color: #adbd01; }
  button.b-button.b-raised.b-yellow.b-pressed:active:hover {
    background-color: #c8da01; }
  button.b-button.b-raised.b-yellow:active:not([disabled]) {
    background-color: #d5e901;
    border-color: #d5e901;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-yellow:active:hover:not([disabled]) {
    background-color: #e9fe09;
    border-color: #e9fe09;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-yellow:disabled:hover, button.b-button.b-raised.b-yellow:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #adbd01;
    cursor: default; }

button.b-button.b-raised.b-red {
  color: #fff;
  border: none;
  background-color: #e53f2c; }
  body.b-using-keyboard button.b-button.b-raised.b-red:focus {
    background-color: #ea6455; }
  body.b-using-keyboard button.b-button.b-raised.b-red:focus:hover:not([disabled]), button.b-button.b-raised.b-red:hover:not([disabled]) {
    background-color: #e85241; }
  button.b-button.b-raised.b-red.b-pressed, button.b-button.b-raised.b-red.b-pressed[data-group]:hover {
    background-color: #cf2c19;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-red.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-red.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-red.b-pressed:focus {
    background-color: #e43420; }
  button.b-button.b-raised.b-red.b-pressed:active {
    background-color: #9e2213; }
  button.b-button.b-raised.b-red.b-pressed:active:hover {
    background-color: #b62716; }
  button.b-button.b-raised.b-red:active:not([disabled]) {
    background-color: #c22918;
    border-color: #c22918;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-red:active:hover:not([disabled]) {
    background-color: #db2f1b;
    border-color: #db2f1b;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-red:disabled:hover, button.b-button.b-raised.b-red:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #982013;
    cursor: default; }

button.b-button.b-raised.b-dark-gray {
  color: #fff;
  border: none;
  background-color: #474849; }
  body.b-using-keyboard button.b-button.b-raised.b-dark-gray:focus {
    background-color: #5e5f60; }
  body.b-using-keyboard button.b-button.b-raised.b-dark-gray:focus:hover:not([disabled]), button.b-button.b-raised.b-dark-gray:hover:not([disabled]) {
    background-color: #595a5c; }
  button.b-button.b-raised.b-dark-gray.b-pressed, button.b-button.b-raised.b-dark-gray.b-pressed[data-group]:hover {
    background-color: #3c3d3e;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-dark-gray.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-dark-gray.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-dark-gray.b-pressed:focus {
    background-color: #434445; }
  button.b-button.b-raised.b-dark-gray.b-pressed:active {
    background-color: #2e2f2f; }
  button.b-button.b-raised.b-dark-gray.b-pressed:active:hover {
    background-color: #353637; }
  button.b-button.b-raised.b-dark-gray:active:not([disabled]) {
    background-color: #393a3a;
    border-color: #393a3a;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-dark-gray:active:hover:not([disabled]) {
    background-color: #404142;
    border-color: #404142;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-dark-gray:disabled:hover, button.b-button.b-raised.b-dark-gray:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #151515;
    cursor: default; }

button.b-button.b-raised.b-gray {
  color: #fff;
  border: none;
  background-color: #b0b0b7; }
  body.b-using-keyboard button.b-button.b-raised.b-gray:focus {
    background-color: #c8c8cd; }
  body.b-using-keyboard button.b-button.b-raised.b-gray:focus:hover:not([disabled]), button.b-button.b-raised.b-gray:hover:not([disabled]) {
    background-color: #b8b8be; }
  button.b-button.b-raised.b-gray.b-pressed, button.b-button.b-raised.b-gray.b-pressed[data-group]:hover {
    background-color: #94949d;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-gray.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-gray.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-gray.b-pressed:focus {
    background-color: #a7a7ae; }
  button.b-button.b-raised.b-gray.b-pressed:active {
    background-color: #6f6f7a; }
  button.b-button.b-raised.b-gray.b-pressed:active:hover {
    background-color: #81818c; }
  button.b-button.b-raised.b-gray:active:not([disabled]) {
    background-color: #8a8a95;
    border-color: #8a8a95;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-gray:active:hover:not([disabled]) {
    background-color: #9d9da6;
    border-color: #9d9da6;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-gray:disabled:hover, button.b-button.b-raised.b-gray:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #7b7b86;
    cursor: default; }

button.b-button.b-raised.b-light-gray {
  color: #fff;
  border: none;
  background-color: #e0e0e7; }
  body.b-using-keyboard button.b-button.b-raised.b-light-gray:focus {
    background-color: #fafafb; }
  body.b-using-keyboard button.b-button.b-raised.b-light-gray:focus:hover:not([disabled]), button.b-button.b-raised.b-light-gray:hover:not([disabled]) {
    background-color: #e3e3e9; }
  button.b-button.b-raised.b-light-gray.b-pressed, button.b-button.b-raised.b-light-gray.b-pressed[data-group]:hover {
    background-color: #babac9;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-light-gray.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-light-gray.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-light-gray.b-pressed:focus {
    background-color: #d3d3dd; }
  button.b-button.b-raised.b-light-gray.b-pressed:active {
    background-color: #8686a2; }
  button.b-button.b-raised.b-light-gray.b-pressed:active:hover {
    background-color: #a0a0b5; }
  button.b-button.b-raised.b-light-gray:active:not([disabled]) {
    background-color: #adadbf;
    border-color: #adadbf;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-light-gray:active:hover:not([disabled]) {
    background-color: #c6c6d3;
    border-color: #c6c6d3;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-light-gray:disabled:hover, button.b-button.b-raised.b-light-gray:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #a7a7ba;
    cursor: default; }

button.b-button.b-raised {
  color: #fff;
  border: none;
  background-color: #b0b0b7; }
  body.b-using-keyboard button.b-button.b-raised:focus {
    background-color: #c8c8cd; }
  body.b-using-keyboard button.b-button.b-raised:focus:hover:not([disabled]), button.b-button.b-raised:hover:not([disabled]) {
    background-color: #b8b8be; }
  button.b-button.b-raised.b-pressed, button.b-button.b-raised.b-pressed[data-group]:hover {
    background-color: #94949d;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised.b-pressed:hover,
  body.b-using-keyboard button.b-button.b-raised.b-pressed:focus:hover,
  body.b-using-keyboard button.b-button.b-raised.b-pressed:focus {
    background-color: #a7a7ae; }
  button.b-button.b-raised.b-pressed:active {
    background-color: #6f6f7a; }
  button.b-button.b-raised.b-pressed:active:hover {
    background-color: #81818c; }
  button.b-button.b-raised:active:not([disabled]) {
    background-color: #8a8a95;
    border-color: #8a8a95;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised:active:hover:not([disabled]) {
    background-color: #9d9da6;
    border-color: #9d9da6;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  button.b-button.b-raised:disabled:hover, button.b-button.b-raised:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #7b7b86;
    cursor: default; }

.b-buttongroup {
  overflow: visible; }
  .b-buttongroup.b-content-element {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
  .b-buttongroup .b-button {
    margin: 0;
    -webkit-box-shadow: none !important;
            box-shadow: none !important; }
    .b-buttongroup .b-button.b-pressed, .b-buttongroup .b-button:active {
      z-index: 2; }
    .b-buttongroup .b-button:not(:first-child) {
      margin-left: -1px; }
  .b-buttongroup .b-button:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .b-buttongroup .b-button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .b-buttongroup .b-button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.b-panel.b-calendarpanel.b-widget {
  display: -ms-inline-flexbox;
  display: inline-flex; }

.b-panel.b-calendarpanel .b-week-number-cell {
  display: none; }

.b-panel.b-calendarpanel.b-show-week-number .b-week-number-cell {
  display: inline;
  display: initial; }

.b-panel.b-calendarpanel .b-calendarpanel-content {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: stretch;
      align-items: stretch; }
  .b-panel.b-calendarpanel .b-calendarpanel-content .b-calendar-row {
    display: -ms-flexbox;
    display: flex; }
  .b-panel.b-calendarpanel .b-calendarpanel-content .b-weeks-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }

.b-container .b-checkbox .b-checkbox-label {
  margin-left: 0; }

.b-container .b-checkbox .b-checkbox-label:before {
  margin: 0.675em;
  margin-right: 0.4em;
  margin-left: 0; }

.b-checkbox {
  position: relative;
  -ms-flex-align: center;
      align-items: center; }
  .b-ie .b-checkbox, .b-checkbox.b-ie {
    display: block; }
  .b-checkbox .b-field-inner {
    border: none;
    background-color: transparent; }
  .b-checkbox .b-checkbox-label {
    position: relative;
    font-size: inherit;
    cursor: pointer; }
  .b-checkbox .b-checkbox-label:before {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    margin-right: 0.4em;
    font-size: inherit;
    width: 1.6em;
    height: 1.6em;
    padding: 0.15em;
    border-radius: 2px;
    -webkit-transition: color 0.2s, background 0.2s;
    transition: color 0.2s, background 0.2s;
    color: rgba(255, 255, 255, 0.2);
    background: #fff;
    border: 1px solid #e0e1e2; }
  .b-checkbox input {
    z-index: 1;
    opacity: 0;
    width: 2.2em;
    height: 2.2em;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    cursor: pointer;
    -webkit-transition: color 0.5s, background-color 0.5s;
    transition: color 0.5s, background-color 0.5s; }
  .b-checkbox input[type=checkbox]:checked + .b-checkbox-label:before {
    color: #474849;
    background: #fff;
    border-color: #e0e1e2; }
  .b-checkbox.b-disabled .b-checkbox-label {
    color: #b0b0b7; }
  .b-checkbox.b-disabled .b-checkbox-label::before {
    border-color: #e0e1e2;
    background: #e8e9ea; }
  .b-checkbox.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
    background: rgba(255, 255, 255, 0.2);
    border-color: rgba(255, 255, 255, 0.2); }

.b-checkbox.b-blue .b-checkbox-label:before {
  color: rgba(49, 131, 254, 0.1);
  border-color: rgba(49, 131, 254, 0.5); }

.b-checkbox.b-blue input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #3183fe;
  border-color: #3183fe; }

.b-checkbox.b-blue.b-disabled .b-checkbox-label::before {
  border-color: rgba(49, 131, 254, 0.2); }

.b-checkbox.b-blue.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(49, 131, 254, 0.2);
  border-color: rgba(49, 131, 254, 0.2); }

.b-checkbox.b-deep-orange .b-checkbox-label:before {
  color: rgba(255, 87, 34, 0.1);
  border-color: rgba(255, 87, 34, 0.5); }

.b-checkbox.b-deep-orange input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #ff5722;
  border-color: #ff5722; }

.b-checkbox.b-deep-orange.b-disabled .b-checkbox-label::before {
  border-color: rgba(255, 87, 34, 0.2); }

.b-checkbox.b-deep-orange.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(255, 87, 34, 0.2);
  border-color: rgba(255, 87, 34, 0.2); }

.b-checkbox.b-orange .b-checkbox-label:before {
  color: rgba(254, 172, 49, 0.1);
  border-color: rgba(254, 172, 49, 0.5); }

.b-checkbox.b-orange input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #feac31;
  border-color: #feac31; }

.b-checkbox.b-orange.b-disabled .b-checkbox-label::before {
  border-color: rgba(254, 172, 49, 0.2); }

.b-checkbox.b-orange.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(254, 172, 49, 0.2);
  border-color: rgba(254, 172, 49, 0.2); }

.b-checkbox.b-indigo .b-checkbox-label:before {
  color: rgba(69, 49, 254, 0.1);
  border-color: rgba(69, 49, 254, 0.5); }

.b-checkbox.b-indigo input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #4531fe;
  border-color: #4531fe; }

.b-checkbox.b-indigo.b-disabled .b-checkbox-label::before {
  border-color: rgba(69, 49, 254, 0.2); }

.b-checkbox.b-indigo.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(69, 49, 254, 0.2);
  border-color: rgba(69, 49, 254, 0.2); }

.b-checkbox.b-green .b-checkbox-label:before {
  color: rgba(39, 202, 55, 0.1);
  border-color: rgba(39, 202, 55, 0.5); }

.b-checkbox.b-green input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #27ca37;
  border-color: #27ca37; }

.b-checkbox.b-green.b-disabled .b-checkbox-label::before {
  border-color: rgba(39, 202, 55, 0.2); }

.b-checkbox.b-green.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(39, 202, 55, 0.2);
  border-color: rgba(39, 202, 55, 0.2); }

.b-checkbox.b-purple .b-checkbox-label:before {
  color: rgba(254, 49, 234, 0.1);
  border-color: rgba(254, 49, 234, 0.5); }

.b-checkbox.b-purple input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #fe31ea;
  border-color: #fe31ea; }

.b-checkbox.b-purple.b-disabled .b-checkbox-label::before {
  border-color: rgba(254, 49, 234, 0.2); }

.b-checkbox.b-purple.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(254, 49, 234, 0.2);
  border-color: rgba(254, 49, 234, 0.2); }

.b-checkbox.b-yellow .b-checkbox-label:before {
  color: rgba(236, 254, 38, 0.1);
  border-color: rgba(236, 254, 38, 0.5); }

.b-checkbox.b-yellow input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #ecfe26;
  border-color: #ecfe26; }

.b-checkbox.b-yellow.b-disabled .b-checkbox-label::before {
  border-color: rgba(236, 254, 38, 0.2); }

.b-checkbox.b-yellow.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(236, 254, 38, 0.2);
  border-color: rgba(236, 254, 38, 0.2); }

.b-checkbox.b-red .b-checkbox-label:before {
  color: rgba(229, 63, 44, 0.1);
  border-color: rgba(229, 63, 44, 0.5); }

.b-checkbox.b-red input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #e53f2c;
  border-color: #e53f2c; }

.b-checkbox.b-red.b-disabled .b-checkbox-label::before {
  border-color: rgba(229, 63, 44, 0.2); }

.b-checkbox.b-red.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(229, 63, 44, 0.2);
  border-color: rgba(229, 63, 44, 0.2); }

.b-checkbox.b-amber .b-checkbox-label:before {
  color: rgba(255, 193, 7, 0.1);
  border-color: rgba(255, 193, 7, 0.5); }

.b-checkbox.b-amber input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #ffc107;
  border-color: #ffc107; }

.b-checkbox.b-amber.b-disabled .b-checkbox-label::before {
  border-color: rgba(255, 193, 7, 0.2); }

.b-checkbox.b-amber.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(255, 193, 7, 0.2);
  border-color: rgba(255, 193, 7, 0.2); }

.b-checkbox.b-lime .b-checkbox-label:before {
  color: rgba(131, 254, 49, 0.1);
  border-color: rgba(131, 254, 49, 0.5); }

.b-checkbox.b-lime input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #83fe31;
  border-color: #83fe31; }

.b-checkbox.b-lime.b-disabled .b-checkbox-label::before {
  border-color: rgba(131, 254, 49, 0.2); }

.b-checkbox.b-lime.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(131, 254, 49, 0.2);
  border-color: rgba(131, 254, 49, 0.2); }

.b-checkbox.b-teal .b-checkbox-label:before {
  color: rgba(49, 254, 172, 0.1);
  border-color: rgba(49, 254, 172, 0.5); }

.b-checkbox.b-teal input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #31feac;
  border-color: #31feac; }

.b-checkbox.b-teal.b-disabled .b-checkbox-label::before {
  border-color: rgba(49, 254, 172, 0.2); }

.b-checkbox.b-teal.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(49, 254, 172, 0.2);
  border-color: rgba(49, 254, 172, 0.2); }

.b-checkbox.b-light-green .b-checkbox-label:before {
  color: rgba(158, 254, 167, 0.1);
  border-color: rgba(158, 254, 167, 0.5); }

.b-checkbox.b-light-green input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #9efea7;
  border-color: #9efea7; }

.b-checkbox.b-light-green.b-disabled .b-checkbox-label::before {
  border-color: rgba(158, 254, 167, 0.2); }

.b-checkbox.b-light-green.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(158, 254, 167, 0.2);
  border-color: rgba(158, 254, 167, 0.2); }

.b-checkbox.b-purple .b-checkbox-label:before {
  color: rgba(254, 49, 234, 0.1);
  border-color: rgba(254, 49, 234, 0.5); }

.b-checkbox.b-purple input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #fe31ea;
  border-color: #fe31ea; }

.b-checkbox.b-purple.b-disabled .b-checkbox-label::before {
  border-color: rgba(254, 49, 234, 0.2); }

.b-checkbox.b-purple.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(254, 49, 234, 0.2);
  border-color: rgba(254, 49, 234, 0.2); }

.b-combo .b-fieldtrigger.b-icon-picker:before {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }

.b-combo.b-open .b-fieldtrigger.b-icon-picker:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.b-combo .b-field-inner .b-fieldtrigger.b-icon-remove {
  font-size: .8em; }

.b-combo.b-multiselect .b-chipview {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-align: center;
      align-items: center;
  margin: .3em 0.3em .1em 0; }

.b-combo.b-multiselect:not(.b-empty) input {
  padding-left: 0.2em;
  -ms-flex-order: 99999;
      order: 99999; }

.b-combo.b-multiselect.b-not-editable input {
  -ms-flex-order: -1;
      order: -1;
  min-width: 0;
  padding-left: 0;
  padding-right: 0;
  -ms-flex: 0 1;
      flex: 0 1; }

.b-container:focus {
  outline: none; }

.b-content-element {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap; }

.b-datepicker:focus {
  outline: 0; }

.b-datepicker .b-datepicker-header.b-dock-top {
  background-color: #fff5e6;
  color: #4f5964;
  border-bottom: none; }
  .b-datepicker .b-datepicker-header.b-dock-top .b-icon {
    color: #4f5964; }
  .b-datepicker .b-datepicker-header.b-dock-top .b-tool {
    color: #4f5964; }

.b-datepicker .b-calendar-weekdays {
  background-color: #fff5e6;
  border-bottom: 1px solid #feac31; }
  .b-datepicker .b-calendar-weekdays .b-calendar-day-header {
    -ms-flex: 1 0;
        flex: 1 0;
    padding: 6px 0px;
    text-align: center; }

.b-datepicker.b-show-week-number .b-week-number-cell {
  line-height: 2.2em;
  width: 2.2em;
  margin: 4px 5px;
  text-align: center; }

.b-datepicker .b-calendarpanel-content {
  padding: 0; }
  .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week {
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly; }
    .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-week-number-cell {
      color: #76c579; }
    .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell {
      text-align: center;
      height: 2.2em;
      width: 2.2em;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: center;
          justify-content: center;
      margin: 4px 5px;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
      border-radius: 50%;
      -webkit-transition: background-color .2s, color .2s;
      transition: background-color .2s, color .2s; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-today {
        border-color: #feac31;
        border-width: 1px; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell:hover {
        background-color: rgba(254, 172, 49, 0.5);
        border-color: rgba(254, 172, 49, 0.5); }
        .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell:hover.b-today {
          background-color: #feac31;
          border-color: #feac31; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-selected-date {
        color: #fff;
        background-color: #feac31;
        border-color: #feac31; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-active-date {
        border-color: #feac31; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-other-month {
        color: #aaa; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell {
        cursor: pointer; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-out-of-range {
        color: #aaa; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-disabled {
        color: #aaa; }

.b-editor {
  background-color: #fff;
  border-radius: 2px; }
  .b-editor.b-positioned {
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.1); }
  .b-editor.b-content-element > .b-widget.b-field {
    margin: 0; }

@-webkit-keyframes b-field-updated {
  0% {
    color: green; }
  50% {
    color: green; }
  100% {
    color: #4f5964; } }

@keyframes b-field-updated {
  0% {
    color: green; }
  50% {
    color: green; }
  100% {
    color: #4f5964; } }

.b-has-label label {
  margin-right: 1em;
  color: #fff;
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis; }

.b-has-label.b-open label, .b-has-label:focus-within label, .b-has-label.b-ie:focus label {
  color: #fff; }

.b-container .b-has-label label {
  color: #616161;
  margin-left: 0.3em; }

.b-container .b-has-label.b-open label, .b-container .b-has-label:focus-within label, .b-container .b-has-label.b-ie:focus label {
  color: #212121; }

.b-has-label.b-invalid label {
  color: #e53f2c !important; }

.b-has-label.b-disabled label {
  color: #b0b0b7; }

.b-field-updated {
  -webkit-animation-name: b-field-updated;
          animation-name: b-field-updated;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s; }

.b-form-updating .b-field-updated {
  -webkit-animation-name: none;
          animation-name: none; }

.b-content-element:not(.b-toolbar) > .b-field {
  -ms-flex: 1 0 100%;
      flex: 1 0 100%;
  margin-bottom: .6em; }
  .b-content-element:not(.b-toolbar) > .b-field.b-inline .b-field-inner {
    margin-right: 0.6em; }
  .b-content-element:not(.b-toolbar) > .b-field.b-last-row {
    margin-bottom: 0; }

.b-field-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 100%;
      flex: 1 1 100%;
  -ms-flex-align: center;
      align-items: center;
  background-color: #fff;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e1e2;
  min-width: 0;
  position: relative; }

.b-numberfield,
.b-textfield {
  -ms-flex-align: center;
      align-items: center;
  min-width: 3em;
  color: #4f5964;
  border-color: none;
  border-radius: 2px;
  position: relative;
  width: 12em; }
  .b-numberfield.b-contains-focus .b-field-inner,
  .b-textfield.b-contains-focus .b-field-inner {
    border-color: rgba(254, 172, 49, 0.6); }
  .b-numberfield .b-fieldtrigger,
  .b-textfield .b-fieldtrigger {
    color: #b0b1b2;
    font-size: 0.9em;
    cursor: pointer;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto; }
    .b-numberfield .b-fieldtrigger.b-align-start,
    .b-textfield .b-fieldtrigger.b-align-start {
      padding-left: 0.8em; }
    .b-numberfield .b-fieldtrigger.b-align-end,
    .b-textfield .b-fieldtrigger.b-align-end {
      padding-right: .5em; }
      .b-numberfield .b-fieldtrigger.b-align-end:last-child,
      .b-textfield .b-fieldtrigger.b-align-end:last-child {
        padding-right: 0.8em; }
    .b-numberfield .b-fieldtrigger:before,
    .b-textfield .b-fieldtrigger:before {
      font-size: 1.3em;
      -webkit-transition: all .3s;
      transition: all .3s;
      -webkit-transform: scale(1);
              transform: scale(1); }
  .b-numberfield .b-spintrigger,
  .b-textfield .b-spintrigger {
    -ms-flex-direction: column;
        flex-direction: column;
    font-size: 1em; }
    .b-numberfield .b-spintrigger .b-spin-up:before,
    .b-textfield .b-spintrigger .b-spin-up:before {
      content: '\F0D8';
      vertical-align: bottom; }
    .b-numberfield .b-spintrigger .b-spin-down:before,
    .b-textfield .b-spintrigger .b-spin-down:before {
      content: '\F0D7';
      vertical-align: top; }
  .b-numberfield.b-hide-spinner .b-spintrigger,
  .b-textfield.b-hide-spinner .b-spintrigger {
    display: none; }
  .b-numberfield input, .b-numberfield textarea,
  .b-textfield input,
  .b-textfield textarea {
    background-color: transparent;
    color: inherit;
    padding: 0.8em;
    font-weight: 400;
    -ms-flex: 1 1;
        flex: 1 1;
    border: 0 none;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    min-width: 1em; }
    .b-numberfield input:focus, .b-numberfield textarea:focus,
    .b-textfield input:focus,
    .b-textfield textarea:focus {
      outline: none; }
    .b-numberfield input::-ms-clear, .b-numberfield textarea::-ms-clear,
    .b-textfield input::-ms-clear,
    .b-textfield textarea::-ms-clear {
      display: none; }
  .b-numberfield ::-webkit-input-placeholder,
  .b-textfield ::-webkit-input-placeholder {
    color: #b0b0b7; }
  .b-numberfield.b-empty .b-fieldtrigger.b-icon-remove,
  .b-textfield.b-empty .b-fieldtrigger.b-icon-remove {
    display: none; }
  .b-numberfield:focus-within label i,
  .b-textfield:focus-within label i {
    color: rgba(254, 172, 49, 0.6); }
  .b-numberfield:not(.b-disabled):hover label i,
  .b-textfield:not(.b-disabled):hover label i {
    color: rgba(254, 172, 49, 0.6); }
  .b-numberfield:not(.b-disabled):hover .b-field-inner,
  .b-textfield:not(.b-disabled):hover .b-field-inner {
    border-color: rgba(254, 172, 49, 0.6); }
  .b-numberfield.b-invalid .b-field-inner,
  .b-textfield.b-invalid .b-field-inner {
    border-color: #e53f2c !important; }
  .b-numberfield.b-disabled,
  .b-textfield.b-disabled {
    color: #b0b0b7;
    cursor: default; }
    .b-numberfield.b-disabled input,
    .b-textfield.b-disabled input {
      cursor: default; }
    .b-numberfield.b-disabled .b-fieldtrigger,
    .b-textfield.b-disabled .b-fieldtrigger {
      color: #e4e4e4; }
    .b-numberfield.b-disabled .b-fieldtrigger,
    .b-textfield.b-disabled .b-fieldtrigger {
      cursor: default; }
    .b-numberfield.b-disabled .b-field-inner,
    .b-textfield.b-disabled .b-field-inner {
      border-style: solid; }
  .b-numberfield.b-readonly,
  .b-textfield.b-readonly {
    cursor: default; }
    .b-numberfield.b-readonly input,
    .b-textfield.b-readonly input {
      cursor: default; }
    .b-numberfield.b-readonly .b-fieldtrigger,
    .b-textfield.b-readonly .b-fieldtrigger {
      color: #e4e4e4;
      cursor: default; }

.b-numberfield input[type=number]::-webkit-inner-spin-button,
.b-numberfield input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.b-numberfield input {
  -moz-appearance: textfield; }

.b-numberfield .b-field-inner .b-fieldtrigger.b-icon-remove {
  font-size: .8em; }

.b-tooltip.b-field-error-tip {
  border: 1px solid #e53f2c; }
  .b-tooltip.b-field-error-tip .b-popup-content {
    background-color: #fffef6;
    color: #e53f2c;
    font-weight: bold; }

.b-filepicker {
  overflow: visible; }

.b-displayfield {
  color: inherit; }
  .b-displayfield .b-field-inner {
    border: 0;
    background: transparent; }

.b-pickerfield.b-open .b-field-inner {
  border-color: #feac31; }

.b-pickerfield:not(.b-readonly):not(.b-disabled) input[readonly] {
  cursor: pointer; }

.b-datefield.b-open .b-fieldtrigger {
  color: #feac31 !important; }

.b-datefield .b-step-trigger {
  display: none; }

.b-datefield.b-show-steppers .b-step-trigger {
  display: inline-block; }

.b-list {
  display: block;
  background-color: white;
  outline: none; }
  .b-list.b-floating {
    border-radius: 2px; }
  .b-list.b-empty {
    min-height: 0 !important;
    min-height: initial !important; }
    .b-list.b-empty::after {
      content: attr(data-empty-text);
      display: block;
      padding: .8em;
      color: #4f5964; }
  .b-list .b-selected-icon {
    -webkit-margin-end: 0.5em;
            margin-inline-end: 0.5em;
    display: none; }
    .b-ie .b-list .b-selected-icon {
      margin-right: 0.5em; }
  .b-list.b-multiselect .b-list-item .b-selected-icon {
    display: inline;
    display: initial;
    visibility: hidden; }
  .b-list.b-multiselect .b-list-item.b-selected .b-selected-icon {
    visibility: visible; }

.b-list-item {
  position: relative;
  padding: .8em;
  color: #4f5964;
  cursor: pointer;
  background-color: transparent;
  -webkit-transition: background-color 0.2s, color 0.2s;
  transition: background-color 0.2s, color 0.2s;
  border-top: 1px dotted rgba(224, 224, 231, 0.8);
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center; }
  .b-list-item.b-hidden {
    display: none; }
  .b-list-item:first-child {
    border-top: none;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px; }
  .b-list-item:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }
  .b-list-item.b-active, .b-list-item:focus {
    outline: none; }
    .b-list-item.b-active:not(.b-disabled), .b-list-item:focus:not(.b-disabled) {
      background-color: rgba(254, 172, 49, 0.3);
      color: #4f5964; }

.b-chipview {
  background-color: transparent; }
  .b-chipview.b-empty::after {
    display: none; }
  .b-chipview input {
    min-width: 50%;
    padding-top: 0.6em;
    padding-bottom: calc(0.6em + 1px); }
  .b-chipview .b-chip {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin: 0 0 0.3em 0.3em;
    padding: 0.3em 0.3em;
    border-radius: 0.8em;
    background-color: #e0e0e7;
    color: #5a6672;
    -webkit-transition: background-color .3s, color .3s;
    transition: background-color .3s, color .3s; }
    .b-chipview .b-chip .b-icon {
      height: 1.5em;
      width: 1.5em;
      border-radius: 50%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      background-color: #eeeef2;
      color: #474849; }
      .b-chipview .b-chip .b-icon:first-child:not(.b-close-icon) {
        margin-right: 0.5em; }
    .b-chipview .b-chip:hover {
      background-color: #d2d2dc;
      color: #4f5964; }
      .b-chipview .b-chip:hover .b-icon {
        background-color: #eeeef2;
        color: #474849; }
    .b-chipview .b-chip.b-selected {
      background-color: #feac31;
      color: #fff; }
      .b-chipview .b-chip.b-selected .b-icon {
        background-color: #eeeef2;
        color: #feac31; }
      .b-chipview .b-chip.b-selected:hover {
        background-color: #fea218;
        color: #fff; }
        .b-chipview .b-chip.b-selected:hover .b-icon {
          background-color: #eeeef2;
          color: #fea218; }
    .b-chipview .b-chip .b-close-icon {
      margin-left: 0.5em;
      cursor: pointer; }

@-webkit-keyframes maskOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes maskOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.b-masked {
  position: relative; }

.b-mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background-color: transparent;
  -webkit-transition: opacity 1s;
  transition: opacity 1s; }
  .b-mask.b-visible {
    -webkit-animation: maskOpacity .3s ease 0s 1;
            animation: maskOpacity .3s ease 0s 1;
    pointer-events: all;
    opacity: 1; }
  .b-mask.b-hidden {
    animation: maskOpacity .2s ease 0s 1 reverse;
    pointer-events: none;
    opacity: 0; }
  .b-mask.b-mask-bright {
    background-color: rgba(255, 255, 255, 0.7); }
  .b-mask.b-mask-bright-blur {
    background-color: rgba(255, 255, 255, 0.7); }
  .b-mask.b-mask-dark {
    background-color: rgba(0, 0, 0, 0.3); }
  .b-mask.b-mask-dark-blur {
    background-color: rgba(0, 0, 0, 0.3); }

.b-mask-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 1em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: #fff;
  background-color: #feac31;
  border-radius: 2px;
  white-space: nowrap; }

.b-mask-icon {
  margin-right: .5em; }

.b-masked-dark-blur,
.b-masked-bright-blur {
  -webkit-filter: blur(3px);
          filter: blur(3px);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  -webkit-transition: all .5s;
  transition: all .5s; }

.b-panel {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding: 0;
  color: #4f5964; }
  .b-panel.b-header-dock-top {
    -ms-flex-flow: column nowrap;
        flex-flow: column nowrap; }
    .b-panel.b-header-dock-top .b-panel-content {
      border-top-right-radius: 0;
      border-top-left-radius: 0; }
  .b-panel.b-header-dock-right {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap; }
    .b-panel.b-header-dock-right .b-panel-content {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
  .b-panel.b-header-dock-bottom {
    -ms-flex-flow: column nowrap;
        flex-flow: column nowrap; }
    .b-panel.b-header-dock-bottom .b-panel-content {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
  .b-panel.b-header-dock-left {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap; }
    .b-panel.b-header-dock-left .b-panel-content {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .b-panel.b-panel-has-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: stretch;
        align-items: stretch; }
  .b-panel.b-panel-has-bottom-toolbar .b-panel-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .b-panel .b-dock-bottom {
    position: static;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px; }

.b-panel-content {
  background: #f8f9fa;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 0.6em; }
  .b-panel-content.b-fit-container {
    padding: 0; }

.b-panel:not(.b-outer) {
  border-radius: 2px; }
  .b-panel:not(.b-outer) .b-panel-content {
    border-radius: 2px; }

.b-tabpanel .b-panel-content {
  background-color: transparent; }

.b-panel-header {
  display: -ms-flexbox;
  display: flex;
  background-color: #fff5e6;
  color: #4f5964;
  padding: 1em;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  -ms-flex-align: center;
      align-items: center; }
  .b-panel-header.b-dock-top {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    border-bottom: 1px solid #feac31; }
  .b-panel-header.b-dock-right {
    -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }
  .b-panel-header.b-dock-bottom {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }
  .b-panel-header.b-dock-left {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -ms-flex-flow: column-reverse nowrap;
        flex-flow: column-reverse nowrap; }
    .b-panel-header.b-dock-left .b-header-title {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .b-panel-header .b-header-title {
    padding: 0 3px;
    -ms-flex: 1 1;
        flex: 1 1;
    text-align: center;
    font-size: 1.2em;
    font-weight: 500; }
  .b-panel-header.b-dock-right .b-header-title, .b-panel-header.b-dock-left .b-header-title {
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr; }
  .b-panel-header.b-dock-right, .b-panel-header.b-dock-bottom {
    -ms-flex-order: 100;
        order: 100; }

.b-tool {
  cursor: pointer;
  color: #4f5964;
  contain: paint; }
  .b-tool:not(:last-child) {
    margin: 0 0.2em; }
  .b-tool.b-icon:before, .b-row-reordering .b-row-reordering-target-parent .b-tool.b-tree-cell::before {
    width: 1em;
    text-align: center; }
  .b-tool:hover {
    opacity: 0.8; }
  .b-tool a {
    color: #4f5964; }

.b-dock-right .b-header-title:not(:last-child), .b-dock-left .b-header-title:not(:last-child) {
  padding: 0.2em 0; }

.b-dock-right .b-tool:not(:last-child), .b-dock-left .b-tool:not(:last-child) {
  margin: 0.2em 0; }

.b-popup {
  color: #4f5964; }

.b-popup-content {
  background: #f8f9fa; }
  .b-popup-content a {
    color: #22262b; }

.b-popup-header {
  background-color: #fff5e6;
  color: #4f5964; }

.b-popup-close:before {
  content: '\F410'; }

.b-modal-mask {
  background-color: rgba(100, 100, 100, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: all; }

.b-menu {
  min-height: 20px;
  outline: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: stretch;
      align-items: stretch;
  /* HACK for IE11. Withouth this padding menu may scroll on first/last item hover */
  /* Covered by Menu.t.js */ }
  .b-menu .b-panel-content {
    background-color: #fff; }
  .b-menu.b-empty::after {
    content: attr(data-empty-text);
    display: block;
    padding: .8em;
    color: #4f5964; }
  .b-menu.b-ie .b-menu-content {
    padding-bottom: 1px; }
  .b-menu .b-icon:before, .b-menu .b-row-reordering .b-row-reordering-target-parent .b-tree-cell::before, .b-row-reordering .b-row-reordering-target-parent .b-menu .b-tree-cell::before, .b-menu .b-icon-fw:before {
    min-width: 1.3em; }
  .b-menu.b-menu-with-submenu .b-menuitem:not(.b-has-submenu) .b-menu-text {
    margin-right: 2em; }

.b-sub-menu .b-anchor:before {
  position: absolute;
  height: 2.7em;
  width: 11px;
  left: -2px;
  content: ' ';
  margin-top: -0.8em; }

.b-menu-content {
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  -ms-flex-pack: start;
      justify-content: start;
  -ms-flex-align: stretch;
      align-items: stretch;
  padding: 0; }
  .b-menu-content > :not(.b-menuitem) {
    margin: 0.6em;
    display: -ms-flexbox;
    display: flex; }

.b-menuitem {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: .8em;
  color: #4f5964;
  cursor: pointer;
  -webkit-transition: background-color 0.2s, color 0.2s;
  transition: background-color 0.2s, color 0.2s;
  border-top: 1px dotted rgba(224, 224, 231, 0.8);
  font-size: 1em;
  min-width: 7em;
  display: -ms-flexbox;
  display: flex; }
  .b-menuitem.b-disabled {
    opacity: .5; }
  .b-menuitem:first-child {
    border-top: none;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px; }
  .b-menuitem:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }
  .b-menuitem.b-separator:not(:first-child) {
    border-top: 1px solid #c3c3d1; }
  .b-menuitem.b-contains-focus, .b-menuitem:focus {
    outline: none;
    background-color: rgba(254, 172, 49, 0.3);
    color: #4f5964; }
  .b-menuitem span.b-menu-text {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    white-space: nowrap;
    margin-left: 0.6em; }
  .b-menuitem.b-has-submenu .b-icon-sub-menu {
    margin-left: .3em; }

a.b-menuitem:hover .b-menu-text {
  text-decoration: underline; }

.b-menu-with-icon .b-menuitem-icon {
  width: 2em; }

.b-menu-with-icon span.b-menu-text {
  margin-left: 2em; }

.b-menu-with-icon .b-menuitem-icon ~ span.b-menu-text {
  margin-left: 0; }

.b-ripple {
  display: none; }

.b-slider {
  background-color: transparent;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }
  .b-slider label {
    font-size: 0.8em;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .b-slider [type='range'] {
    -ms-flex-item-align: stretch;
        align-self: stretch;
    max-width: 100%;
    -webkit-appearance: none;
    margin: 10px 0;
    padding: 0;
    align-self: stretch;
    font-size: inherit;
    background-color: transparent; }
    .b-slider [type='range']:focus {
      outline: 0; }
      .b-slider [type='range']:focus::-webkit-slider-runnable-track {
        background: #c9dfff; }
      .b-slider [type='range']:focus::-moz-range-track {
        background: #c9dfff; }
      .b-slider [type='range']:focus::-ms-fill-lower, .b-slider [type='range']:focus::-ms-fill-upper {
        background: #c9dfff; }
      .b-slider [type='range']:focus::-webkit-slider-thumb {
        -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.2);
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.2); }
    .b-slider [type='range']::-moz-focus-outer {
      border: 0; }
    .b-slider [type='range']::-ms-tooltip {
      display: none; }
    .b-slider [type='range']::-webkit-slider-runnable-track {
      cursor: pointer;
      height: 8px;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
      width: 100%;
      background: #e0e0e7;
      border: none;
      border-radius: 2px; }
    .b-slider [type='range']::-webkit-slider-thumb {
      background: #3183fe;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      height: 20px;
      width: 20px;
      -webkit-appearance: none;
      margin-top: -6px;
      -webkit-transition: all 0.5s;
      transition: all 0.5s; }
    .b-slider [type='range']::-moz-range-track {
      cursor: pointer;
      height: 8px;
      -moz-transition: all .2s ease;
      transition: all .2s ease;
      width: 100%;
      background: #e0e0e7;
      border: none;
      border-radius: 2px; }
    .b-slider [type='range']::-moz-range-thumb {
      background: #3183fe;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      height: 20px;
      width: 20px; }
    .b-slider [type='range']::-ms-track {
      cursor: pointer;
      height: 8px;
      -ms-transition: all .2s ease;
      transition: all .2s ease;
      width: 100%;
      background: transparent;
      border-color: transparent;
      border-width: 10px 0;
      color: transparent; }
    .b-slider [type='range']::-ms-fill-lower, .b-slider [type='range']::-ms-fill-upper {
      background: #e0e0e7;
      border: none;
      border-radius: 4px; }
    .b-slider [type='range']::-ms-thumb {
      background: #3183fe;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      height: 20px;
      width: 20px;
      margin-top: 0; }
  .b-slider.b-disabled [type='range']::-ms-thumb, .b-slider.b-disabled [type='range']::-ms-fill-lower, .b-slider.b-disabled [type='range']::-ms-fill-upper {
    background: #d2d2dc;
    cursor: default; }
  .b-slider.b-disabled [type='range']::-moz-range-thumb, .b-slider.b-disabled [type='range']::-moz-range-track {
    background: #d2d2dc;
    cursor: default; }
  .b-slider.b-disabled [type='range']::-webkit-slider-thumb, .b-slider.b-disabled [type='range']::-webkit-slider-runnable-track {
    background: #d2d2dc;
    cursor: default; }

.b-tabpanel {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  -ms-flex-align: stretch;
      align-items: stretch; }
  .b-tabpanel-tabs {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    -ms-flex-direction: row;
        flex-direction: row;
    margin-bottom: 0;
    border-bottom: 1px solid #feac31; }
    .b-tabpanel-tabs.b-edge {
      margin-bottom: -1px; }
  .b-tabpanel-tab {
    display: -ms-flexbox;
    display: flex;
    padding: 0.9em;
    height: auto;
    min-width: 3em;
    cursor: pointer;
    -webkit-transition: background-color 0.2s, color 0.2s;
    transition: background-color 0.2s, color 0.2s;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    font-size: 0.9em;
    font-weight: 500;
    text-transform: uppercase;
    color: #7b7b7b;
    background-color: transparent;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
    .b-tabpanel-tab.b-active {
      background-color: #fff5e6;
      border-color: #e0e1e2;
      color: #616161;
      font-weight: 800;
      z-index: 1; }
    .b-tabpanel-tab:focus {
      outline: none; }
    .b-tabpanel-tab:hover:not(.b-active), .b-tabpanel-tab:focus:not(.b-active) {
      background-color: #fff5e6;
      color: #616161; }
    .b-tabpanel-tab-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
  .b-tabpanel-body.b-card-container {
    background-color: transparent;
    border-radius: 2px;
    border: 0 solid #e0e1e2;
    -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    padding: 1em 0; }
  .b-tabpanel[data-active-index="0"] .b-tabpanel-body {
    border-top-left-radius: 0; }
  .b-tabpanel .b-html {
    color: #4f5964; }

.b-timefield.b-open .b-icon-clock-live {
  background-color: #feac31 !important; }

.b-timefield.b-empty .b-fieldtrigger {
  -webkit-animation-delay: -300s;
          animation-delay: -300s; }

.b-timefield.b-disabled .b-icon-clock-live, .b-timefield.b-readonly .b-icon-clock-live {
  background-color: #e4e4e4; }

.b-timefield:not(.b-disabled):not(.b-readonly) .b-icon-angle-left:hover,
.b-timefield:not(.b-disabled):not(.b-readonly) .b-icon-angle-right:hover {
  color: #feac31; }

.b-timepicker {
  width: 20em; }
  .b-timepicker.b-24h {
    width: 12.8em; }
  .b-timepicker .b-panel-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-align: center;
        align-items: center;
    padding: 0.3em; }
    .b-timepicker .b-panel-content .b-widget {
      margin: 0.3em; }
      .b-timepicker .b-panel-content .b-widget.b-html {
        margin: 0; }
      .b-timepicker .b-panel-content .b-widget label {
        font-weight: bold; }
    .b-timepicker .b-panel-content .b-numberfield {
      -ms-flex: 0 1 5.3em;
          flex: 0 1 5.3em; }
    .b-timepicker .b-panel-content .b-button {
      -ms-flex: 0 0 3em;
          flex: 0 0 3em; }

.b-ie.b-timepicker {
  width: 25em; }
  .b-ie.b-timepicker.b-24h {
    width: 13.8em; }
  .b-ie.b-timepicker .b-panel-content .b-button {
    line-height: 3em; }

@-webkit-keyframes progress {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

@keyframes progress {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

.b-float-root > .b-floating.b-toast {
  display: inline-block;
  overflow: hidden;
  top: auto;
  right: 2em;
  left: auto;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  bottom: 0;
  -webkit-transition: bottom .25s ease-in, -webkit-transform .25s ease-in;
  transition: bottom .25s ease-in, -webkit-transform .25s ease-in;
  transition: bottom .25s ease-in, transform .25s ease-in;
  transition: bottom .25s ease-in, transform .25s ease-in, -webkit-transform .25s ease-in;
  padding: 1em;
  background-color: #f8f9fa;
  color: #4f5964;
  border-radius: 2px;
  cursor: pointer; }
  .b-float-root > .b-floating.b-toast.b-toast-hide {
    bottom: 0 !important;
    -webkit-transform: translateY(100%) !important;
            transform: translateY(100%) !important; }
  .b-float-root > .b-floating.b-toast.b-icon:before, .b-row-reordering .b-row-reordering-target-parent .b-float-root > .b-floating.b-toast.b-tree-cell::before {
    margin-right: .5em; }

.b-toast-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  background: #feac31;
  -webkit-animation-name: progress;
          animation-name: progress;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.b-float-root > .b-floating.b-toast.b-white {
  background-color: #fff; }

.b-float-root > .b-floating.b-toast.b-blue {
  background-color: #3183fe; }

.b-float-root > .b-floating.b-toast.b-deep-orange {
  background-color: #ff5722; }

.b-float-root > .b-floating.b-toast.b-orange {
  background-color: #feac31; }

.b-float-root > .b-floating.b-toast.b-amber {
  background-color: #ffc107; }

.b-float-root > .b-floating.b-toast.b-indigo {
  background-color: #4531fe; }

.b-float-root > .b-floating.b-toast.b-lime {
  background-color: #83fe31; }

.b-float-root > .b-floating.b-toast.b-teal {
  background-color: #31feac; }

.b-float-root > .b-floating.b-toast.b-green {
  background-color: #27ca37; }

.b-float-root > .b-floating.b-toast.b-light-green {
  background-color: #9efea7; }

.b-float-root > .b-floating.b-toast.b-purple {
  background-color: #fe31ea; }

.b-float-root > .b-floating.b-toast.b-yellow {
  background-color: #ecfe26; }

.b-float-root > .b-floating.b-toast.b-red {
  background-color: #e53f2c; }

.b-float-root > .b-floating.b-toast.b-dark-gray {
  background-color: #474849; }

.b-float-root > .b-floating.b-toast.b-gray {
  background-color: #b0b0b7; }

.b-float-root > .b-floating.b-toast.b-light-gray {
  background-color: #e0e0e7; }

.b-float-root > .b-floating.b-toast {
  background-color: #b0b0b7; }

.b-tooltip {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .b-tooltip .b-tooltip-loading .b-icon {
    display: inline-block;
    margin-right: .5em; }

.b-tooltip-content {
  background: white;
  color: #4f5964;
  font-size: 1em;
  line-height: 1.4em;
  padding: 1em; }
  .b-tooltip-content a {
    color: #4f5964; }

.b-tooltip-text-content {
  max-width: 14em; }
  .b-tooltip-text-content .b-tooltip-content {
    display: inline;
    display: initial;
    overflow: auto; }

.b-tooltip-header.b-dock-right .b-header-title,
.b-tooltip-header.b-dock-left .b-header-title {
  font-size: 1em; }

.b-textareafield textarea {
  padding: 0;
  padding: initial;
  -ms-flex-item-align: stretch;
      align-self: stretch; }

.b-textareafield-picker {
  display: -ms-flexbox;
  display: flex; }
  .b-textareafield-picker textarea {
    -ms-flex: 1 1;
        flex: 1 1;
    min-height: 10em; }
    .b-textareafield-picker textarea:focus {
      outline: none;
      border-color: rgba(254, 172, 49, 0.6); }

.b-panel .b-toolbar {
  background-color: #f8f9fa;
  color: #4f5964;
  padding: 0.6em 0.6em 0 0.6em; }
  .b-panel .b-toolbar > .b-widget {
    margin-bottom: 0.6em; }

.b-panel.b-content-element {
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap; }

.b-tabpanel .b-panel .b-toolbar {
  background-color: transparent; }

.b-card-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -ms-flex-align: stretch;
      align-items: stretch;
  overflow: hidden;
  padding: 1em;
  position: relative; }
  .b-card-container > .b-card-item {
    display: none;
    -ms-flex: 1 0 100%;
        flex: 1 0 100%;
    -ms-flex-align: stretch;
        align-items: stretch;
    margin-left: 0;
    max-width: 100%;
    margin-right: 1em; }
    .b-card-container > .b-card-item.b-active {
      display: -ms-flexbox;
      display: flex; }

@-webkit-keyframes b-card-slide-in-left {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    margin-left: -1em; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    margin-left: 0; } }

@keyframes b-card-slide-in-left {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    margin-left: -1em; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    margin-left: 0; } }

@-webkit-keyframes b-card-slide-out-right {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes b-card-slide-out-right {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes b-card-slide-in-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@keyframes b-card-slide-in-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@-webkit-keyframes b-card-slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    margin-left: 0; }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    margin-left: -1em; } }

@keyframes b-card-slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    margin-left: 0; }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    margin-left: -1em; } }

.b-slide-in-left {
  -webkit-animation: b-card-slide-in-left 0.3s ease 0s 1;
          animation: b-card-slide-in-left 0.3s ease 0s 1; }

.b-slide-out-right {
  -webkit-animation: b-card-slide-out-right 0.3s ease 0s 1;
          animation: b-card-slide-out-right 0.3s ease 0s 1; }

.b-slide-in-right {
  -webkit-animation: b-card-slide-in-right 0.3s ease 0s 1;
          animation: b-card-slide-in-right 0.3s ease 0s 1; }

.b-slide-out-left {
  -webkit-animation: b-card-slide-out-left 0.3s ease 0s 1;
          animation: b-card-slide-out-left 0.3s ease 0s 1; }

.b-fit-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -ms-flex-align: stretch;
      align-items: stretch;
  overflow: hidden;
  position: relative; }
  .b-fit-container > .b-fit-item {
    -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    margin: 0;
    max-width: 100%; }

.b-edge .b-fit-container > .b-fit-item,
.b-ie .b-fit-container > .b-fit-item {
  -ms-flex: 1 0 auto;
      flex: 1 0 auto; }

.b-check-cell .b-checkbox label.b-checkbox-label:before {
  margin-right: 0; }

.b-percent-bar-outer {
  width: calc(100% - 1em);
  height: 1.4em;
  position: absolute;
  top: calc(50% - .7em);
  background: #f1f1f1; }

.b-percent-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  height: 100%;
  max-width: 100%;
  padding: 0 .2em;
  background: #27ca37;
  color: #fff;
  font-size: .7em;
  text-align: right;
  -webkit-transition: width 0.5s, padding .5s, background-color .5s;
  transition: width 0.5s, padding .5s, background-color .5s; }
  .b-percent-bar.b-zero {
    padding: 0;
    text-indent: .2em; }
  .b-percent-bar.b-low {
    background: #e53f2c; }

.b-grid-body-container.b-scrolling .b-percent-bar {
  -webkit-transition: none;
  transition: none; }

.b-rating-cell .b-icon {
  font-size: 1.8em; }

.b-rating-cell .b-empty {
  color: rgba(224, 224, 231, 0.3); }

.b-rating-cell .b-filled {
  color: #ffce3a; }

.b-grid:not(.b-readonly) .b-rating-cell-inner:not(.b-not-editable) .b-icon {
  cursor: pointer;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  position: relative; }

.b-grid:not(.b-readonly) .b-rating-cell-inner:not(.b-not-editable):hover .b-icon {
  color: #ffce3a; }
  .b-grid:not(.b-readonly) .b-rating-cell-inner:not(.b-not-editable):hover .b-icon.b-empty::before {
    opacity: 0.4; }

.b-grid:not(.b-readonly) .b-rating-cell-inner:not(.b-not-editable) .b-icon:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3); }
  .b-grid:not(.b-readonly) .b-rating-cell-inner:not(.b-not-editable) .b-icon:hover.b-filled {
    opacity: 1; }
  .b-grid:not(.b-readonly) .b-rating-cell-inner:not(.b-not-editable) .b-icon:hover:hover ~ .b-icon::before {
    color: rgba(224, 224, 231, 0.3);
    opacity: 1; }

.b-grid-body-container.b-scrolling .b-rating-cell-inner .b-icon {
  -webkit-transition: none;
  transition: none; }

.b-grid-cell.b-row-number-cell {
  background-color: #f1f1f1;
  border-right: 1px solid #e9eaeb; }

.b-grid-row.b-hover .b-grid-cell.b-row-number-cell:hover {
  border-right: 1px solid #e9eaeb; }

.b-group-row .b-grid-cell.b-row-number-cell:first-child {
  border-right: 1px solid #e9eaeb; }

.b-grid:focus .b-grid-row.b-selected .b-grid-cell.b-row-number-cell.b-selected {
  border-right: 1px solid #e9eaeb; }

.b-tree-expander {
  position: relative;
  top: 1px;
  float: left;
  margin: 0 .7em;
  cursor: pointer;
  width: 0.6em;
  min-width: 0.6em; }

.b-tree-icon {
  margin-right: .6em;
  width: 1em;
  min-width: 1em;
  text-align: center; }

.b-tree-expander::before {
  font-size: 1.2em;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.b-loading-children .b-tree-icon:before {
  content: "\F110";
  -webkit-animation: spin 2s infinite linear;
          animation: spin 2s infinite linear; }

.b-tree-leaf-cell .b-tree-cell-inner:before,
.b-tree-expander:before,
.b-tree-icon:before {
  font-family: "Font Awesome 5 Pro";
  color: #adaeaf; }

.b-tree-leaf-cell .b-tree-cell-inner:before {
  margin-right: .6em;
  width: 1em;
  min-width: 1em;
  text-align: center; }

.b-tree-cell-inner {
  padding: 0.5em 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-positive: 1;
      flex-grow: 1; }

a.b-tree-cell-inner {
  text-decoration: none; }
  a.b-tree-cell-inner:hover .b-tree-cell-value {
    text-decoration: underline; }

.b-tree-parent-cell,
.b-tree-leaf-cell {
  padding-left: 10px; }

.b-tree-cell.b-tree-parent-cell {
  font-weight: 600;
  color: #797b7d; }

.b-tree-cell-value {
  -ms-flex: 1 1;
      flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center; }

.b-touch .b-tree-expander {
  width: 1.1em; }

.b-touch .b-tree-expander:before {
  font-size: 1.8em; }

.b-touch .b-tree-icon,
.b-touch .b-tree-leaf-cell:not(.b-tree-parent-cell):before {
  font-size: 1.2em; }

.b-widget-cell .b-button {
  height: 80%;
  width: 100%; }

.b-cell-editor {
  position: absolute;
  background-color: transparent;
  border-width: 0;
  border-style: solid;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  z-index: 1;
  /*transition: left 0.1s, top 0.1s;*/ }
  .b-cell-editor > .b-widget {
    width: 100%;
    height: 100%; }
  .b-cell-editor .b-numberfield,
  .b-cell-editor .b-textfield {
    -ms-flex-align: stretch;
        align-items: stretch; }
    .b-cell-editor .b-numberfield .b-field-inner,
    .b-cell-editor .b-textfield .b-field-inner {
      padding-left: 0; }
    .b-cell-editor .b-numberfield input,
    .b-cell-editor .b-textfield input {
      padding: 0.8em calc(0.5em - 1px); }

.b-cell-editor-hidden {
  display: none; }

.b-grid-row .b-grid-cell.b-focused.b-editing:after {
  display: none; }

.b-columndragtoolbar {
  position: absolute;
  top: calc(100% - 3em);
  left: 50%;
  z-index: 100;
  -webkit-animation-name: b-show-columndragtoolbar;
          animation-name: b-show-columndragtoolbar;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 1em;
  background: #f8f9fa;
  -webkit-transition: opacity 0.2s, top 0.2s;
  transition: opacity 0.2s, top 0.2s;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 1em 1em 0.5em 1em;
  opacity: 0.4;
  font-size: .8em; }
  .b-columndragtoolbar.b-closer {
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .b-columndragtoolbar.b-hover {
    opacity: 0.8; }
  .b-columndragtoolbar.b-remove {
    -webkit-animation-name: b-hide-columndragtoolbar;
            animation-name: b-hide-columndragtoolbar;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    top: 100%;
    opacity: 0; }
    .b-columndragtoolbar.b-remove.b-closer {
      -webkit-animation-name: b-hide-columndragtoolbar-closer;
              animation-name: b-hide-columndragtoolbar-closer; }
  .b-columndragtoolbar > .b-title {
    color: #fff;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    margin-bottom: 1em;
    text-align: center; }
    .b-columndragtoolbar > .b-title:before {
      content: 'Drag header downwards'; }
  .b-columndragtoolbar.b-closer > .b-title:before {
    content: 'Drop header on a button'; }
  .b-columndragtoolbar .b-group {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    margin-right: 1.5em; }
    .b-columndragtoolbar .b-group:last-child {
      margin-right: 0; }
    .b-columndragtoolbar .b-group .b-title {
      color: #fff;
      margin-top: .5em;
      margin-bottom: .5em; }
  .b-columndragtoolbar .b-buttons {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-direction: row;
        flex-direction: row; }
  .b-columndragtoolbar .b-target-button {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -webkit-transition: all .2s;
    transition: all .2s;
    color: #4f5964;
    border: 0.3em solid #feac31;
    border-radius: 1em;
    background: transparent;
    padding: 1em 0;
    width: 7em;
    margin-right: .5em;
    -webkit-box-shadow: none;
            box-shadow: none; }
    .b-columndragtoolbar .b-target-button:last-child {
      margin-right: 0; }
    .b-columndragtoolbar .b-target-button i {
      font-size: 2.5em;
      color: #feac31;
      pointer-events: none;
      -webkit-transition: all .2s;
      transition: all .2s; }
    .b-columndragtoolbar .b-target-button.b-hover:not([data-disabled=true]) {
      background-color: #feac31;
      color: #fff;
      -webkit-box-shadow: none;
              box-shadow: none; }
      .b-columndragtoolbar .b-target-button.b-hover:not([data-disabled=true]) i {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        color: #fff; }
    .b-columndragtoolbar .b-target-button.b-activate i {
      -webkit-transform: scale(1.1) rotate(180deg) !important;
              transform: scale(1.1) rotate(180deg) !important; }
    .b-columndragtoolbar .b-target-button[data-button-id^=group] {
      border-color: #3183fe;
      background-color: transparent; }
      .b-columndragtoolbar .b-target-button[data-button-id^=group] i {
        color: #3183fe; }
      .b-columndragtoolbar .b-target-button[data-button-id^=group].b-hover:not([data-disabled=true]) {
        background-color: #3183fe; }
        .b-columndragtoolbar .b-target-button[data-button-id^=group].b-hover:not([data-disabled=true]) i {
          color: #fff; }
    .b-columndragtoolbar .b-target-button[data-button-id^=multisort] {
      border-color: #fb9701; }
      .b-columndragtoolbar .b-target-button[data-button-id^=multisort] i {
        color: #fb9701; }
      .b-columndragtoolbar .b-target-button[data-button-id^=multisort].b-hover:not([data-disabled=true]) {
        background-color: #fb9701; }
        .b-columndragtoolbar .b-target-button[data-button-id^=multisort].b-hover:not([data-disabled=true]) i {
          color: #fff; }
    .b-columndragtoolbar .b-target-button[data-disabled=true] {
      opacity: .5; }

@-webkit-keyframes b-show-columndragtoolbar {
  from {
    top: 100%;
    opacity: 0; }
  to {
    top: calc(100% - 3em);
    opacity: .4; } }

@keyframes b-show-columndragtoolbar {
  from {
    top: 100%;
    opacity: 0; }
  to {
    top: calc(100% - 3em);
    opacity: .4; } }

@-webkit-keyframes b-hide-columndragtoolbar {
  from {
    top: calc(100% - 3em);
    opacity: .4; }
  to {
    top: 100%;
    opacity: 0; } }

@keyframes b-hide-columndragtoolbar {
  from {
    top: calc(100% - 3em);
    opacity: .4; }
  to {
    top: 100%;
    opacity: 0; } }

@-webkit-keyframes b-hide-columndragtoolbar-closer {
  from {
    top: 50%;
    opacity: .4; }
  to {
    top: 100%;
    opacity: 0; } }

@keyframes b-hide-columndragtoolbar-closer {
  from {
    top: 50%;
    opacity: .4; }
  to {
    top: 100%;
    opacity: 0; } }

.b-grid-header.b-drag-proxy {
  line-height: normal;
  font-weight: 500;
  font-size: 14px;
  background-color: #f3f4f5;
  border: 1px solid #d8d9da;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s; }
  .b-grid-header.b-drag-proxy .b-grid-header-text {
    padding: 5px; }

.b-grid-header.b-drop-placeholder {
  opacity: .3; }

.b-grid-header.b-drag-invalid {
  color: #e53f2c;
  border: 1px solid #e53f2c; }

.b-grid.b-column-resize .b-grid-header.b-resize-handle {
  cursor: ew-resize !important; }

.b-grid.b-column-resize .b-grid-header.b-resizing {
  background: white; }

.b-grid.b-column-resize.b-sort {
  /*.b-grid-header.b-resizing:hover:not(.b-sort):after {
                display: none;
            }*/ }
  .b-grid.b-column-resize.b-sort .b-grid-header.b-resizing:not(.b-filter) .b-filter-icon,
  .b-grid.b-column-resize.b-sort .b-grid-header.b-over-resize-handle:not(.b-filter) .b-filter-icon,
  .b-grid.b-column-resize.b-sort .b-grid-header.b-resizing:not(.b-sort):after,
  .b-grid.b-column-resize.b-sort .b-grid-header.b-over-resize-handle:hover:not(.b-sort):after {
    display: none; }

.b-grid.b-column-resize.b-touch.b-column-resizing .b-grid-header.b-resizing {
  overflow: visible;
  z-index: 100; }
  .b-grid.b-column-resize.b-touch.b-column-resizing .b-grid-header.b-resizing::before {
    font-size: 1.5em;
    color: #fff;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(50%) translateY(-50%);
            transform: translateX(50%) translateY(-50%);
    right: 0;
    z-index: 101;
    border-radius: 100%;
    background-color: #d8d9da;
    padding: 0.5em; }

.b-grid.b-column-resize.b-touch.b-column-resizing .b-grid-header:not(.b-resizing) {
  z-index: 1; }

.b-context-menu {
  min-width: 14em; }

@-webkit-keyframes b-filter-icon-color {
  0% {
    color: #b0b0b7; }
  50% {
    color: #3183fe; }
  100% {
    color: #b0b0b7; } }

@keyframes b-filter-icon-color {
  0% {
    color: #b0b0b7; }
  50% {
    color: #3183fe; }
  100% {
    color: #b0b0b7; } }

.b-grid-header .b-filter-icon {
  display: none;
  cursor: pointer; }
  .b-grid-header .b-filter-icon::after {
    font-family: "Font Awesome 5 Pro";
    font-size: 0.8em;
    color: #e5e7e9;
    margin-left: .5em;
    -webkit-transition: color 0.2s, -webkit-transform 0.3s;
    transition: color 0.2s, -webkit-transform 0.3s;
    transition: transform 0.3s, color 0.2s;
    transition: transform 0.3s, color 0.2s, -webkit-transform 0.3s;
    -webkit-transform: none;
            transform: none;
    background: inherit;
    /* Transparent bg will look bad if there is text under the icon */ }
  .b-grid-header .b-filter-icon:hover::after {
    color: #b0b0b7; }

.b-grid.b-sort:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header:hover .b-filter-icon {
  display: -ms-flexbox;
  display: flex; }

.b-grid-header.b-filter .b-grid-header-text {
  font-weight: 700;
  color: #666; }

.b-grid-header.b-filter .b-filter-icon {
  display: -ms-flexbox;
  display: flex; }
  .b-grid-header.b-filter .b-filter-icon::after {
    color: #b0b0b7; }
  .b-grid-header.b-filter .b-filter-icon.b-latest::after {
    -webkit-animation-name: b-filter-icon-color;
            animation-name: b-filter-icon-color;
    -webkit-animation-duration: 0.75s;
            animation-duration: 0.75s; }

.b-filter-popup .b-field {
  width: 15em; }

.b-filter-bar-field {
  margin: 0 0 .5em 0;
  width: 100%; }

.b-group-summary .b-grid-row.b-group-footer .b-grid-cell {
  border-right-color: transparent;
  background-color: #fffbf5; }

.b-group-summary .b-grid-row.b-group-footer td {
  padding: .25em 0; }

.b-group-summary .b-grid-row.b-group-footer .b-grid-summary-label {
  padding-right: 1em; }

.b-group-summary .b-grid-row.b-group-footer .b-grid-summary-value {
  width: 100%; }

.b-quick-hit-cell {
  background: #f5fe8c; }

.b-quick-hit-text {
  font-weight: 400;
  background: #f5fe8c; }

.b-quick-hit-text {
  padding: .3em 0; }

.b-quick-hit-header {
  position: absolute;
  top: .3em;
  left: .3em;
  bottom: .3em;
  right: .3em;
  z-index: 1; }
  .b-quick-hit-header.b-quick-hit-mode-grid {
    bottom: auto;
    height: 3em;
    z-index: 100;
    opacity: 0.75; }

.b-quick-hit-field {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 7px 0;
  background: #f5fe8c; }

.b-quick-hit-cell-badge,
.b-quick-hit-header .b-quick-hit-badge {
  position: absolute;
  top: .4em;
  right: .4em;
  font-size: .7em;
  line-height: .7em; }

.b-quick-hit-header .b-quick-hit-badge {
  color: #7f8a01; }

.b-quick-hit-cell-badge {
  color: #7f8a01; }

.b-grid.b-split .b-grid-splitter {
  background: #e9eaeb;
  width: 5px;
  cursor: col-resize;
  -webkit-transition: width 0.2s, -webkit-transform 0.2s;
  transition: width 0.2s, -webkit-transform 0.2s;
  transition: width 0.2s, transform 0.2s;
  transition: width 0.2s, transform 0.2s, -webkit-transform 0.2s;
  overflow: hidden;
  white-space: nowrap; }
  .b-grid.b-split .b-grid-splitter.b-grid-splitter-collapsed {
    cursor: auto;
    cursor: initial; }
    .b-grid.b-split .b-grid-splitter.b-grid-splitter-collapsed:not(:hover) .b-icon {
      display: inline;
      opacity: 0.5; }
    .b-grid.b-split .b-grid-splitter.b-grid-splitter-collapsed:not(.b-grid-splitter-allow-collapse) .b-icon.b-icon-collapse-gridregion {
      display: none; }
    .b-grid.b-split .b-grid-splitter.b-grid-splitter-collapsed.b-grid-splitter-allow-collapse .b-icon.b-icon-expand-gridregion {
      display: none; }
  .b-grid.b-split .b-grid-splitter.b-left-only:not(.b-grid-splitter-collapsed) .b-icon.b-icon-expand-gridregion {
    display: none; }
  .b-grid.b-split .b-grid-splitter.b-right-only:not(.b-grid-splitter-collapsed) .b-icon.b-icon-collapse-gridregion {
    display: none; }
  .b-grid.b-split .b-grid-splitter .b-icon {
    position: absolute;
    display: none;
    cursor: pointer;
    color: #7b7b86;
    font-size: 1.6em; }
    .b-grid.b-split .b-grid-splitter .b-icon:hover {
      color: #fff; }
    .b-grid.b-split .b-grid-splitter .b-icon.b-icon-collapse-gridregion {
      left: 50%;
      margin-left: -1px;
      top: 50%;
      -webkit-transform: translate(-50%, -10px);
              transform: translate(-50%, -10px); }
    .b-grid.b-split .b-grid-splitter .b-icon.b-icon-expand-gridregion {
      left: 50%;
      margin-left: 1px;
      top: 50%;
      -webkit-transform: translate(-50%, 10px);
              transform: translate(-50%, 10px);
      text-align: right; }
  .b-grid.b-split .b-grid-splitter .b-grid-splitter-inner {
    -webkit-transition: width 0.2s;
    transition: width 0.2s; }
  .b-grid.b-split .b-grid-splitter:hover, .b-grid.b-split .b-grid-splitter.b-hover, .b-grid.b-split .b-grid-splitter.b-moving {
    width: 12px; }
    .b-grid.b-split .b-grid-splitter:hover .b-grid-splitter-inner, .b-grid.b-split .b-grid-splitter.b-hover .b-grid-splitter-inner, .b-grid.b-split .b-grid-splitter.b-moving .b-grid-splitter-inner {
      width: 12px; }
    .b-grid.b-split .b-grid-splitter:hover:not(.b-grid-splitter-collapsed), .b-grid.b-split .b-grid-splitter.b-hover:not(.b-grid-splitter-collapsed), .b-grid.b-split .b-grid-splitter.b-moving:not(.b-grid-splitter-collapsed) {
      -webkit-transform: translateX(-4px);
              transform: translateX(-4px);
      -ms-transform: translate(-4px, -100%); }
    .b-grid.b-split .b-grid-splitter:hover .b-icon, .b-grid.b-split .b-grid-splitter.b-hover .b-icon, .b-grid.b-split .b-grid-splitter.b-moving .b-icon {
      display: inline; }

.b-grid.b-split .b-grid-horizontal-scroller:not(:first-child) {
  margin-left: 5px; }

.b-grid.b-split .b-grid-header-scroller:not(:first-child),
.b-grid.b-split .b-grid-footer-scroller:not(:first-child) {
  margin-left: 5px; }

.b-grid.b-split .b-virtual-scroller:not(:first-child) {
  margin-left: 5px; }

.b-grid:not(.b-split) .b-grid-splitter .b-icon {
  display: none; }

.b-grid.b-split.b-touch .b-grid-splitter {
  width: 25px;
  background: transparent;
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
  -ms-transform: translate(-10px, -100%); }
  .b-grid.b-split.b-touch .b-grid-splitter:hover:not(.b-grid-splitter-collapsed), .b-grid.b-split.b-touch .b-grid-splitter.b-moving:not(.b-grid-splitter-collapsed) {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
    -ms-transform: translate(-10px, -100%); }
  .b-grid.b-split.b-touch .b-grid-splitter.b-grid-splitter-collapsed .b-grid-splitter-inner {
    width: 12px; }

.b-grid.b-split.b-touch .b-grid-splitter-inner {
  width: 5px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 100%;
  background: #e9eaeb; }

.b-grid.b-split.b-touch .b-grid-horizontal-scroller:not(:first-child) {
  margin-left: 5px; }

.b-grid.b-split.b-touch .b-grid-header-scroller:not(:first-child) {
  margin-left: 5px; }

.b-grid.b-split.b-touch .b-virtual-scroller:not(:first-child) {
  margin-left: 5px; }

.b-moving-splitter.b-touch .b-grid-splitter::before {
  font-size: 1.5em;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 12.5px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 101;
  border-radius: 100%;
  background-color: #b0b0b7;
  padding: 0.5em; }

.b-draghelper-active .b-grid.b-split .b-grid-splitter {
  cursor: auto;
  cursor: initial;
  width: 5px !important;
  -webkit-transform: none !important;
          transform: none !important;
  -ms-transform: none !important; }
  .b-draghelper-active .b-grid.b-split .b-grid-splitter .b-icon {
    display: none !important; }
  .b-draghelper-active .b-grid.b-split .b-grid-splitter .b-grid-splitter-inner {
    width: 5px !important; }

.b-row-drop-indicator {
  display: none;
  pointer-events: none; }

.b-row-reordering .b-row-drop-indicator {
  position: absolute;
  display: block;
  left: 0;
  top: -1px;
  width: 100%;
  height: 2px;
  background-color: #feac31;
  z-index: 2000; }

.b-row-reordering .b-drag-proxy.b-dragging {
  -webkit-transition: margin-top 0.2s, opacity 0.2s;
  transition: margin-top 0.2s, opacity 0.2s;
  margin-top: 5em;
  background: transparent;
  opacity: 1; }
  .b-row-reordering .b-drag-proxy.b-dragging .b-grid-row {
    -webkit-transition: background-color 0.2s, -webkit-transform 0.2s;
    transition: background-color 0.2s, -webkit-transform 0.2s;
    transition: transform 0.2s, background-color 0.2s;
    transition: transform 0.2s, background-color 0.2s, -webkit-transform 0.2s;
    -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    background: #fff; }
  .b-row-reordering .b-drag-proxy.b-dragging.b-drag-invalid .b-grid-row {
    border: 2px solid #e53f2c; }

.b-row-reordering .b-grid-body-container {
  z-index: 4; }

.b-row-reordering .b-drag-original {
  opacity: 0.3; }

.b-row-reordering .b-drag-proxy.b-dropping {
  margin-top: 0;
  opacity: 0; }
  .b-row-reordering .b-drag-proxy.b-dropping .b-grid-row {
    -webkit-transform: scale(1);
            transform: scale(1); }

.b-row-reordering .b-row-reordering-target-parent .b-tree-cell {
  color: #feac31;
  overflow: visible;
  contain: none; }
  .b-row-reordering .b-row-reordering-target-parent .b-tree-cell .b-tree-expander::before {
    color: #feac31; }
  .b-row-reordering .b-row-reordering-target-parent .b-tree-cell::before {
    position: absolute;
    left: -.5em; }

.b-search-hit-cell {
  background: #f5fe8c; }

.b-search-hit-text {
  font-weight: 400;
  background: #f5fe8c;
  padding: .3em 0; }

.b-search-hit-field {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: .5em 0;
  background: #f5fe8c; }

.b-search-hit-cell-badge {
  position: absolute;
  top: .4em;
  right: .4em;
  font-size: .7em;
  line-height: .7em;
  color: #7f8a01; }

.b-grid.b-sort .b-grid-header.b-sort .b-grid-header-text::after {
  font-family: "Font Awesome 5 Pro";
  font-size: 0.8em;
  color: #b0b0b7;
  margin-left: .5em;
  -webkit-transition: color 0.2s, -webkit-transform 0.3s;
  transition: color 0.2s, -webkit-transform 0.3s;
  transition: transform 0.3s, color 0.2s;
  transition: transform 0.3s, color 0.2s, -webkit-transform 0.3s;
  -webkit-transform: none;
          transform: none;
  background: inherit;
  /* Transparent bg will look bad if there is text under the icon */ }

.b-grid.b-sort .b-grid-header.b-sort.b-desc .b-grid-header-text::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.b-grid.b-sort .b-grid-header.b-sort .b-grid-header-text[data-sort-index]::before {
  content: attr(data-sort-index);
  position: relative;
  top: 1em;
  color: #7b7b86;
  z-index: 30;
  font-size: .6em;
  -ms-flex-order: 1;
      order: 1; }

.b-grid.b-sort .b-grid-header.b-sort .b-grid-header-text {
  color: #667280; }

.b-grid.b-sort:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header.b-sortable.b-depth-0:hover:not(.b-sort):not(.b-group) .b-grid-header-text::after,
.b-grid.b-sort:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header.b-sortable.b-depth-0:focus:not(.b-sort):not(.b-group) .b-grid-header-text::after {
  font-family: "Font Awesome 5 Pro";
  font-size: 0.8em;
  color: #e5e7e9;
  margin-left: .5em;
  -webkit-transition: color 0.2s, -webkit-transform 0.3s;
  transition: color 0.2s, -webkit-transform 0.3s;
  transition: transform 0.3s, color 0.2s;
  transition: transform 0.3s, color 0.2s, -webkit-transform 0.3s;
  -webkit-transform: none;
          transform: none;
  background: inherit;
  /* Transparent bg will look bad if there is text under the icon */ }

.b-grid-header.b-group .b-grid-header-text::after {
  font-family: "Font Awesome 5 Pro";
  font-size: 0.8em;
  color: #3183fe;
  margin-left: .5em;
  -webkit-transition: color 0.2s, -webkit-transform 0.3s;
  transition: color 0.2s, -webkit-transform 0.3s;
  transition: transform 0.3s, color 0.2s;
  transition: transform 0.3s, color 0.2s, -webkit-transform 0.3s;
  -webkit-transform: none;
          transform: none;
  background: inherit;
  /* Transparent bg will look bad if there is text under the icon */ }

.b-grid-header.b-group.b-desc .b-grid-header-text:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.b-group-row {
  background: #fafbfc; }
  .b-group-row .b-grid-cell {
    border-right-color: transparent; }
  .b-group-row .b-group-title.b-grid-cell-align-right {
    -ms-flex-pack: start;
        justify-content: flex-start; }
  .b-group-row .b-group-title.b-grid-cell-align-center {
    -ms-flex-pack: start;
        justify-content: flex-start; }

.b-grid-cell.b-group-title {
  color: #4f5964;
  overflow: visible;
  contain: size layout style;
  font-weight: 500;
  text-transform: uppercase; }
  .b-grid-cell.b-group-title:before {
    margin-right: .5em;
    cursor: pointer;
    color: #babbbc; }

.b-stripe .b-grid-row.b-group-row,
.b-grid-row.b-group-row {
  border-bottom: 1px solid #d8d9da;
  cursor: pointer; }

.b-stripe .b-grid-row.b-odd {
  background-color: rgba(250, 250, 250, 0.8); }

.b-stripe .b-grid-row.b-even {
  background-color: rgba(255, 255, 255, 0.8); }

.b-stripe .b-grid-row {
  border-bottom: none; }

.b-stripe .b-grid-row.b-selected {
  background-color: rgba(254, 172, 49, 0.25); }
  .b-stripe .b-grid-row.b-selected .b-grid-cell.b-selected {
    background-color: rgba(254, 172, 49, 0.25); }

.b-stripe:focus .b-grid-row.b-selected {
  background-color: rgba(254, 172, 49, 0.3); }

.b-grid-footer-summary td {
  padding: .25em 0; }

.b-grid-footer-summary .b-grid-summary-label {
  font-size: .7em;
  padding-right: 1em; }

.b-grid-footer-summary .b-grid-summary-value {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.b-grid-footer-container {
  background-color: #e0e0e7;
  outline: 1px solid #d8d9da;
  z-index: 2; }
  .b-grid-footer-container.b-hidden {
    display: none; }

.b-grid-footers {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: stretch;
      align-items: stretch;
  height: 100%;
  white-space: nowrap;
  line-height: normal;
  line-height: initial; }
  .b-overflowing > .b-grid-footers {
    -ms-flex: 1 1;
        flex: 1 1; }
  :not(.b-overflowing) > .b-grid-footers {
    min-width: 100%; }

.b-grid-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-align: stretch;
      align-items: stretch;
  -ms-flex-pack: center;
      justify-content: center;
  position: relative;
  padding: 0.5em .5em;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
  color: #667280;
  overflow: hidden; }

.b-grid-footer:last-child {
  border-right: none; }

.b-grid-footer-align-left {
  text-align: left; }

.b-grid-footer-align-center {
  text-align: center; }

.b-grid-footer-align-right {
  text-align: right; }

.b-grid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  /* This is required for splitter to have correct height in IE11 */
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /*
     * This implements the fillLastColumn config. It causes the last leaf header, and the last cell in
     * each row to flex-grow to consume all available space when there are no configured flexed columns and rows are not
     * wider than their container.
     */ }
  .b-grid.b-outer {
    height: 100%; }
  .b-grid.b-autoheight {
    height: auto; }
  .b-grid:not(.b-autoheight) .b-grid-body-container {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    contain: strict; }
  .b-grid:not(.b-autoheight) > .b-grid-body-container > .b-grid-vertical-scroller {
    min-height: 100%; }
  .b-grid.b-grid-translate .b-grid-row, .b-grid.b-grid-translate3d .b-grid-row {
    -webkit-transform-style: flat;
            transform-style: flat; }
  .b-grid.b-no-column-lines .b-grid-row .b-grid-cell {
    border-right-color: transparent; }
  .b-grid.b-fill-last-column .b-grid-horizontal-scroller:not(.b-has-flex):not(.b-overflowing-horizontally) .b-grid-cell:last-child,
  .b-grid.b-fill-last-column .b-grid-footer-scroller:not(.b-has-flex):not(.b-overflowing) .b-grid-footer:last-child,
  .b-grid.b-fill-last-column .b-grid-header-scroller:not(.b-has-flex):not(.b-overflowing) .b-last-parent,
  .b-grid.b-fill-last-column .b-grid-header-scroller:not(.b-has-flex):not(.b-overflowing) .b-last-leaf {
    -ms-flex-positive: 1;
        flex-grow: 1; }
  .b-grid .b-grid-empty .b-grid-subgrid:first-child::before {
    color: #667280;
    content: attr(data-empty-text);
    position: absolute;
    top: 1em;
    left: 1em; }
  .b-grid:focus {
    outline: none; }
    .b-grid:focus .b-grid-row.b-selected {
      background-color: rgba(254, 172, 49, 0.3); }
  .b-grid.b-grid-notextselection .b-grid-cell {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .b-grid .b-grid-header-scroller:first-child,
  .b-grid .b-grid-horizontal-scroller:first-child,
  .b-grid .b-virtual-scroller:first-child {
    margin-left: 0; }
  .b-grid.b-notransition * {
    -webkit-transition: none !important;
    transition: none !important; }
  .b-grid.b-disabled {
    opacity: 0.5; }

.b-grid-splitter {
  width: 1px;
  position: absolute;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  height: 100%;
  background: #bec0c1;
  z-index: 5; }
  .b-grid-splitter.b-grid-splitter-animate {
    -webkit-transition: left .15s;
    transition: left .15s; }

.b-grid-splitter.b-grid-splitter-transform {
  -webkit-transform: translate(0, -100%);
          transform: translate(0, -100%); }

.b-grid-row:not(.b-grid-row-updating) * {
  -webkit-transition: none;
  transition: none; }

.b-grid-body-container {
  position: relative; }

.b-playing-demo .b-grid-body-container {
  overflow: hidden !important; }

.b-grid-vertical-scroller {
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  overflow: hidden;
  width: 100%;
  -ms-flex-align: stretch;
      align-items: stretch; }

.b-grid-row-container {
  display: inline-block;
  min-width: 100%; }

.b-virtual-scrollers {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  contain: paint style layout; }
  .b-virtual-scrollers.b-overlay-scrollbar {
    position: absolute;
    bottom: 0;
    pointer-events: none; }
    .b-virtual-scrollers.b-overlay-scrollbar .b-virtual-scroller {
      height: 20px; }
  .b-virtual-scrollers .b-virtual-scroller {
    overflow-x: scroll;
    overflow-y: hidden; }
    .b-safari .b-virtual-scrollers .b-virtual-scroller {
      overflow-x: scroll !important; }
  .b-virtual-scrollers .b-virtual-scroller:not(:first-child) {
    margin-left: 1px; }
  .b-virtual-scrollers .b-virtual-width {
    height: 1px; }

/* using tag name to increase specificity, otherwise rule gets overridden by salesforce styles */
.b-grid-footer-container,
header.b-grid-header-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row; }

.b-grid-header-container {
  border-bottom: 1px solid #d8d9da; }

.b-grid-footer-scroller,
.b-grid-header-scroller {
  overflow: hidden;
  position: relative;
  display: -ms-flexbox;
  display: flex; }

.b-grid-header-scroller:not(:first-child),
.b-grid-footer-scroller:not(:first-child) {
  margin-left: 1px; }

.b-grid-footers,
.b-grid-headers {
  z-index: 2;
  contain: paint style layout; }

.b-grid-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: stretch;
      align-items: stretch;
  position: absolute;
  min-width: 100%;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  left: 0;
  overflow: hidden;
  border-bottom: 1px solid #e9eaeb;
  height: 45px;
  contain: layout; }

.b-grid-refreshing .b-grid-row {
  -webkit-transition: none;
  transition: none; }

.b-grid-row.b-selected {
  background-color: rgba(254, 172, 49, 0.25); }

.b-grid-row.b-removing {
  left: -100%;
  -webkit-transition: left .4s;
  transition: left .4s;
  pointer-events: none; }

.b-grid-cell {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: #606263;
  font-weight: 400;
  padding: 0 .5em;
  overflow: hidden;
  white-space: nowrap;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  text-overflow: ellipsis;
  border-right: 1px solid #e9eaeb;
  -webkit-transform-style: flat;
          transform-style: flat;
  width: 0;
  contain: strict; }
  .b-grid-cell:last-child {
    border-right-color: transparent; }
  .b-grid-cell > i {
    margin-right: .5em; }
  .b-show-dirty .b-grid-cell.b-cell-dirty:after {
    content: " ";
    position: absolute;
    top: -5px;
    left: -5px;
    background-color: red;
    width: 10px;
    height: 10px;
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg); }

.b-grid-cell.b-focused {
  -webkit-transition: none !important;
  transition: none !important; }
  .b-grid-cell.b-focused:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    border: 1px solid rgba(49, 131, 254, 0.6) !important;
    border-radius: 0; }

.b-grid-cell-align-right {
  -ms-flex-pack: end;
      justify-content: flex-end; }

.b-grid-cell-align-center {
  -ms-flex-pack: center;
      justify-content: center; }

.b-grid-subgrid.b-grid-horizontal-scroller {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  -ms-overflow-style: none; }
  .b-grid-subgrid.b-grid-horizontal-scroller::-webkit-scrollbar {
    display: none; }
  .b-firefox .b-grid:not(.b-overlay-scrollbar) .b-grid-subgrid.b-grid-horizontal-scroller,
  .b-grid.b-firefox:not(.b-overlay-scrollbar) .b-grid-subgrid.b-grid-horizontal-scroller {
    overflow: hidden !important; }

.b-grid-subgrid.b-grid-horizontal-scroller:not(:first-child) {
  margin-left: 1px; }

.b-grid-subgrid.b-grid-subgrid-animate-collapse {
  -webkit-transition: width .15s;
  transition: width .15s; }

.b-playing-demo .b-grid-subgrid {
  overflow: hidden !important; }

.b-grid-header-scroller.b-grid-subgrid-animate-collapse {
  -webkit-transition: width .15s;
  transition: width .15s; }

.b-grid:not(.b-moving-splitter) .b-grid-row.b-hover .b-grid-cell {
  background-color: rgba(254, 172, 49, 0.15); }

.b-grid:not(.b-moving-splitter) .b-grid-row.b-hover .b-grid-cell:not(.b-focused):hover {
  background-color: rgba(254, 172, 49, 0.1); }

.b-grid-header-container {
  background-color: #f3f4f5;
  z-index: 4;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08); }
  .b-grid-header-container.b-hidden {
    display: none; }

.b-grid-headers {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -ms-flex-align: stretch;
      align-items: stretch;
  line-height: normal;
  line-height: initial;
  position: relative; }
  .b-overflowing > .b-grid-headers {
    -ms-flex: 1 1;
        flex: 1 1; }
  :not(.b-overflowing) > .b-grid-headers {
    min-width: 100%; }

.b-grid-header-align-right > .b-grid-header-text > .b-grid-header-text-content {
  text-align: right; }

.b-grid-header-align-center > .b-grid-header-text > .b-grid-header-text-content {
  text-align: center; }

.b-grid:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-depth-0:hover, .b-grid:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-depth-0:focus {
  background-color: white; }

.b-grid.b-column-resize .b-grid-header-resizable:not(.b-last-leaf) {
  overflow: visible; }

.b-grid.b-column-resize .b-grid-header-resizable .b-grid-header-resize-handle {
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  width: 10px;
  background-color: transparent;
  z-index: 3;
  cursor: col-resize;
  display: block; }

.b-touch-events .b-grid.b-column-resize .b-grid-header-resizable .b-grid-header-resize-handle {
  right: -10px;
  width: 20px; }

.b-grid-vertical-overflow .b-grid-header-scroller:last-child {
  border-right: 1px solid #d8d9da; }

.b-fill-last-column .b-grid-headers .b-last-parent,
.b-fill-last-column .b-grid-headers .b-last-leaf {
  border-right-color: transparent; }
  .b-fill-last-column .b-grid-headers .b-last-parent > .b-grid-header-text,
  .b-fill-last-column .b-grid-headers .b-last-leaf > .b-grid-header-text {
    border-right-color: transparent; }

.b-ie .b-grid-header.b-depth-1 .b-grid-header-text {
  -ms-flex-preferred-size: 1em;
      flex-basis: 1em; }

/* Need some rules to be global to be used in drag proxies*/
.b-grid-header-text {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 1em 0 1em 0;
  white-space: nowrap;
  position: relative;
  font-weight: 500;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  overflow: hidden; }
  .b-grid-header-text > .b-grid-header-text-content {
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-flex: 1 1;
        flex: 1 1;
    width: 0;
    font-size: 0.9em; }
    .b-ie .b-grid-header-text > .b-grid-header-text-content {
      -ms-flex: 1 1;
          flex: 1 1; }
    .b-grid-header-text > .b-grid-header-text-content > i {
      margin-right: .5em; }

.b-grid-header-children {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .b-grid-header-children > * {
    width: inherit; }

.b-grid-header-parent > .b-grid-header-children {
  border-top: 1px solid #d8d9da;
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap; }

.b-grid-header {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: stretch;
      align-items: stretch;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  position: relative;
  overflow: hidden;
  background: #f3f4f5;
  color: #667280;
  outline: none;
  border-right: 1px solid #d8d9da;
  text-transform: uppercase; }
  .b-grid-header:not(.b-depth-0) {
    overflow: visible; }
  .b-grid-header.b-depth-0 {
    padding: 0 0.5em 0 0.5em;
    width: 0;
    cursor: pointer; }
    .b-grid-header.b-depth-0 .b-grid-header-text {
      border-bottom: none;
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s;
      -ms-flex-direction: row;
          flex-direction: row; }
  .b-grid-header.b-grid-header-parent {
    border-right: none;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    padding-left: 0;
    padding-right: 0; }
    .b-grid-header.b-grid-header-parent > .b-grid-header-text {
      padding-left: 0.5em;
      padding-right: 0.5em;
      border-right: 1px solid #d8d9da; }
  .b-grid-header .b-grid-header-resize-handle {
    display: none; }

.b-checkbox.b-blue label.b-checkbox-label:before {
  color: transparent;
  border-color: #e0e1e2; }

.b-checkbox.b-blue input[type=checkbox]:checked + label.b-checkbox-label:before {
  color: #3183fe;
  background: #fff;
  border-color: #e0e1e2; }

.b-checkbox.b-blue.b-disabled label::before {
  border-color: #e0e1e2;
  background: #e8e9ea; }

.b-checkbox.b-orange label.b-checkbox-label:before {
  color: transparent;
  border-color: #e0e1e2; }

.b-checkbox.b-orange input[type=checkbox]:checked + label.b-checkbox-label:before {
  color: #feac31;
  background: #fff;
  border-color: #e0e1e2; }

.b-checkbox.b-orange.b-disabled label::before {
  border-color: #e0e1e2;
  background: #e8e9ea; }

.b-grid.b-no-column-lines .b-grid-header {
  border-right-color: transparent; }

header.demo-header {
  background-color: #2667c8; }

.b-sch-style-plain {
  border-radius: 2px; }

.b-grid.b-no-column-lines .b-sch-header-timeaxis-cell {
  border-right-color: transparent; }

.b-sch-canvas,
.b-sch-foreground-canvas,
.b-sch-background-canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  height: 100%;
  overflow: hidden; }

.b-sch-background-canvas {
  z-index: 1;
  background-repeat: repeat; }

.b-sch-foreground-canvas {
  z-index: 2; }
  .b-row-reordering .b-sch-foreground-canvas * {
    pointer-events: none !important; }

.b-animating .b-timeline-subgrid {
  pointer-events: none !important; }

.b-scheduler > .b-editor {
  z-index: 5; }

.b-sch-event.b-milestone label,
.b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event {
  font-weight: 500;
  font-size: 13px; }
  .b-milestone .b-sch-event.b-milestone label, .b-milestone
  .b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event {
    color: #888; }

.b-sch-event-wrap {
  position: absolute;
  will-change: contents, width, height, transform;
  -webkit-transform-style: flat;
          transform-style: flat;
  z-index: 5;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  pointer-events: all;
  -ms-touch-action: none;
      touch-action: none;
  -webkit-transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s;
  transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s;
  contain: layout; }
  .b-animating .b-sch-event-wrap {
    -webkit-transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, width 0.2s, height 0.2s, font-size 0.2s, -webkit-transform 0.2s;
    transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, width 0.2s, height 0.2s, font-size 0.2s, -webkit-transform 0.2s;
    transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, transform 0.2s, width 0.2s, height 0.2s, font-size 0.2s;
    transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, transform 0.2s, width 0.2s, height 0.2s, font-size 0.2s, -webkit-transform 0.2s; }
  .b-toggling-node .b-sch-event-wrap {
    -webkit-transition: none;
    transition: none; }
  .b-sch-event-wrap.b-sch-released {
    display: none;
    -webkit-transition: none;
    transition: none; }
  .b-sch-event-wrap.b-active {
    z-index: 15 !important; }
  .b-sch-event-wrap:focus {
    outline: none;
    z-index: 7; }
  .b-scrolling .b-sch-event-wrap {
    -webkit-transition: none !important;
    transition: none !important;
    pointer-events: none !important; }
    .b-scrolling .b-sch-event-wrap .b-sch-event {
      -webkit-transition: none !important;
      transition: none !important;
      pointer-events: none !important; }

.b-sch-event-wrap.b-milestone-wrap {
  min-width: 1em;
  margin-left: -0.5em;
  z-index: 4; }
  .b-sch-event-wrap.b-milestone-wrap .b-sch-event {
    overflow: visible;
    -webkit-box-shadow: none;
            box-shadow: none;
    border-color: #27ca37; }
    .b-sch-event-wrap.b-milestone-wrap .b-sch-event:not(.b-sch-event-withicon) {
      background-color: #777;
      width: 0;
      font-size: inherit;
      min-width: 0; }
    .b-sch-event-wrap.b-milestone-wrap .b-sch-event.b-sch-event-withicon {
      -ms-flex-pack: center;
          justify-content: center; }
      .b-sch-event-wrap.b-milestone-wrap .b-sch-event.b-sch-event-withicon .b-fa, .b-sch-event-wrap.b-milestone-wrap .b-sch-event.b-sch-event-withicon .b-icon {
        margin-right: 0; }
    .b-sch-event-wrap.b-milestone-wrap .b-sch-event label {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      font-size: 13px;
      padding-left: 1.9em; }
  .b-sch-event-wrap.b-milestone-wrap.b-sch-style-plain > .b-sch-event {
    background-color: transparent; }
  .b-sch-event-wrap.b-milestone-wrap .b-fa, .b-sch-event-wrap.b-milestone-wrap .b-icon {
    margin-right: 1em;
    color: #777;
    font-size: inherit; }
  .b-labels-topbottom .b-sch-event-wrap.b-milestone-wrap .b-sch-event:not(.b-sch-event-withicon), .b-labels-topbottom
  .b-sch-event-wrap.b-milestone-wrap .b-task-baseline {
    font-size: 50%; }
  .b-sch-event-wrap.b-milestone-wrap .b-sch-event:not(.b-sch-event-withicon):before,
  .b-sch-event-wrap.b-milestone-wrap .b-task-baseline:before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: ' ';
    height: 0.707107em;
    width: 0.707107em;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
    background-image: inherit;
    background-color: inherit;
    border-style: inherit;
    border-color: inherit; }
  .b-sch-event-wrap.b-milestone-wrap.b-active .b-sch-event.b-sch-event-withicon {
    outline: 1px solid rgba(49, 131, 254, 0.6);
    outline-offset: 3px; }
  .b-sch-event-wrap.b-milestone-wrap.b-active .b-sch-event:not(.b-sch-event-withicon):before {
    outline: 1px solid rgba(49, 131, 254, 0.6);
    outline-offset: 3px; }
  .b-sch-event-wrap.b-milestone-wrap.b-measure {
    height: 1em;
    position: fixed;
    top: -10000px;
    left: -10000px;
    visibility: hidden; }
    .b-sch-event-wrap.b-milestone-wrap.b-measure label {
      font-weight: 400; }
    .b-ie .b-sch-event-wrap.b-milestone-wrap.b-measure {
      display: block; }
  .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap {
    margin: 0;
    min-width: 0;
    -ms-flex-direction: row;
        flex-direction: row; }
    .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap.b-active {
      outline: 1px solid rgba(49, 131, 254, 0.6);
      outline-offset: 3px; }
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap.b-active .b-sch-event.b-milestone,
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap.b-active .b-sch-event.b-milestone:before {
        outline: none; }
    .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone {
      font-size: inherit;
      width: inherit;
      min-width: 0;
      padding: 0;
      margin: 0 0.5em;
      -ms-flex-item-align: stretch;
          align-self: stretch;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      z-index: 1; }
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone label {
        position: static;
        padding: 0;
        color: #fff;
        -webkit-transform: none;
                transform: none;
        z-index: 1; }
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone:before {
        top: 50%;
        left: 0;
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(45deg); }
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone:after {
        background: inherit;
        position: absolute;
        top: 0;
        right: 0;
        content: ' ';
        height: 0.707107em;
        width: 0.707107em;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        -webkit-transform-origin: 100% 0;
                transform-origin: 100% 0;
        border: inherit;
        z-index: 0; }
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone:hover {
        background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15)));
        background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)); }
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone.b-sch-event-selected {
        background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.25)), to(rgba(0, 0, 0, 0.25)));
        background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)); }
        .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone.b-sch-event-selected:hover {
          background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.35)), to(rgba(0, 0, 0, 0.35)));
          background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)); }
    .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap.b-measure .b-sch-event.b-milestone:before, .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap.b-measure .b-sch-event.b-milestone:after {
      display: none; }
  .b-sch-event-wrap.b-milestone-wrap.b-sch-event-wrap label.b-sch-label-left {
    margin: 0 0 0 0.5em;
    -webkit-transform: translateX(calc(-100% - 1em)) translateY(0);
            transform: translateX(calc(-100% - 1em)) translateY(0); }
    .b-labels-topbottom .b-sch-event-wrap.b-milestone-wrap.b-sch-event-wrap label.b-sch-label-left {
      -webkit-transform: translateX(-100%) translateY(0);
              transform: translateX(-100%) translateY(0); }
  .b-sch-event-wrap.b-milestone-wrap.b-sch-event-wrap label.b-sch-label-right {
    margin: 0 0 0 -0.5em;
    -webkit-transform: translateX(calc(100% + 1em)) translateY(0);
            transform: translateX(calc(100% + 1em)) translateY(0); }
    .b-labels-topbottom .b-sch-event-wrap.b-milestone-wrap.b-sch-event-wrap label.b-sch-label-right {
      -webkit-transform: translateX(100%) translateY(0);
              transform: translateX(100%) translateY(0); }

.b-sch-event {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  -ms-flex: 1 1;
      flex: 1 1;
  width: 100%;
  text-align: left;
  padding-left: 0.75em;
  min-width: 1px;
  color: #fff;
  background-color: #27ca37;
  -webkit-transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s;
  transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s;
  border-color: #27ca37;
  position: relative; }
  .b-toggling-node .b-sch-event {
    -webkit-transition: none;
    transition: none; }
  .b-sch-event.b-sch-event-narrow {
    padding-left: 0; }
  .b-sch-event label {
    color: #888; }
  .b-sch-event:not(.b-milestone) .b-fa,
  .b-sch-event:not(.b-milestone) .b-icon {
    margin-right: .4em;
    line-height: inherit; }
  .b-sch-event:before {
    font-family: "Font Awesome 5 Pro"; }
  .b-sch-event.b-sch-event-resizing, .b-sch-event:hover {
    z-index: 6; }
  .b-sch-event.b-sch-event-selected,
  .b-sch-event.b-sch-event-selected * {
    font-weight: 500;
    z-index: 6; }
  .b-sch-event:not(.b-milestone).b-sch-event-selected,
  .b-sch-event:not(.b-milestone).b-sch-event-selected * {
    color: #fff; }
  .b-sch-event.b-sch-event-assign-selected {
    -webkit-animation-name: instance-selected;
            animation-name: instance-selected;
    -webkit-animation-duration: .2s;
            animation-duration: .2s;
    -webkit-animation-iteration-count: 3;
            animation-iteration-count: 3;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out; }

.b-sch-event-wrap:not(.b-milestone-wrap).b-active .b-sch-event {
  outline: 1px solid rgba(49, 131, 254, 0.6);
  outline-offset: 3px; }

.b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event.b-sch-event-selected {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.25)), to(rgba(0, 0, 0, 0.25)));
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)); }

.b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15)));
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)); }

.b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event.b-sch-event-selected:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.35)), to(rgba(0, 0, 0, 0.35)));
  background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)); }

.b-sch-event-resizing .b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event,
.b-dragproxy .b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event {
  background-color: #73e47e; }

@-webkit-keyframes instance-selected {
  0% {
    top: -2px; }
  50% {
    top: 2px; }
  100% {
    top: 0; } }

@keyframes instance-selected {
  0% {
    top: -2px; }
  50% {
    top: 2px; }
  100% {
    top: 0; } }

.b-initial-fade-in .b-first-render {
  opacity: 0;
  -webkit-animation-name: none-fade-in;
          animation-name: none-fade-in;
  -webkit-animation-name: initial-fade-in;
          animation-name: initial-fade-in;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes initial-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes initial-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.b-initial-zoom-in .b-first-render .b-sch-event {
  -webkit-animation-name: none-zoom-in;
          animation-name: none-zoom-in;
  -webkit-animation-name: initial-zoom-in;
          animation-name: initial-zoom-in;
  -webkit-animation-duration: .5s;
          animation-duration: .5s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

@-webkit-keyframes initial-zoom-in {
  0% {
    -webkit-transform: scale(0.01);
            transform: scale(0.01); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes initial-zoom-in {
  0% {
    -webkit-transform: scale(0.01);
            transform: scale(0.01); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.b-initial-slide-from-left .b-first-render {
  left: -100%;
  -webkit-animation-name: none-slide-from-left;
          animation-name: none-slide-from-left;
  -webkit-animation-name: initial-slide-from-left;
          animation-name: initial-slide-from-left;
  -webkit-animation-duration: .7s;
          animation-duration: .7s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes initial-slide-from-left {
  0% {
    left: -100%; }
  100% {
    left: 0; } }

@keyframes initial-slide-from-left {
  0% {
    left: -100%; }
  100% {
    left: 0; } }

.b-initial-slide-from-top .b-first-render {
  top: -100%;
  -webkit-animation-name: none-slide-from-top;
          animation-name: none-slide-from-top;
  -webkit-animation-name: initial-slide-from-top;
          animation-name: initial-slide-from-top;
  -webkit-animation-duration: .7s;
          animation-duration: .7s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes initial-slide-from-top {
  0% {
    top: -100%; }
  100% {
    top: 0; } }

@keyframes initial-slide-from-top {
  0% {
    top: -100%; }
  100% {
    top: 0; } }

.b-scheduler-event-parent:not(.b-milestone-wrap) .b-scheduler-event {
  overflow: visible;
  background-color: #8bc34a;
  border-color: #8bc34a;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-bottom: 8px;
  min-height: 4px; }
  .b-scheduler-event-parent:not(.b-milestone-wrap) .b-scheduler-event::before, .b-scheduler-event-parent:not(.b-milestone-wrap) .b-scheduler-event::after {
    content: '';
    position: absolute;
    bottom: -8px;
    border: 4px solid #8bc34a;
    -webkit-transition: border .2s;
    transition: border .2s; }
  .b-scheduler-event-parent:not(.b-milestone-wrap) .b-scheduler-event::before {
    left: 0;
    border-right-color: transparent;
    border-bottom-color: transparent; }
  .b-scheduler-event-parent:not(.b-milestone-wrap) .b-scheduler-event::after {
    right: 0;
    border-left-color: transparent;
    border-bottom-color: transparent; }

.b-scheduler:not(.b-scheduler-resizing-event-percent):not(.b-resizing-event):not(.b-dragging-event) .b-scheduler-event-parent:not(.b-milestone-wrap):hover .b-scheduler-event {
  background-color: #bcdc97; }

.b-sch-vertical .b-sch-event-wrap {
  contain: strict; }

.b-sch-vertical .b-sch-event {
  -ms-flex-align: start;
      align-items: flex-start;
  padding-top: .75em;
  padding-right: .75em;
  contain: strict;
  white-space: normal; }

.b-sch-vertical.b-eventlayout-mixed .b-sch-event {
  opacity: .8; }

.b-scheduler.b-animating .b-grid-row {
  -webkit-transition: height 0.2s, -webkit-transform 0.2s;
  transition: height 0.2s, -webkit-transform 0.2s;
  transition: height 0.2s, transform 0.2s;
  transition: height 0.2s, transform 0.2s, -webkit-transform 0.2s; }

.b-grid-footer-container .b-sch-timeaxiscolumn {
  padding: 0;
  /*flex    : none !important;*/ }

.b-sch-timeaxis-cell {
  padding: 0;
  -ms-flex: none !important;
      flex: none !important;
  -ms-flex-align: baseline;
      align-items: baseline;
  border-right: none;
  /* No focus outline in scheduler */ }
  .b-sch-timeaxis-cell.b-focused::after {
    content: none; }

.b-grid .b-timeline-subgrid .b-grid-row.b-selected:not(.b-group-row) {
  background-color: transparent; }

.b-grid .b-timeline-subgrid .b-grid-row.b-selected .b-grid-cell.b-sch-timeaxis-cell.b-selected {
  background-color: transparent; }

.b-grid.b-timelinebase .b-timeline-subgrid .b-grid-row.b-hover .b-grid-cell,
.b-grid.b-timelinebase .b-timeline-subgrid .b-grid-row.b-hover .b-grid-cell:hover {
  background-color: transparent; }

.b-stripe .b-timeline-subgrid .b-grid-row.b-selected.b-odd,
.b-stripe .b-timeline-subgrid .b-grid-row.b-odd,
.b-grid.b-stripe .b-timeline-subgrid .b-grid-row.b-selected.b-odd,
.b-grid.b-stripe .b-timeline-subgrid .b-grid-row.b-odd {
  background-color: rgba(250, 250, 250, 0.8); }

.b-stripe .b-timeline-subgrid .b-grid-row.b-selected.b-even,
.b-stripe .b-timeline-subgrid .b-grid-row.b-even,
.b-grid.b-stripe .b-timeline-subgrid .b-grid-row.b-selected.b-even,
.b-grid.b-stripe .b-timeline-subgrid .b-grid-row.b-even {
  background-color: rgba(255, 255, 255, 0.8); }

.b-stripe .b-timeline-subgrid .b-grid-row.b-selected .b-grid-cell.b-selected {
  background-color: transparent; }

.b-stripe .b-timeline-subgrid .b-grid-row.b-selected .b-grid-cell.b-selected {
  background-color: transparent; }

.b-grid .b-timeline-subgrid .b-group-row.b-selected,
.b-timeline-subgrid .b-group-row.b-selected,
.b-timeline-subgrid .b-group-row {
  z-index: 3;
  background-color: rgba(250, 251, 252, 0.8); }

.b-resource-info {
  -ms-flex: 1 1;
      flex: 1 1;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center; }
  .b-resource-info img {
    width: 2.4em;
    height: 2.4em;
    border-radius: 50%;
    margin-right: .6em; }
  .b-resource-info dt {
    font-weight: 400;
    margin-bottom: .2em; }
  .b-resource-info dd {
    margin: 0;
    font-size: .8em;
    color: #474849; }

.b-columnlines .b-grid-cell:not(:last-child) {
  border-right: 1px solid #e9eaeb; }

.b-columnlines .b-group-row .b-grid-cell {
  border-right-color: transparent; }

.b-column-line,
.b-column-line-major {
  height: 100%;
  position: absolute;
  border-left-width: 1px;
  border-left-style: solid; }

.b-column-line {
  border-color: #f2f2f3; }

.b-column-line-major {
  border-color: #ced1d3; }

.b-sch-vertical .b-column-line,
.b-sch-vertical .b-column-line-major {
  height: auto;
  width: 100%;
  position: absolute;
  border-left: none;
  border-top-width: 1px;
  border-top-style: solid; }

.b-sch-foreground-canvas > svg {
  overflow: visible;
  position: absolute;
  pointer-events: none;
  z-index: 3; }
  .b-sch-foreground-canvas > svg marker {
    fill: #bbb; }
    .b-sch-foreground-canvas > svg marker path {
      stroke: #bbb;
      fill: inherit;
      stroke-width: 1 !important; }
  .b-sch-foreground-canvas > svg polyline {
    pointer-events: visibleStroke;
    fill: transparent;
    marker-end: url("#arrowEnd");
    stroke: #bbb;
    stroke-width: 1;
    -webkit-transition: stroke-width 0.2s linear;
    transition: stroke-width 0.2s linear; }
    .b-sch-foreground-canvas > svg polyline.b-sch-bidirectional-line {
      marker-start: url("#arrowEnd"); }
    .b-sch-foreground-canvas > svg polyline.b-sch-dependency-over {
      stroke-width: 2;
      stroke-dasharray: 0; }
    .b-sch-foreground-canvas > svg polyline.b-sch-released {
      display: none; }

.b-edge .b-sch-foreground-canvas polyline.b-sch-bidirectional-line,
.b-ie .b-sch-foreground-canvas polyline.b-sch-bidirectional-line {
  marker-start: url("#arrowStart"); }

.b-edge .b-sch-foreground-canvas .b-no-start-marker,
.b-ie .b-sch-foreground-canvas .b-no-start-marker {
  marker-start: none; }

.b-edge .b-sch-foreground-canvas .b-no-end-marker,
.b-ie .b-sch-foreground-canvas .b-no-end-marker {
  marker-end: none; }

.b-dependencies .b-sch-event.b-sch-terminals-visible,
.b-dependencies .b-gantt-task.b-sch-terminals-visible {
  overflow: visible; }

.b-sch-terminal {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 50%;
  position: absolute;
  z-index: 6;
  cursor: pointer; }
  .b-sch-terminal.b-sch-terminal:hover {
    background-color: #e6e6e6; }
  .b-dragging .b-sch-terminal,
  .b-sch-event-resizing .b-sch-terminal {
    display: none; }

.b-creating-dependency .b-sch-terminal:hover {
  background-color: #e6e6e6; }
  .b-creating-dependency .b-sch-terminal:hover.b-valid {
    background-color: #9eeca5; }
  .b-creating-dependency .b-sch-terminal:hover.b-invalid {
    background-color: #f6bbb4; }

.b-sch-terminal-top {
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-80%);
          transform: translateX(-50%) translateY(-80%); }

.b-sch-terminal-bottom {
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(80%);
          transform: translateX(-50%) translateY(80%); }

.b-sch-terminal-left {
  left: 0;
  top: 50%;
  -webkit-transform: translateX(-80%) translateY(-50%);
          transform: translateX(-80%) translateY(-50%); }

.b-sch-terminal-right {
  right: 0;
  top: 50%;
  -webkit-transform: translateX(80%) translateY(-50%);
          transform: translateX(80%) translateY(-50%); }

.b-scheduler:not(.b-sch-layout-milestones) .b-milestone:not(.b-sch-event-withicon) .b-sch-terminal-left,
.b-gantt:not(.b-sch-layout-milestones) .b-milestone:not(.b-gantt-task-withicon) .b-sch-terminal-left {
  -webkit-transform: translate(-0.7em, -50%);
          transform: translate(-0.7em, -50%); }
  .b-labels-topbottom .b-scheduler:not(.b-sch-layout-milestones) .b-milestone:not(.b-sch-event-withicon) .b-sch-terminal-left, .b-labels-topbottom
  .b-gantt:not(.b-sch-layout-milestones) .b-milestone:not(.b-gantt-task-withicon) .b-sch-terminal-left {
    -webkit-transform: translate(-0.35em, -50%);
            transform: translate(-0.35em, -50%); }

.b-scheduler:not(.b-sch-layout-milestones) .b-milestone:not(.b-sch-event-withicon) .b-sch-terminal-right,
.b-gantt:not(.b-sch-layout-milestones) .b-milestone:not(.b-gantt-task-withicon) .b-sch-terminal-right {
  -webkit-transform: translate(0.7em, -50%);
          transform: translate(0.7em, -50%); }
  .b-labels-topbottom .b-scheduler:not(.b-sch-layout-milestones) .b-milestone:not(.b-sch-event-withicon) .b-sch-terminal-right, .b-labels-topbottom
  .b-gantt:not(.b-sch-layout-milestones) .b-milestone:not(.b-gantt-task-withicon) .b-sch-terminal-right {
    -webkit-transform: translate(0.35em, -50%);
            transform: translate(0.35em, -50%); }

.b-sch-dependency-connector {
  position: absolute;
  contain: strict;
  border-top: 2px dashed #bbb;
  z-index: 10;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  pointer-events: none; }
  .b-sch-dependency-connector.b-removing {
    -webkit-transition: width 0.2s ease-out;
    transition: width 0.2s ease-out; }
  .b-sch-dependency-connector.b-valid {
    border-color: #27ca37;
    border-top-style: solid; }

.b-sch-dependency-creation-tooltip .b-popup-header {
  padding: 0.5em 0.075em 0.5em 0.075em;
  background-color: #27ca37; }
  .b-sch-dependency-creation-tooltip .b-popup-header i {
    margin-bottom: .5em; }

.b-sch-dependency-creation-tooltip.b-invalid .b-popup-header {
  background-color: #e53f2c; }

.b-sch-dependency-creation-tooltip.b-checking .b-popup-header {
  background-color: #b0b0b7; }

.b-sch-dependency-creation-tooltip .b-panel-header,
.b-sch-dependency-tooltip .b-panel-header {
  color: #fff; }

.b-sch-dependency-creation-tooltip td:first-child,
.b-sch-dependency-tooltip td:first-child {
  padding-right: 5px;
  font-size: 12px; }

.b-sch-dependency-creation-tooltip .b-sch-box,
.b-sch-dependency-tooltip .b-sch-box {
  width: 12px;
  height: 8px;
  border: 1px solid #bbb;
  display: none;
  margin-left: 5px;
  position: relative;
  top: 2px; }
  .b-sch-dependency-creation-tooltip .b-sch-box.b-left, .b-sch-dependency-creation-tooltip .b-sch-box.b-right, .b-sch-dependency-creation-tooltip .b-sch-box.b-top, .b-sch-dependency-creation-tooltip .b-sch-box.b-bottom,
  .b-sch-dependency-tooltip .b-sch-box.b-left,
  .b-sch-dependency-tooltip .b-sch-box.b-right,
  .b-sch-dependency-tooltip .b-sch-box.b-top,
  .b-sch-dependency-tooltip .b-sch-box.b-bottom {
    display: block; }
  .b-sch-dependency-creation-tooltip .b-sch-box:after,
  .b-sch-dependency-tooltip .b-sch-box:after {
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #888;
    position: absolute; }
  .b-sch-dependency-creation-tooltip .b-sch-box.b-right:after,
  .b-sch-dependency-tooltip .b-sch-box.b-right:after {
    right: -2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .b-sch-dependency-creation-tooltip .b-sch-box.b-left:after,
  .b-sch-dependency-tooltip .b-sch-box.b-left:after {
    left: -2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .b-sch-dependency-creation-tooltip .b-sch-box.b-top:after,
  .b-sch-dependency-tooltip .b-sch-box.b-top:after {
    top: -2px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .b-sch-dependency-creation-tooltip .b-sch-box.b-bottom:after,
  .b-sch-dependency-tooltip .b-sch-box.b-bottom:after {
    bottom: -2px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }

.b-predecessor-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .b-predecessor-list > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    -ms-flex-item-align: stretch;
        align-self: stretch;
    width: auto; }
  .b-predecessor-list > .b-list-item .b-predecessor-item-text {
    -ms-flex: 1 1;
        flex: 1 1; }
  .b-predecessor-list > .b-list-item .b-sch-box {
    width: 1.2em;
    height: .9em;
    border: 1px solid #bbb;
    margin-left: .5em;
    position: relative; }
    .b-predecessor-list > .b-list-item .b-sch-box:after {
      content: '';
      width: .5em;
      height: .5em;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      border: 1px solid #bbb;
      display: none; }
  .b-predecessor-list > .b-list-item.b-selected .b-sch-box {
    background-color: #27ca37; }
  .b-predecessor-list > .b-list-item.b-selected.b-fs .b-sch-box.b-from:after {
    right: -.25em;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-fs .b-sch-box.b-to:after {
    left: -.25em;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-sf .b-sch-box.b-from:after {
    left: -.25em;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-sf .b-sch-box.b-to:after {
    right: -.25em;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-ss .b-sch-box.b-from:after {
    left: -.25em;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-ss .b-sch-box.b-to:after {
    left: -.25em;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-ff .b-sch-box.b-from:after {
    right: -.25em;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-ff .b-sch-box.b-to:after {
    right: -.25em;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block; }

.b-dependencyeditor.b-popup {
  width: 29em; }
  .b-dependencyeditor.b-popup .b-popup-content {
    padding: 1em 1em 0 1em; }
    .b-dependencyeditor.b-popup .b-popup-content .b-textfield label {
      -ms-flex: 1 0 4em;
          flex: 1 0 4em; }
  .b-dependencyeditor.b-popup .b-bottom-toolbar {
    padding: 1em 1em 0.4em 1em; }
    .b-dependencyeditor.b-popup .b-bottom-toolbar .b-label-filler {
      display: none; }
    .b-dependencyeditor.b-popup .b-bottom-toolbar > button {
      -ms-flex: 1 1;
          flex: 1 1;
      margin-right: .5em; }
      .b-dependencyeditor.b-popup .b-bottom-toolbar > button:last-child {
        margin-right: 0; }

.b-dragselect-rect {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  pointer-events: none;
  z-index: 100;
  border: 1px dashed #474849; }

.b-scheduler.b-dragging-event {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.b-sch-event-wrap.b-dragging {
  z-index: 7;
  pointer-events: none;
  opacity: 0.65; }
  .b-sch-event-wrap.b-dragging.b-drag-main {
    z-index: 10;
    opacity: 1; }

.b-drag-proxy .b-sch-event-wrap {
  opacity: 0.5; }

.b-sch-event-wrap.b-aborting {
  -webkit-transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, -webkit-transform 0.2s;
  transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, -webkit-transform 0.2s;
  transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, transform 0.2s;
  transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, transform 0.2s, -webkit-transform 0.2s; }

.b-sch-tip-message {
  margin-top: 0.5em; }
  .b-sch-tip-message:empty {
    display: none; }

.b-scheduler.b-dragcreating .b-sch-timeaxis-cell {
  pointer-events: none; }

.b-scheduler.b-dragcreating-proxy-sizing {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* prevent other events from reacting to hover during drag create */ }
  .b-scheduler.b-dragcreating-proxy-sizing .b-sch-event-wrap {
    pointer-events: none; }

.b-sch-dragcreator-proxy {
  background-color: rgba(94, 224, 117, 0.6);
  z-index: 7;
  position: absolute;
  pointer-events: all;
  font-size: 13px; }

.b-eventeditor.b-popup {
  width: 29em; }
  .b-eventeditor.b-popup .b-eventeditor-content.b-popup-content {
    padding: 1em 1em 0 1em; }
    .b-eventeditor.b-popup .b-eventeditor-content.b-popup-content .b-textfield label {
      -ms-flex: 1 0 6em;
          flex: 1 0 6em; }
  .b-eventeditor.b-popup .b-bottom-toolbar {
    padding: 1em 1em 0.4em 1em; }
    .b-eventeditor.b-popup .b-bottom-toolbar .b-label-filler {
      width: 6.6em; }
    .b-eventeditor.b-popup .b-bottom-toolbar > button {
      -ms-flex: 1 1;
          flex: 1 1;
      margin-right: .5em; }
      .b-eventeditor.b-popup .b-bottom-toolbar > button:last-child {
        margin-right: 0; }

.b-ie .b-eventfilter {
  -ms-flex: 0 1 200px;
      flex: 0 1 200px; }

.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-start:before, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-end:after, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:before, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:after,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-start:before,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-end:after,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:before,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:after {
  content: '';
  width: 4px;
  height: 50%;
  top: 25%;
  position: absolute;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  pointer-events: none; }

.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:before, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-start:before,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:before,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-start:before {
  margin-left: 3px;
  left: 0;
  border-width: 0 1px; }

.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:after, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-end:after, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:hover:after, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-end:hover:after,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:after,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-end:after,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:hover:after,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-end:hover:after {
  margin-right: 3px;
  right: 0;
  border-width: 0 1px; }

.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event.b-resize-handle {
  cursor: ew-resize; }

.b-eventresize:not(.b-sch-vertical):not(.b-readonly).b-resizing-event,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: ew-resize !important; }

.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-start:before, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-end:after, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:before, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:after,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-start:before,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-end:after,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:before,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:after {
  content: '';
  height: 4px;
  width: 2em;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  pointer-events: none; }

.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:before, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-start:before,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:before,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-start:before {
  margin-top: 3px;
  top: 0;
  border-width: 1px 0; }

.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:after, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-end:after, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:hover:after, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-end:hover:after,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:after,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-end:after,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:hover:after,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-end:hover:after {
  margin-bottom: 3px;
  bottom: 0;
  border-width: 1px 0; }

.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event.b-resize-handle {
  cursor: ns-resize; }

.b-eventresize.b-sch-vertical:not(.b-readonly).b-resizing-event,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: ns-resize !important; }

.b-sch-event-wrap.b-sch-event-wrap-resizing {
  z-index: 7; }

.b-scheduler.b-resizing-event .b-sch-timeaxis-cell {
  /* prevent drag create */
  pointer-events: none; }

.b-scheduler.b-resizing-event .b-sch-event-wrap {
  pointer-events: none; }

.b-sch-event-tooltip {
  max-width: inherit; }
  .b-sch-event-tooltip .b-tooltip-content {
    -ms-flex-direction: column;
        flex-direction: column; }
  .b-sch-event-tooltip .b-sch-clockwrap {
    margin-top: .5em; }
  .b-sch-event-tooltip .b-sch-clockwrap:first-child {
    margin-top: 0; }
  .b-sch-event-tooltip .b-sch-event-title {
    font-weight: 600; }

.b-timeaxis-group-summary {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  width: 100%;
  height: 100%; }
  .b-timeaxis-group-summary .b-timeaxis-tick {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: stretch;
        align-items: stretch;
    -ms-flex-pack: center;
        justify-content: center; }
  .b-timeaxis-group-summary .b-timeaxis-summary-value {
    text-align: center; }

.b-grid.b-scheduler .b-timeline-subgrid .b-grid-row.b-group-footer.b-hover .b-grid-cell,
.b-grid.b-scheduler .b-timeline-subgrid .b-grid-row.b-group-footer.b-hover .b-grid-cell:hover {
  background-color: #fffbf5; }

.b-headerzoom-rect {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  pointer-events: none;
  z-index: 100;
  background: rgba(152, 193, 255, 0.3); }

.b-ie .b-sch-event-wrap .b-sch-label-right, .b-ie .b-gantt-task-wrap .b-sch-label-right {
  right: -5px;
  top: 50%;
  -webkit-transform: translate3d(100%, -50%, 0);
          transform: translate3d(100%, -50%, 0); }

.b-ie .b-sch-event-wrap .b-sch-label-left, .b-ie .b-gantt-task-wrap .b-sch-label-left {
  left: -5px;
  top: 50%;
  -webkit-transform: translate3d(-100%, -50%, 0);
          transform: translate3d(-100%, -50%, 0); }

.b-sch-event-wrap .b-sch-label, .b-gantt-task-wrap .b-sch-label {
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  margin: .2em 0;
  color: #888;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: all; }
  .b-sch-event-wrap .b-sch-label.b-sch-label-top, .b-gantt-task-wrap .b-sch-label.b-sch-label-top {
    -ms-flex-order: -2;
        order: -2; }
  .b-sch-event-wrap .b-sch-label.b-sch-label-left, .b-gantt-task-wrap .b-sch-label.b-sch-label-left {
    -ms-flex-order: -1;
        order: -1; }

.b-sch-event-wrap .b-sch-label-left, .b-gantt-task-wrap .b-sch-label-left {
  position: absolute;
  -ms-flex-item-align: self-start;
      align-self: self-start;
  -webkit-transform: translate3d(calc(-100% - 2em), 0, 0);
          transform: translate3d(calc(-100% - 2em), 0, 0);
  margin: 0; }

.b-sch-event-wrap .b-sch-label-right, .b-gantt-task-wrap .b-sch-label-right {
  position: absolute;
  -ms-flex-item-align: end;
      align-self: flex-end;
  -webkit-transform: translate3d(calc(100% + 5px), 0, 0);
          transform: translate3d(calc(100% + 5px), 0, 0);
  margin: 0; }

.b-show-baselines .b-sch-event-wrap.b-has-baselines .b-sch-label-left, .b-show-baselines .b-sch-event-wrap.b-has-baselines .b-sch-label-right, .b-show-baselines .b-gantt-task-wrap.b-has-baselines .b-sch-label-left, .b-show-baselines .b-gantt-task-wrap.b-has-baselines .b-sch-label-right {
  top: 0;
  line-height: 85%; }

.b-pan .b-timeline-subgrid {
  cursor: move; }

.b-sch-resourcetimerange {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  background-color: rgba(255, 235, 59, 0.1);
  color: #ffe60c;
  z-index: 2;
  -webkit-transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s;
  transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s;
  font-size: 12px;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: end;
      align-items: flex-end;
  padding: 3px; }
  .b-animating .b-sch-resourcetimerange {
    -webkit-transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, width 0.2s, height 0.2s, font-size 0.2s, -webkit-transform 0.2s;
    transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, width 0.2s, height 0.2s, font-size 0.2s, -webkit-transform 0.2s;
    transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, transform 0.2s, width 0.2s, height 0.2s, font-size 0.2s;
    transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, transform 0.2s, width 0.2s, height 0.2s, font-size 0.2s, -webkit-transform 0.2s; }
  .b-sch-resourcetimerange.b-sch-released {
    display: none;
    -webkit-transition: none;
    transition: none; }

.b-sch-vertical .b-sch-resourcetimerange {
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: start;
      align-items: flex-start; }

.b-sch-color-red.b-sch-resourcetimerange {
  background-color: rgba(229, 63, 44, 0.1);
  color: #d02b18; }

.b-sch-color-pink.b-sch-resourcetimerange {
  background-color: rgba(254, 49, 131, 0.1);
  color: #fe0468; }

.b-sch-color-purple.b-sch-resourcetimerange {
  background-color: rgba(254, 49, 234, 0.1);
  color: #fe04e5; }

.b-sch-color-violet.b-sch-resourcetimerange {
  background-color: rgba(172, 49, 254, 0.1);
  color: #9a04fe; }

.b-sch-color-indigo.b-sch-resourcetimerange {
  background-color: rgba(69, 49, 254, 0.1);
  color: #1c04fe; }

.b-sch-color-blue.b-sch-resourcetimerange {
  background-color: rgba(49, 131, 254, 0.1);
  color: #0468fe; }

.b-sch-color-cyan.b-sch-resourcetimerange {
  background-color: rgba(49, 233, 254, 0.1);
  color: #04e4fe; }

.b-sch-color-teal.b-sch-resourcetimerange {
  background-color: rgba(49, 254, 172, 0.1);
  color: #04fe9a; }

.b-sch-color-green.b-sch-resourcetimerange {
  background-color: rgba(39, 202, 55, 0.1);
  color: #1fad2d; }

.b-sch-color-lime.b-sch-resourcetimerange {
  background-color: rgba(118, 230, 44, 0.1);
  color: #61d117; }

.b-sch-color-yellow.b-sch-resourcetimerange {
  background-color: rgba(255, 235, 59, 0.1);
  color: #ffe60c; }

.b-sch-color-orange.b-sch-resourcetimerange {
  background-color: rgba(254, 172, 49, 0.1);
  color: #fe9a04; }

.b-simpleeventeditor .b-field {
  -ms-flex-align: stretch;
      align-items: stretch; }

.b-grid-footer.b-sch-timeaxiscolumn {
  padding: 0;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: start;
      justify-content: start;
  -ms-flex-positive: unset !important;
      flex-grow: unset !important; }
  .b-grid-footer.b-sch-timeaxiscolumn .b-timeaxis-tick {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    text-align: center;
    overflow: hidden;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: stretch;
        align-items: stretch;
    padding: .5em 0; }
  .b-grid-footer.b-sch-timeaxiscolumn .b-timeaxis-tick:not(:last-child) {
    border-right: 1px solid #f2f3f3; }

.b-timeaxis-summary-tip {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .b-timeaxis-summary-tip header {
    width: 100%;
    text-align: center;
    font-weight: 700;
    margin-bottom: 1em; }
  .b-timeaxis-summary-tip label {
    -ms-flex: 1 1 50%;
        flex: 1 1 50%; }
  .b-timeaxis-summary-tip .b-timeaxis-summary-value {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto; }

.b-ie .b-grid-footer.b-sch-timeaxiscolumn .b-timeaxis-tick {
  -ms-flex-negative: 0;
      flex-shrink: 0; }

.b-float-root > .b-floating.b-interaction-tooltip {
  pointer-events: none; }

.b-sch-timerange {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  position: absolute;
  left: 0;
  overflow: hidden;
  -ms-flex-pack: center;
      justify-content: center;
  color: #aaa;
  z-index: 2;
  cursor: pointer; }
  .b-sch-timerange.b-over-resize-handle {
    cursor: ew-resize; }
  .b-sch-timerange:hover {
    z-index: 3; }
  .b-sch-timerange label {
    font-size: .8em;
    color: inherit;
    white-space: nowrap;
    cursor: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0.3em; }

.b-sch-current-time {
  pointer-events: none; }

.b-animating .b-sch-timerange {
  -webkit-transition: width 0.3s, -webkit-transform 0.3s;
  transition: width 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, width 0.3s;
  transition: transform 0.3s, width 0.3s, -webkit-transform 0.3s; }

.b-timeline-subgrid .b-sch-timerange {
  height: 100%;
  -ms-flex-align: start;
      align-items: start;
  min-width: 1px;
  color: #aaa;
  font-size: 14px; }
  .b-timeline-subgrid .b-sch-timerange:not(.b-sch-line) label.b-vertical {
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr; }
  .b-timeline-subgrid .b-sch-timerange.b-sch-line:before {
    display: none; }

.b-timeline-subgrid .b-sch-range {
  background-color: rgba(240, 240, 240, 0.5);
  pointer-events: none; }

.b-timeline-subgrid .b-sch-line {
  border-left: 2px dotted #fab005;
  color: #fff;
  padding: 0; }
  .b-timeline-subgrid .b-sch-line label {
    background-color: #fab005;
    padding: 0.3em;
    white-space: nowrap;
    margin-top: 0.5em; }

.b-sch-vertical .b-sch-timerange {
  left: auto;
  top: 0; }
  .b-sch-vertical .b-sch-timerange label {
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .b-sch-vertical .b-sch-timerange.b-over-resize-handle {
    cursor: ns-resize; }

.b-sch-vertical .b-timeline-subgrid .b-sch-timerange {
  height: auto;
  width: 100%; }

.b-sch-vertical .b-timeline-subgrid .b-sch-line {
  border-top: 2px dotted #fab005;
  color: #fff;
  padding: 0; }
  .b-sch-vertical .b-timeline-subgrid .b-sch-line label {
    background-color: #fab005;
    padding: 0.3em;
    white-space: nowrap;
    margin-left: 0.5em; }

.b-grid-headers .b-sch-timerange {
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  bottom: 0;
  background-color: #98c1ff;
  color: #fff;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .b-grid-headers .b-sch-timerange.b-sch-line {
    padding: 0 .5em; }
  .b-grid-headers .b-sch-timerange:before {
    margin-right: 0.3em; }
  .b-grid-headers .b-sch-timerange label {
    padding: 0; }

.b-grid-headers.b-sch-timeaxiscolumn-levels-1 .b-sch-timerange {
  height: 100%; }

.b-grid-headers.b-sch-timeaxiscolumn-levels-2 .b-sch-timerange {
  height: 50%; }

.b-grid-headers.b-sch-timeaxiscolumn-levels-3 .b-sch-timerange {
  height: 33.33333%; }

.b-grid-headers .b-sch-line {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px; }
  .b-grid-headers .b-sch-line.b-sch-timerange-with-headerlabel label {
    left: -2px; }

.b-verticaltimeaxiscolumn .b-sch-timerange {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  right: 0;
  background-color: #98c1ff;
  color: #fff;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .b-verticaltimeaxiscolumn .b-sch-timerange:before {
    margin-bottom: 0.5em; }

.b-verticaltimeaxiscolumn.b-sch-timeaxiscolumn-levels-1 .b-sch-timerange {
  width: 100%; }

.b-verticaltimeaxiscolumn.b-sch-timeaxiscolumn-levels-2 .b-sch-timerange {
  width: 50%; }

.b-verticaltimeaxiscolumn.b-sch-timeaxiscolumn-levels-3 .b-sch-timerange {
  width: 33.33333%; }

.b-verticaltimeaxiscolumn .b-sch-line {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }
  .b-verticaltimeaxiscolumn .b-sch-line.b-sch-timerange-with-headerlabel label {
    top: -2px; }

.b-sch-timeranges-with-headerelements .b-timeline-subgrid .b-sch-range:before {
  display: none; }

.b-grid-headers .b-sch-nonworkingtime {
  background-color: rgba(240, 213, 213, 0.3);
  z-index: 1;
  pointer-events: none; }

.b-sch-clockwrap {
  display: -ms-flexbox;
  display: flex; }
  .b-sch-clockwrap:not(:first-child) {
    margin-top: .3em; }

.b-sch-clock {
  position: relative;
  height: 21px;
  width: 21px;
  min-width: 21px;
  white-space: nowrap; }
  .b-sch-clock .b-sch-hour-indicator {
    border-top: 2px solid transparent;
    border-bottom: 6px solid #777; }
  .b-sch-clock .b-sch-minute-indicator {
    border-top: 8px solid #777; }
  .b-sch-clock .b-sch-minute-indicator,
  .b-sch-clock .b-sch-hour-indicator {
    position: absolute;
    left: 50%;
    top: 1px;
    width: 2px;
    height: 8px;
    overflow: hidden;
    margin-left: -1px;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%; }

.b-sch-clock-hour .b-sch-clock {
  border: 2px solid #feac31;
  background-color: #f8f8f8;
  border-radius: 100%; }

.b-sch-clock-hour .b-sch-clock-dot {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 4px;
  height: 4px;
  margin-left: -2px;
  margin-top: -2px;
  background: #777;
  border-radius: 3px;
  z-index: 2; }

.b-sch-clock-day .b-sch-clock {
  background-color: #f8f8f8;
  border: none;
  border-radius: 2px; }
  .b-sch-clock-day .b-sch-clock .b-sch-hour-indicator {
    width: inherit;
    position: static !important;
    background-color: #feac31;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-size: 7px;
    line-height: 8px;
    text-align: center;
    color: #fff;
    height: 9px;
    text-indent: 0;
    margin-top: -1px;
    margin-left: 0;
    border: 0 none;
    -webkit-transform: none !important;
            transform: none !important; }
  .b-sch-clock-day .b-sch-clock .b-sch-minute-indicator {
    width: inherit;
    color: #555;
    position: static !important;
    height: 16px;
    background-color: transparent;
    font-size: 10px;
    text-align: center;
    text-indent: 0;
    line-height: 12px;
    border: none;
    margin-left: 0;
    -webkit-transform: none !important;
            transform: none !important; }

.b-sch-clock-day .b-sch-clock-dot {
  display: none; }

.b-sch-clock-text {
  margin-left: 8px;
  padding-top: 1px;
  white-space: nowrap;
  width: 100%; }

/*.b-rtl .b-sch-clock-text {
    margin-left  : auto;
    margin-right : 25px;
}*/
.b-sch-column.b-grid-header {
  padding: 0; }

.b-grid-header-container .b-sch-timeaxiscolumn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0;
  -ms-flex-positive: unset !important;
      flex-grow: unset !important;
  background-color: #f3f4f5;
  border-right: 0 none; }
  .b-grid-header-container .b-sch-timeaxiscolumn.b-depth-0:hover {
    background-color: #f3f4f5 !important; }
  .b-grid-header-container .b-sch-timeaxiscolumn .b-sch-header-timeaxis-cell:hover {
    background-color: white; }

.b-sch-header-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex: 1 1;
      flex: 1 1; }
  .b-sch-header-row:last-child .b-sch-header-timeaxis-cell {
    border-bottom: none; }
  .b-ie .b-sch-header-row {
    min-height: 2em; }

.b-sch-header-timeaxis-cell {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  color: #667280;
  border-bottom: 1px solid #d8d9da;
  border-right: 1px solid #d8d9da;
  overflow: hidden;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  font-size: 0.9em;
  font-weight: 500;
  padding: 0.5em 0 0.5em 0;
  white-space: nowrap;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .b-sch-header-timeaxis-cell:hover {
    cursor: pointer;
    background-color: white; }
  .b-sch-header-timeaxis-cell.b-align-start, .b-sch-header-timeaxis-cell.b-align-end {
    padding-left: 0.3em;
    padding-right: 0.3em; }
  .b-sch-header-timeaxis-cell.b-align-start {
    -ms-flex-pack: start;
        justify-content: flex-start; }
  .b-sch-header-timeaxis-cell.b-align-end {
    -ms-flex-pack: end;
        justify-content: flex-end; }

.b-scheduler.b-fill-last-column .b-sch-timeaxiscolumn:last-child {
  border-right-color: #d8d9da; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .b-sch-timeaxiscolumn {
    height: 100%; } }

.b-verticaltimeaxiscolumn {
  padding: 0;
  border-right: none;
  background-color: #fbfcfc !important; }
  .b-verticaltimeaxiscolumn.b-grid-cell.b-focused:after {
    display: none; }

.b-verticaltimeaxis {
  -ms-flex: 1 1;
      flex: 1 1;
  contain: strict; }
  .b-verticaltimeaxis .b-sch-header-row {
    -ms-flex-direction: column;
        flex-direction: column;
    position: relative;
    contain: strict; }
  .b-verticaltimeaxis .b-sch-header-timeaxis-cell {
    position: absolute;
    width: 100%;
    text-transform: uppercase;
    contain: strict;
    -ms-flex-align: start;
        align-items: flex-start;
    padding-top: .5em; }
  .b-verticaltimeaxis .b-sch-header-row:not(.b-lowest) {
    -ms-flex: unset;
        flex: unset;
    width: 2em; }
    .b-verticaltimeaxis .b-sch-header-row:not(.b-lowest) .b-sch-header-text {
      -webkit-writing-mode: vertical-lr;
              writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .b-verticaltimeaxis .b-lowest .b-sch-header-timeaxis-cell {
    border-right: none;
    padding: .5em .5em 0 .5em; }
  .b-verticaltimeaxis .b-last {
    border-bottom: none; }
  .b-verticaltimeaxis .b-sch-header-timeaxis-cell:hover {
    background-color: #f3f4f5; }

.b-sch-vertical .b-sch-timeaxiscolumn {
  -ms-flex-direction: row;
      flex-direction: row; }

.b-resourceheader {
  -ms-flex: 1 1;
      flex: 1 1;
  position: relative;
  contain: strict; }
  .b-resourceheader.b-has-images {
    height: 3em; }
  .b-resourceheader.b-animating .b-resourceheader-cell {
    -webkit-transition: width .2s, left .2s;
    transition: width .2s, left .2s; }

.b-resourceheader-cell {
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  color: #667280;
  border-right: 1px solid #d8d9da;
  overflow: hidden;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  font-size: 0.9em;
  font-weight: 500;
  padding: 0.5em;
  white-space: nowrap;
  contain: strict;
  height: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .b-resourceheader-cell:hover {
    cursor: pointer;
    background-color: white; }
  .b-resourceheader-cell.b-align-start, .b-resourceheader-cell.b-align-end {
    padding-left: 0.3em;
    padding-right: 0.3em; }
  .b-resourceheader-cell.b-align-start {
    -ms-flex-pack: start;
        justify-content: flex-start; }
  .b-resourceheader-cell.b-align-end {
    -ms-flex-pack: end;
        justify-content: flex-end; }
  .b-resourceheader-cell i {
    margin-right: .5em; }
  .b-resourceheader-cell .b-resource-name {
    overflow: hidden; }
  .b-resourceheader-cell .b-resource-image {
    position: relative;
    min-width: 2em;
    min-height: 2em;
    width: 2em;
    height: 2em;
    margin-right: 1em;
    border-radius: 100%; }

.b-sch-red {
  background-color: #e53f2c; }

.b-sch-pink {
  background-color: #fe3183; }

.b-sch-purple {
  background-color: #fe31ea; }

.b-sch-violet {
  background-color: #ac31fe; }

.b-sch-indigo {
  background-color: #4531fe; }

.b-sch-blue {
  background-color: #3183fe; }

.b-sch-cyan {
  background-color: #31e9fe; }

.b-sch-teal {
  background-color: #31feac; }

.b-sch-green {
  background-color: #27ca37; }

.b-sch-lime {
  background-color: #76e62c; }

.b-sch-yellow {
  background-color: #FFEB3B; }

.b-sch-orange {
  background-color: #feac31; }

.b-sch-gray {
  background-color: #a0a0a0; }

.b-sch-style-plain .b-sch-event:not(.b-milestone) {
  background-color: #27ca37;
  border-color: #27ca37;
  color: #fff;
  font-weight: 500; }
  .b-sch-style-plain .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #10801b;
    background-image: none; }
  .b-sch-style-plain .b-sch-event:not(.b-milestone).b-sch-event-selected {
    font-weight: 500; }
  .b-sch-style-plain .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #0d7817;
    background-image: none; }

.b-sch-style-plain .b-sch-event.b-milestone:before {
  background-color: #777; }

.b-sch-style-plain.b-sch-color-red .b-sch-event:not(.b-milestone) {
  background-color: #e53f2c;
  border-color: #e53f2c; }
  .b-sch-style-plain.b-sch-color-red .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-red .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #971b0d; }
  .b-sch-style-plain.b-sch-color-red .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-red .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #8c170a; }

.b-sch-style-plain.b-sch-color-red .b-sch-event.b-milestone:before {
  background-color: #e53f2c;
  border-color: #e53f2c; }

.b-sch-style-plain.b-sch-color-red .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-red .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #971b0d; }

.b-sch-style-plain.b-sch-color-red .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-red .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #8c170a; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-red.b-milestone-wrap .b-sch-event {
  background: #e53f2c; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-red.b-milestone-wrap:hover .b-sch-event {
  background: #971b0d; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-red.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #8c170a; }

.b-sch-style-plain.b-sch-color-pink .b-sch-event:not(.b-milestone) {
  background-color: #fe3183;
  border-color: #fe3183; }
  .b-sch-style-plain.b-sch-color-pink .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-pink .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #b50149; }
  .b-sch-style-plain.b-sch-color-pink .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-pink .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #a60043; }

.b-sch-style-plain.b-sch-color-pink .b-sch-event.b-milestone:before {
  background-color: #fe3183;
  border-color: #fe3183; }

.b-sch-style-plain.b-sch-color-pink .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-pink .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #b50149; }

.b-sch-style-plain.b-sch-color-pink .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-pink .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #a60043; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-pink.b-milestone-wrap .b-sch-event {
  background: #fe3183; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-pink.b-milestone-wrap:hover .b-sch-event {
  background: #b50149; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-pink.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #a60043; }

.b-sch-style-plain.b-sch-color-purple .b-sch-event:not(.b-milestone) {
  background-color: #fe31ea;
  border-color: #fe31ea; }
  .b-sch-style-plain.b-sch-color-purple .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-purple .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #b501a4; }
  .b-sch-style-plain.b-sch-color-purple .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-purple .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #a60096; }

.b-sch-style-plain.b-sch-color-purple .b-sch-event.b-milestone:before {
  background-color: #fe31ea;
  border-color: #fe31ea; }

.b-sch-style-plain.b-sch-color-purple .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-purple .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #b501a4; }

.b-sch-style-plain.b-sch-color-purple .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-purple .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #a60096; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-purple.b-milestone-wrap .b-sch-event {
  background: #fe31ea; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-purple.b-milestone-wrap:hover .b-sch-event {
  background: #b501a4; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-purple.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #a60096; }

.b-sch-style-plain.b-sch-color-violet .b-sch-event:not(.b-milestone) {
  background-color: #ac31fe;
  border-color: #ac31fe; }
  .b-sch-style-plain.b-sch-color-violet .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-violet .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #6d01b5; }
  .b-sch-style-plain.b-sch-color-violet .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-violet .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #6400a6; }

.b-sch-style-plain.b-sch-color-violet .b-sch-event.b-milestone:before {
  background-color: #ac31fe;
  border-color: #ac31fe; }

.b-sch-style-plain.b-sch-color-violet .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-violet .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #6d01b5; }

.b-sch-style-plain.b-sch-color-violet .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-violet .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #6400a6; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-violet.b-milestone-wrap .b-sch-event {
  background: #ac31fe; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-violet.b-milestone-wrap:hover .b-sch-event {
  background: #6d01b5; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-violet.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #6400a6; }

.b-sch-style-plain.b-sch-color-indigo .b-sch-event:not(.b-milestone) {
  background-color: #4531fe;
  border-color: #4531fe; }
  .b-sch-style-plain.b-sch-color-indigo .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-indigo .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #1201b5; }
  .b-sch-style-plain.b-sch-color-indigo .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-indigo .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #1100a6; }

.b-sch-style-plain.b-sch-color-indigo .b-sch-event.b-milestone:before {
  background-color: #4531fe;
  border-color: #4531fe; }

.b-sch-style-plain.b-sch-color-indigo .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-indigo .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #1201b5; }

.b-sch-style-plain.b-sch-color-indigo .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-indigo .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #1100a6; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-indigo.b-milestone-wrap .b-sch-event {
  background: #4531fe; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-indigo.b-milestone-wrap:hover .b-sch-event {
  background: #1201b5; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-indigo.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #1100a6; }

.b-sch-style-plain.b-sch-color-blue .b-sch-event:not(.b-milestone) {
  background-color: #3183fe;
  border-color: #3183fe; }
  .b-sch-style-plain.b-sch-color-blue .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-blue .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #0149b5; }
  .b-sch-style-plain.b-sch-color-blue .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-blue .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #0043a6; }

.b-sch-style-plain.b-sch-color-blue .b-sch-event.b-milestone:before {
  background-color: #3183fe;
  border-color: #3183fe; }

.b-sch-style-plain.b-sch-color-blue .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-blue .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #0149b5; }

.b-sch-style-plain.b-sch-color-blue .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-blue .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #0043a6; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-blue.b-milestone-wrap .b-sch-event {
  background: #3183fe; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-blue.b-milestone-wrap:hover .b-sch-event {
  background: #0149b5; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-blue.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #0043a6; }

.b-sch-style-plain.b-sch-color-cyan .b-sch-event:not(.b-milestone) {
  background-color: #31e9fe;
  border-color: #31e9fe; }
  .b-sch-style-plain.b-sch-color-cyan .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-cyan .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #01a3b5; }
  .b-sch-style-plain.b-sch-color-cyan .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-cyan .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #0095a6; }

.b-sch-style-plain.b-sch-color-cyan .b-sch-event.b-milestone:before {
  background-color: #31e9fe;
  border-color: #31e9fe; }

.b-sch-style-plain.b-sch-color-cyan .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-cyan .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #01a3b5; }

.b-sch-style-plain.b-sch-color-cyan .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-cyan .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #0095a6; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-cyan.b-milestone-wrap .b-sch-event {
  background: #31e9fe; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-cyan.b-milestone-wrap:hover .b-sch-event {
  background: #01a3b5; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-cyan.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #0095a6; }

.b-sch-style-plain.b-sch-color-teal .b-sch-event:not(.b-milestone) {
  background-color: #31feac;
  border-color: #31feac; }
  .b-sch-style-plain.b-sch-color-teal .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-teal .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #01b56d; }
  .b-sch-style-plain.b-sch-color-teal .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-teal .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #00a664; }

.b-sch-style-plain.b-sch-color-teal .b-sch-event.b-milestone:before {
  background-color: #31feac;
  border-color: #31feac; }

.b-sch-style-plain.b-sch-color-teal .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-teal .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #01b56d; }

.b-sch-style-plain.b-sch-color-teal .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-teal .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #00a664; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-teal.b-milestone-wrap .b-sch-event {
  background: #31feac; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-teal.b-milestone-wrap:hover .b-sch-event {
  background: #01b56d; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-teal.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #00a664; }

.b-sch-style-plain.b-sch-color-green .b-sch-event:not(.b-milestone) {
  background-color: #27ca37;
  border-color: #27ca37; }
  .b-sch-style-plain.b-sch-color-green .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-green .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #10801b; }
  .b-sch-style-plain.b-sch-color-green .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-green .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #0d7817; }

.b-sch-style-plain.b-sch-color-green .b-sch-event.b-milestone:before {
  background-color: #27ca37;
  border-color: #27ca37; }

.b-sch-style-plain.b-sch-color-green .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-green .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #10801b; }

.b-sch-style-plain.b-sch-color-green .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-green .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #0d7817; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-green.b-milestone-wrap .b-sch-event {
  background: #27ca37; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-green.b-milestone-wrap:hover .b-sch-event {
  background: #10801b; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-green.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #0d7817; }

.b-sch-style-plain.b-sch-color-lime .b-sch-event:not(.b-milestone) {
  background-color: #76e62c;
  border-color: #76e62c; }
  .b-sch-style-plain.b-sch-color-lime .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-lime .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #44980c; }
  .b-sch-style-plain.b-sch-color-lime .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-lime .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #3e8d0a; }

.b-sch-style-plain.b-sch-color-lime .b-sch-event.b-milestone:before {
  background-color: #76e62c;
  border-color: #76e62c; }

.b-sch-style-plain.b-sch-color-lime .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-lime .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #44980c; }

.b-sch-style-plain.b-sch-color-lime .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-lime .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #3e8d0a; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-lime.b-milestone-wrap .b-sch-event {
  background: #76e62c; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-lime.b-milestone-wrap:hover .b-sch-event {
  background: #44980c; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-lime.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #3e8d0a; }

.b-sch-style-plain.b-sch-color-yellow .b-sch-event:not(.b-milestone) {
  background-color: #FFEB3B;
  border-color: #FFEB3B; }
  .b-sch-style-plain.b-sch-color-yellow .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-yellow .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #bca900; }
  .b-sch-style-plain.b-sch-color-yellow .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-yellow .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #ad9b00; }

.b-sch-style-plain.b-sch-color-yellow .b-sch-event.b-milestone:before {
  background-color: #FFEB3B;
  border-color: #FFEB3B; }

.b-sch-style-plain.b-sch-color-yellow .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-yellow .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #bca900; }

.b-sch-style-plain.b-sch-color-yellow .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-yellow .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #ad9b00; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-yellow.b-milestone-wrap .b-sch-event {
  background: #FFEB3B; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-yellow.b-milestone-wrap:hover .b-sch-event {
  background: #bca900; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-yellow.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #ad9b00; }

.b-sch-style-plain.b-sch-color-orange .b-sch-event:not(.b-milestone) {
  background-color: #feac31;
  border-color: #feac31; }
  .b-sch-style-plain.b-sch-color-orange .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-orange .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #b56d01; }
  .b-sch-style-plain.b-sch-color-orange .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-orange .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #a66400; }

.b-sch-style-plain.b-sch-color-orange .b-sch-event.b-milestone:before {
  background-color: #feac31;
  border-color: #feac31; }

.b-sch-style-plain.b-sch-color-orange .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-orange .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #b56d01; }

.b-sch-style-plain.b-sch-color-orange .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-orange .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #a66400; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-orange.b-milestone-wrap .b-sch-event {
  background: #feac31; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-orange.b-milestone-wrap:hover .b-sch-event {
  background: #b56d01; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-orange.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #a66400; }

.b-sch-style-plain.b-sch-color-gray .b-sch-event:not(.b-milestone) {
  background-color: #a0a0a0;
  border-color: #a0a0a0; }
  .b-sch-style-plain.b-sch-color-gray .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-gray .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #606060; }
  .b-sch-style-plain.b-sch-color-gray .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-gray .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #614f4f; }

.b-sch-style-plain.b-sch-color-gray .b-sch-event.b-milestone:before {
  background-color: #a0a0a0;
  border-color: #a0a0a0; }

.b-sch-style-plain.b-sch-color-gray .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-gray .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #606060; }

.b-sch-style-plain.b-sch-color-gray .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-gray .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #614f4f; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-gray.b-milestone-wrap .b-sch-event {
  background: #a0a0a0; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-gray.b-milestone-wrap:hover .b-sch-event {
  background: #606060; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-gray.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #614f4f; }

.b-sch-style-border .b-sch-event {
  background-color: #27ca37;
  border-color: #1b8d27;
  border-style: solid;
  border-width: 0;
  font-weight: 600;
  opacity: .8;
  border-radius: 3px; }
  .b-sch-style-border .b-sch-event:not(.b-milestone) {
    color: #fff;
    font-size: 11px;
    border-width: 1px; }
    .b-sch-style-border .b-sch-event:not(.b-milestone).b-sch-event-startsoutside {
      border-left: none; }
    .b-sch-style-border .b-sch-event:not(.b-milestone):hover, .b-sch-style-border .b-sch-event:not(.b-milestone).b-sch-event-resizing {
      background-color: #1b8d27;
      background-image: none;
      opacity: 1; }
    .b-sch-style-border .b-sch-event:not(.b-milestone).b-sch-event-selected {
      font-weight: 600; }
    .b-sch-style-border .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
      background-color: #198324;
      background-image: none; }
  .b-sch-style-border .b-sch-event.b-milestone {
    background: none; }
    .b-sch-style-border .b-sch-event.b-milestone:before {
      border-width: 1px;
      border-radius: 3px;
      opacity: .8; }
    .b-sch-style-border .b-sch-event.b-milestone:hover, .b-sch-style-border .b-sch-event.b-milestone.b-sch-event-selected {
      opacity: 1; }
      .b-sch-style-border .b-sch-event.b-milestone:hover:before, .b-sch-style-border .b-sch-event.b-milestone.b-sch-event-selected:before {
        opacity: 1; }

.b-sch-style-border.b-sch-color-red .b-sch-event {
  border-color: #aa2415; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-red .b-sch-event {
  background-color: #e53f2c; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-red .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected {
    background-color: #aa2415; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected:hover {
    background-color: #9e2213; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-red.b-dragging .b-sch-event {
  background-color: #9e2213; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-red .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #e53f2c; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-red .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-red .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #aa2415; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-red .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #9e2213; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap .b-sch-event {
  background: #e53f2c;
  border-top: 1px solid #aa2415;
  border-bottom: 1px solid #aa2415; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap .b-sch-event :before {
    border-color: #aa2415;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap .b-sch-event :after {
    border-color: #aa2415;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #aa2415; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #9e2213; }

.b-sch-style-border.b-sch-color-pink .b-sch-event {
  border-color: #d30155; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event {
  background-color: #fe3183; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected {
    background-color: #d30155; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected:hover {
    background-color: #c4014f; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-pink.b-dragging .b-sch-event {
  background-color: #c4014f; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-pink .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #fe3183; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-pink .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-pink .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #d30155; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-pink .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #c4014f; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap .b-sch-event {
  background: #fe3183;
  border-top: 1px solid #d30155;
  border-bottom: 1px solid #d30155; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap .b-sch-event :before {
    border-color: #d30155;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap .b-sch-event :after {
    border-color: #d30155;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #d30155; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #c4014f; }

.b-sch-style-border.b-sch-color-purple .b-sch-event {
  border-color: #d301bf; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event {
  background-color: #fe31ea; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected {
    background-color: #d301bf; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected:hover {
    background-color: #c401b1; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-purple.b-dragging .b-sch-event {
  background-color: #c401b1; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-purple .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #fe31ea; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-purple .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-purple .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #d301bf; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-purple .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #c401b1; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap .b-sch-event {
  background: #fe31ea;
  border-top: 1px solid #d301bf;
  border-bottom: 1px solid #d301bf; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap .b-sch-event :before {
    border-color: #d301bf;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap .b-sch-event :after {
    border-color: #d301bf;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #d301bf; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #c401b1; }

.b-sch-style-border.b-sch-color-violet .b-sch-event {
  border-color: #7f01d3; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event {
  background-color: #ac31fe; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected {
    background-color: #7f01d3; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected:hover {
    background-color: #7601c4; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-violet.b-dragging .b-sch-event {
  background-color: #7601c4; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-violet .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #ac31fe; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-violet .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-violet .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #7f01d3; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-violet .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #7601c4; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap .b-sch-event {
  background: #ac31fe;
  border-top: 1px solid #7f01d3;
  border-bottom: 1px solid #7f01d3; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap .b-sch-event :before {
    border-color: #7f01d3;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap .b-sch-event :after {
    border-color: #7f01d3;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #7f01d3; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #7601c4; }

.b-sch-style-border.b-sch-color-indigo .b-sch-event {
  border-color: #1601d3; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event {
  background-color: #4531fe; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected {
    background-color: #1601d3; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover {
    background-color: #1401c4; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-indigo.b-dragging .b-sch-event {
  background-color: #1401c4; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-indigo .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #4531fe; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-indigo .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-indigo .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #1601d3; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-indigo .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #1401c4; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap .b-sch-event {
  background: #4531fe;
  border-top: 1px solid #1601d3;
  border-bottom: 1px solid #1601d3; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap .b-sch-event :before {
    border-color: #1601d3;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap .b-sch-event :after {
    border-color: #1601d3;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #1601d3; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #1401c4; }

.b-sch-style-border.b-sch-color-blue .b-sch-event {
  border-color: #0155d3; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event {
  background-color: #3183fe; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected {
    background-color: #0155d3; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected:hover {
    background-color: #014fc4; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-blue.b-dragging .b-sch-event {
  background-color: #014fc4; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-blue .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #3183fe; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-blue .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-blue .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #0155d3; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-blue .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #014fc4; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap .b-sch-event {
  background: #3183fe;
  border-top: 1px solid #0155d3;
  border-bottom: 1px solid #0155d3; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap .b-sch-event :before {
    border-color: #0155d3;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap .b-sch-event :after {
    border-color: #0155d3;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #0155d3; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #014fc4; }

.b-sch-style-border.b-sch-color-cyan .b-sch-event {
  border-color: #01bed3; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event {
  background-color: #31e9fe; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected {
    background-color: #01bed3; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover {
    background-color: #01b0c4; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-cyan.b-dragging .b-sch-event {
  background-color: #01b0c4; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-cyan .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #31e9fe; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-cyan .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-cyan .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #01bed3; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-cyan .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #01b0c4; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap .b-sch-event {
  background: #31e9fe;
  border-top: 1px solid #01bed3;
  border-bottom: 1px solid #01bed3; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap .b-sch-event :before {
    border-color: #01bed3;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap .b-sch-event :after {
    border-color: #01bed3;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #01bed3; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #01b0c4; }

.b-sch-style-border.b-sch-color-teal .b-sch-event {
  border-color: #01d37f; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event {
  background-color: #31feac; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected {
    background-color: #01d37f; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected:hover {
    background-color: #01c476; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-teal.b-dragging .b-sch-event {
  background-color: #01c476; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-teal .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #31feac; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-teal .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-teal .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #01d37f; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-teal .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #01c476; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap .b-sch-event {
  background: #31feac;
  border-top: 1px solid #01d37f;
  border-bottom: 1px solid #01d37f; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap .b-sch-event :before {
    border-color: #01d37f;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap .b-sch-event :after {
    border-color: #01d37f;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #01d37f; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #01c476; }

.b-sch-style-border.b-sch-color-green .b-sch-event {
  border-color: #1b8d27; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-green .b-sch-event {
  background-color: #27ca37; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-green .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected {
    background-color: #1b8d27; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected:hover {
    background-color: #198324; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-green.b-dragging .b-sch-event {
  background-color: #198324; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-green .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #27ca37; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-green .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-green .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #1b8d27; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-green .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #198324; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap .b-sch-event {
  background: #27ca37;
  border-top: 1px solid #1b8d27;
  border-bottom: 1px solid #1b8d27; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap .b-sch-event :before {
    border-color: #1b8d27;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap .b-sch-event :after {
    border-color: #1b8d27;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #1b8d27; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #198324; }

.b-sch-style-border.b-sch-color-lime .b-sch-event {
  border-color: #50ab14; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event {
  background-color: #76e62c; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected {
    background-color: #50ab14; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected:hover {
    background-color: #4b9f13; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-lime.b-dragging .b-sch-event {
  background-color: #4b9f13; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-lime .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #76e62c; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-lime .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-lime .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #50ab14; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-lime .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #4b9f13; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap .b-sch-event {
  background: #76e62c;
  border-top: 1px solid #50ab14;
  border-bottom: 1px solid #50ab14; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap .b-sch-event :before {
    border-color: #50ab14;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap .b-sch-event :after {
    border-color: #50ab14;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #50ab14; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #4b9f13; }

.b-sch-style-border.b-sch-color-yellow .b-sch-event {
  border-color: #dcc500; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event {
  background-color: #FFEB3B; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected {
    background-color: #dcc500; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover {
    background-color: #ccb700; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-yellow.b-dragging .b-sch-event {
  background-color: #ccb700; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-yellow .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #FFEB3B; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-yellow .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-yellow .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #dcc500; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-yellow .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #ccb700; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap .b-sch-event {
  background: #FFEB3B;
  border-top: 1px solid #dcc500;
  border-bottom: 1px solid #dcc500; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap .b-sch-event :before {
    border-color: #dcc500;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap .b-sch-event :after {
    border-color: #dcc500;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #dcc500; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #ccb700; }

.b-sch-style-border.b-sch-color-orange .b-sch-event {
  border-color: #d37f01; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event {
  background-color: #feac31; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected {
    background-color: #d37f01; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected:hover {
    background-color: #c47601; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-orange.b-dragging .b-sch-event {
  background-color: #c47601; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-orange .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #feac31; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-orange .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-orange .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #d37f01; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-orange .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #c47601; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap .b-sch-event {
  background: #feac31;
  border-top: 1px solid #d37f01;
  border-bottom: 1px solid #d37f01; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap .b-sch-event :before {
    border-color: #d37f01;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap .b-sch-event :after {
    border-color: #d37f01;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #d37f01; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #c47601; }

.b-sch-style-border.b-sch-color-gray .b-sch-event {
  border-color: #707070; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event {
  background-color: #a0a0a0; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected {
    background-color: #707070; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected:hover {
    background-color: #686868; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-gray.b-dragging .b-sch-event {
  background-color: #686868; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-gray .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #a0a0a0; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-gray .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-gray .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #707070; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-gray .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #686868; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap .b-sch-event {
  background: #a0a0a0;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap .b-sch-event :before {
    border-color: #707070;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap .b-sch-event :after {
    border-color: #707070;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #707070; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #686868; }

.b-sch-style-hollow.b-sch-event-wrap .b-sch-event {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: #27ca37;
  border-style: solid;
  border-width: 0;
  font-weight: 600;
  opacity: .8;
  border-radius: 3px; }
  .b-sch-style-hollow.b-sch-event-wrap .b-sch-event:not(.b-milestone) {
    color: #27ca37;
    font-size: 11px;
    border-width: 2px; }
    .b-sch-style-hollow.b-sch-event-wrap .b-sch-event:not(.b-milestone).b-sch-event-startsoutside {
      border-left: none; }
    .b-sch-style-hollow.b-sch-event-wrap .b-sch-event:not(.b-milestone):hover, .b-sch-style-hollow.b-sch-event-wrap .b-sch-event:not(.b-milestone).b-sch-event-selected {
      color: #fff;
      background-image: none; }
  .b-sch-style-hollow.b-sch-event-wrap .b-sch-event.b-milestone:before {
    background-color: rgba(255, 255, 255, 0.5);
    border-width: 2px;
    border-radius: 3px;
    opacity: .8; }
  .b-sch-style-hollow.b-sch-event-wrap .b-sch-event.b-milestone:hover, .b-sch-style-hollow.b-sch-event-wrap .b-sch-event.b-milestone.b-sch-event-selected {
    background-color: #27ca37;
    opacity: 1;
    background-image: none; }
  .b-sch-style-hollow.b-sch-event-wrap .b-sch-event.b-milestone.b-sch-event-selected {
    font-weight: 600; }
  .b-sch-style-hollow.b-sch-event-wrap .b-sch-event.b-milestone.b-sch-event-selected:hover {
    background-color: #23b632;
    background-image: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-red .b-sch-event {
  border-color: #e53f2c;
  color: #e53f2c; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-red .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-resizing {
  background-color: #e53f2c;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected:hover {
  background-color: #db2f1b; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-red.b-dragging .b-sch-event {
  background-color: #e53f2c;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-red .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-red .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-red .b-sch-event.b-dragging:before {
  background-color: #e53f2c; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-red .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #db2f1b; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone > * {
  background: none;
  color: #e53f2c;
  border-top: 2px solid #e53f2c;
  border-bottom: 2px solid #e53f2c;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone > * :before {
    border-color: #e53f2c;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone > * :after {
    border-color: #e53f2c;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-pink .b-sch-event {
  border-color: #fe3183;
  color: #fe3183; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-resizing {
  background-color: #fe3183;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected:hover {
  background-color: #fe1371; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-pink.b-dragging .b-sch-event {
  background-color: #fe3183;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-pink .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-pink .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-pink .b-sch-event.b-dragging:before {
  background-color: #fe3183; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-pink .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #fe1371; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone > * {
  background: none;
  color: #fe3183;
  border-top: 2px solid #fe3183;
  border-bottom: 2px solid #fe3183;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone > * :before {
    border-color: #fe3183;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone > * :after {
    border-color: #fe3183;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-purple .b-sch-event {
  border-color: #fe31ea;
  color: #fe31ea; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-resizing {
  background-color: #fe31ea;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected:hover {
  background-color: #fe13e7; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-purple.b-dragging .b-sch-event {
  background-color: #fe31ea;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-purple .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-purple .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-purple .b-sch-event.b-dragging:before {
  background-color: #fe31ea; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-purple .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #fe13e7; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone > * {
  background: none;
  color: #fe31ea;
  border-top: 2px solid #fe31ea;
  border-bottom: 2px solid #fe31ea;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone > * :before {
    border-color: #fe31ea;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone > * :after {
    border-color: #fe31ea;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-violet .b-sch-event {
  border-color: #ac31fe;
  color: #ac31fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-resizing {
  background-color: #ac31fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected:hover {
  background-color: #a013fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-violet.b-dragging .b-sch-event {
  background-color: #ac31fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-violet .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-violet .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-violet .b-sch-event.b-dragging:before {
  background-color: #ac31fe; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-violet .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #a013fe; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone > * {
  background: none;
  color: #ac31fe;
  border-top: 2px solid #ac31fe;
  border-bottom: 2px solid #ac31fe;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone > * :before {
    border-color: #ac31fe;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone > * :after {
    border-color: #ac31fe;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-indigo .b-sch-event {
  border-color: #4531fe;
  color: #4531fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-resizing {
  background-color: #4531fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover {
  background-color: #2a13fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-indigo.b-dragging .b-sch-event {
  background-color: #4531fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-indigo .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-indigo .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-indigo .b-sch-event.b-dragging:before {
  background-color: #4531fe; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #2a13fe; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone > * {
  background: none;
  color: #4531fe;
  border-top: 2px solid #4531fe;
  border-bottom: 2px solid #4531fe;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone > * :before {
    border-color: #4531fe;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone > * :after {
    border-color: #4531fe;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-blue .b-sch-event {
  border-color: #3183fe;
  color: #3183fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-resizing {
  background-color: #3183fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected:hover {
  background-color: #1371fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-blue.b-dragging .b-sch-event {
  background-color: #3183fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-blue .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-blue .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-blue .b-sch-event.b-dragging:before {
  background-color: #3183fe; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-blue .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #1371fe; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone > * {
  background: none;
  color: #3183fe;
  border-top: 2px solid #3183fe;
  border-bottom: 2px solid #3183fe;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone > * :before {
    border-color: #3183fe;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone > * :after {
    border-color: #3183fe;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-cyan .b-sch-event {
  border-color: #31e9fe;
  color: #31e9fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-resizing {
  background-color: #31e9fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover {
  background-color: #13e6fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-cyan.b-dragging .b-sch-event {
  background-color: #31e9fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-cyan .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-cyan .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-cyan .b-sch-event.b-dragging:before {
  background-color: #31e9fe; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #13e6fe; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone > * {
  background: none;
  color: #31e9fe;
  border-top: 2px solid #31e9fe;
  border-bottom: 2px solid #31e9fe;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone > * :before {
    border-color: #31e9fe;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone > * :after {
    border-color: #31e9fe;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-teal .b-sch-event {
  border-color: #31feac;
  color: #31feac; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-resizing {
  background-color: #31feac;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected:hover {
  background-color: #13fea0; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-teal.b-dragging .b-sch-event {
  background-color: #31feac;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-teal .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-teal .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-teal .b-sch-event.b-dragging:before {
  background-color: #31feac; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-teal .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #13fea0; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone > * {
  background: none;
  color: #31feac;
  border-top: 2px solid #31feac;
  border-bottom: 2px solid #31feac;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone > * :before {
    border-color: #31feac;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone > * :after {
    border-color: #31feac;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-green .b-sch-event {
  border-color: #27ca37;
  color: #27ca37; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-green .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-resizing {
  background-color: #27ca37;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected:hover {
  background-color: #23b632; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-green.b-dragging .b-sch-event {
  background-color: #27ca37;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-green .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-green .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-green .b-sch-event.b-dragging:before {
  background-color: #27ca37; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-green .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #23b632; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone > * {
  background: none;
  color: #27ca37;
  border-top: 2px solid #27ca37;
  border-bottom: 2px solid #27ca37;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone > * :before {
    border-color: #27ca37;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone > * :after {
    border-color: #27ca37;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-lime .b-sch-event {
  border-color: #76e62c;
  color: #76e62c; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-resizing {
  background-color: #76e62c;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected:hover {
  background-color: #67dc1a; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-lime.b-dragging .b-sch-event {
  background-color: #76e62c;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-lime .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-lime .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-lime .b-sch-event.b-dragging:before {
  background-color: #76e62c; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-lime .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #67dc1a; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone > * {
  background: none;
  color: #76e62c;
  border-top: 2px solid #76e62c;
  border-bottom: 2px solid #76e62c;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone > * :before {
    border-color: #76e62c;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone > * :after {
    border-color: #76e62c;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-yellow .b-sch-event {
  border-color: #FFEB3B;
  color: #FFEB3B; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-resizing {
  background-color: #FFEB3B;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover {
  background-color: #ffe81c; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-yellow.b-dragging .b-sch-event {
  background-color: #FFEB3B;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-yellow .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-yellow .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-yellow .b-sch-event.b-dragging:before {
  background-color: #FFEB3B; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #ffe81c; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone > * {
  background: none;
  color: #FFEB3B;
  border-top: 2px solid #FFEB3B;
  border-bottom: 2px solid #FFEB3B;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone > * :before {
    border-color: #FFEB3B;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone > * :after {
    border-color: #FFEB3B;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-orange .b-sch-event {
  border-color: #feac31;
  color: #feac31; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-resizing {
  background-color: #feac31;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected:hover {
  background-color: #fea013; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-orange.b-dragging .b-sch-event {
  background-color: #feac31;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-orange .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-orange .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-orange .b-sch-event.b-dragging:before {
  background-color: #feac31; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-orange .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #fea013; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone > * {
  background: none;
  color: #feac31;
  border-top: 2px solid #feac31;
  border-bottom: 2px solid #feac31;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone > * :before {
    border-color: #feac31;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone > * :after {
    border-color: #feac31;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-gray .b-sch-event {
  border-color: #a0a0a0;
  color: #a0a0a0; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-resizing {
  background-color: #a0a0a0;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected:hover {
  background-color: #909090; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-gray.b-dragging .b-sch-event {
  background-color: #a0a0a0;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-gray .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-gray .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-gray .b-sch-event.b-dragging:before {
  background-color: #a0a0a0; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-gray .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #909090; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone > * {
  background: none;
  color: #a0a0a0;
  border-top: 2px solid #a0a0a0;
  border-bottom: 2px solid #a0a0a0;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone > * :before {
    border-color: #a0a0a0;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone > * :after {
    border-color: #a0a0a0;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-colored.b-sch-event-wrap .b-sch-event {
  background-color: #d2f6d5;
  border-color: #23b632;
  border-left-width: 3px;
  border-left-style: solid;
  font-weight: 500;
  opacity: .8; }
  .b-sch-style-colored.b-sch-event-wrap .b-sch-event:not(.b-milestone) {
    color: #1b8d27; }
  .b-sch-style-colored.b-sch-event-wrap .b-sch-event:hover, .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-resizing, .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-selected {
    background-color: #27ca37;
    background-image: none;
    opacity: 1; }
    .b-sch-style-colored.b-sch-event-wrap .b-sch-event:hover:not(.b-milestone), .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-selected {
    font-weight: 500;
    opacity: 1; }
  .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-selected:hover {
    background-color: #1b8d27;
    background-image: none; }
  .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-startsoutside {
    border-left: none; }
  .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone {
    border-width: 0; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap .b-sch-event,
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap::after {
    background: #d2f6d5; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap::after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap::before {
    border-top: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 3px solid #23b632;
    border-left: 3px solid #23b632; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap:hover::after {
    background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap.b-sch-event-selected:hover::after {
    background: none; }
  .b-sch-vertical .b-sch-style-colored.b-sch-event-wrap .b-sch-event {
    border-top-width: 3px;
    border-top-style: solid;
    border-left-width: 0;
    border-left-style: none; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event {
  background-color: #fad9d5;
  border-color: #db2f1b; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event:not(.b-milestone) {
    color: #aa2415; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event:hover {
    background-color: #f5b2ab; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected {
    background-color: #e53f2c; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected:hover {
    background-color: #db2f1b; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-red:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-red:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #fad9d5;
    border: 3px solid #db2f1b; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-red:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #f5b2ab; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-red:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #e53f2c; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-red:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #db2f1b; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone::after {
  background: #fad9d5; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone > * {
  width: 100%;
  color: #aa2415;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone::before {
  border-bottom-color: #db2f1b;
  border-left-color: #db2f1b; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone:hover::after {
  background: #f5b2ab; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #e53f2c; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #db2f1b; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event {
  background-color: #ffd6e6;
  border-color: #fe1371; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event:not(.b-milestone) {
    color: #d30155; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event:hover {
    background-color: #ffadcd; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected {
    background-color: #fe3183; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected:hover {
    background-color: #fe1371; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-pink:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-pink:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #ffd6e6;
    border: 3px solid #fe1371; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-pink:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #ffadcd; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-pink:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #fe3183; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-pink:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #fe1371; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone::after {
  background: #ffd6e6; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone > * {
  width: 100%;
  color: #d30155;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone::before {
  border-bottom-color: #fe1371;
  border-left-color: #fe1371; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone:hover::after {
  background: #ffadcd; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #fe3183; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #fe1371; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event {
  background-color: #ffd6fb;
  border-color: #fe13e7; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event:not(.b-milestone) {
    color: #d301bf; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event:hover {
    background-color: #ffadf7; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected {
    background-color: #fe31ea; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected:hover {
    background-color: #fe13e7; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-purple:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-purple:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #ffd6fb;
    border: 3px solid #fe13e7; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-purple:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #ffadf7; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-purple:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #fe31ea; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-purple:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #fe13e7; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone::after {
  background: #ffd6fb; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone > * {
  width: 100%;
  color: #d301bf;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone::before {
  border-bottom-color: #fe13e7;
  border-left-color: #fe13e7; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone:hover::after {
  background: #ffadf7; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #fe31ea; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #fe13e7; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event {
  background-color: #eed6ff;
  border-color: #a013fe; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event:not(.b-milestone) {
    color: #7f01d3; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event:hover {
    background-color: #deadff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected {
    background-color: #ac31fe; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected:hover {
    background-color: #a013fe; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-violet:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-violet:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #eed6ff;
    border: 3px solid #a013fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-violet:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #deadff; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-violet:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #ac31fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-violet:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #a013fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone::after {
  background: #eed6ff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone > * {
  width: 100%;
  color: #7f01d3;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone::before {
  border-bottom-color: #a013fe;
  border-left-color: #a013fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone:hover::after {
  background: #deadff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #ac31fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #a013fe; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event {
  background-color: #dad6ff;
  border-color: #2a13fe; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event:not(.b-milestone) {
    color: #1601d3; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event:hover {
    background-color: #b5adff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected {
    background-color: #4531fe; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover {
    background-color: #2a13fe; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-indigo:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-indigo:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #dad6ff;
    border: 3px solid #2a13fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-indigo:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #b5adff; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-indigo:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #4531fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-indigo:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #2a13fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone::after {
  background: #dad6ff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone > * {
  width: 100%;
  color: #1601d3;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone::before {
  border-bottom-color: #2a13fe;
  border-left-color: #2a13fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone:hover::after {
  background: #b5adff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #4531fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #2a13fe; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event {
  background-color: #d6e6ff;
  border-color: #1371fe; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event:not(.b-milestone) {
    color: #0155d3; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event:hover {
    background-color: #adcdff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected {
    background-color: #3183fe; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected:hover {
    background-color: #1371fe; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-blue:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-blue:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #d6e6ff;
    border: 3px solid #1371fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-blue:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #adcdff; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-blue:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #3183fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-blue:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #1371fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone::after {
  background: #d6e6ff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone > * {
  width: 100%;
  color: #0155d3;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone::before {
  border-bottom-color: #1371fe;
  border-left-color: #1371fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone:hover::after {
  background: #adcdff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #3183fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #1371fe; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event {
  background-color: #d6fbff;
  border-color: #13e6fe; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event:not(.b-milestone) {
    color: #01bed3; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event:hover {
    background-color: #adf6ff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected {
    background-color: #31e9fe; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover {
    background-color: #13e6fe; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-cyan:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-cyan:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #d6fbff;
    border: 3px solid #13e6fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-cyan:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #adf6ff; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-cyan:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #31e9fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-cyan:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #13e6fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone::after {
  background: #d6fbff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone > * {
  width: 100%;
  color: #01bed3;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone::before {
  border-bottom-color: #13e6fe;
  border-left-color: #13e6fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone:hover::after {
  background: #adf6ff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #31e9fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #13e6fe; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event {
  background-color: #d6ffee;
  border-color: #13fea0; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event:not(.b-milestone) {
    color: #01d37f; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event:hover {
    background-color: #adffde; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected {
    background-color: #31feac; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected:hover {
    background-color: #13fea0; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-teal:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-teal:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #d6ffee;
    border: 3px solid #13fea0; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-teal:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #adffde; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-teal:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #31feac; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-teal:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #13fea0; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone::after {
  background: #d6ffee; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone > * {
  width: 100%;
  color: #01d37f;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone::before {
  border-bottom-color: #13fea0;
  border-left-color: #13fea0; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone:hover::after {
  background: #adffde; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #31feac; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #13fea0; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event {
  background-color: #d2f6d5;
  border-color: #23b632; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event:not(.b-milestone) {
    color: #1b8d27; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event:hover {
    background-color: #a5eeac; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected {
    background-color: #27ca37; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected:hover {
    background-color: #23b632; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-green:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-green:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #d2f6d5;
    border: 3px solid #23b632; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-green:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #a5eeac; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-green:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #27ca37; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-green:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #23b632; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone::after {
  background: #d2f6d5; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone > * {
  width: 100%;
  color: #1b8d27;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone::before {
  border-bottom-color: #23b632;
  border-left-color: #23b632; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone:hover::after {
  background: #a5eeac; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #27ca37; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #23b632; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event {
  background-color: #e4fad5;
  border-color: #67dc1a; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event:not(.b-milestone) {
    color: #50ab14; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event:hover {
    background-color: #c8f5ab; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected {
    background-color: #76e62c; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected:hover {
    background-color: #67dc1a; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-lime:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-lime:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #e4fad5;
    border: 3px solid #67dc1a; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-lime:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #c8f5ab; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-lime:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #76e62c; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-lime:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #67dc1a; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone::after {
  background: #e4fad5; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone > * {
  width: 100%;
  color: #50ab14;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone::before {
  border-bottom-color: #67dc1a;
  border-left-color: #67dc1a; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone:hover::after {
  background: #c8f5ab; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #76e62c; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #67dc1a; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event {
  background-color: #fffbd8;
  border-color: #ffe81c; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:not(.b-milestone) {
    color: #dcc500; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:hover {
    background-color: #fff7b1; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected {
    background-color: #FFEB3B; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover {
    background-color: #ffe81c; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-yellow:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-yellow:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #fffbd8;
    border: 3px solid #ffe81c; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-yellow:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #fff7b1; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-yellow:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #FFEB3B; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-yellow:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #ffe81c; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone::after {
  background: #fffbd8; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone > * {
  width: 100%;
  color: #dcc500;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone::before {
  border-bottom-color: #ffe81c;
  border-left-color: #ffe81c; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone:hover::after {
  background: #fff7b1; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #FFEB3B; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #ffe81c; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event {
  background-color: #ffeed6;
  border-color: #fea013; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event:not(.b-milestone) {
    color: #d37f01; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event:hover {
    background-color: navajowhite; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected {
    background-color: #feac31; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected:hover {
    background-color: #fea013; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-orange:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-orange:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #ffeed6;
    border: 3px solid #fea013; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-orange:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: navajowhite; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-orange:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #feac31; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-orange:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #fea013; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone::after {
  background: #ffeed6; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone > * {
  width: 100%;
  color: #d37f01;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone::before {
  border-bottom-color: #fea013;
  border-left-color: #fea013; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone:hover::after {
  background: navajowhite; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #feac31; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #fea013; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event {
  background-color: #ececec;
  border-color: #909090; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event:not(.b-milestone) {
    color: #707070; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event:hover {
    background-color: #d9d9d9; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected {
    background-color: #a0a0a0; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected:hover {
    background-color: #909090; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-gray:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-gray:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #ececec;
    border: 3px solid #909090; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-gray:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #d9d9d9; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-gray:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #a0a0a0; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-gray:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #909090; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone::after {
  background: #ececec; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone > * {
  width: 100%;
  color: #707070;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone::before {
  border-bottom-color: #909090;
  border-left-color: #909090; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone:hover::after {
  background: #d9d9d9; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #a0a0a0; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #909090; }

.b-sch-event-wrap.b-sch-style-line .b-sch-event {
  background-color: transparent;
  border-color: #27ca37;
  border-width: 5px 0 0 0;
  border-style: solid;
  padding: 5px 0 0 0;
  color: #777;
  font-weight: 500;
  -ms-flex-pack: center;
      justify-content: center;
  overflow: visible;
  top: 5px;
  height: auto !important; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-active {
    outline-offset: 5px; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event:not(.b-milestone)::before, .b-sch-event-wrap.b-sch-style-line .b-sch-event:not(.b-milestone)::after {
    content: '';
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    height: 13px;
    position: absolute;
    top: -9px;
    border-radius: 2px; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event:not(.b-milestone)::before {
    border-left-width: 5px;
    left: 0; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event:not(.b-milestone)::after {
    border-right-width: 5px;
    right: 0; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-milestone {
    top: auto;
    top: initial;
    border-width: 0; }
    .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-milestone:before {
      background-color: transparent;
      border-width: 5px;
      border-style: solid; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-sch-event-selected, .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-sch-event-resizing {
    background-color: transparent;
    border-color: #1d9829;
    background-image: none; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-sch-event-selected {
    font-weight: 500; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-sch-event-selected:hover {
    background-color: transparent;
    border-color: #1b8d27;
    background-image: none; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-red .b-sch-event {
  border-color: #e53f2c;
  color: #e53f2c; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-red .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-red .b-sch-event.b-sch-event-selected {
    border-color: #b62716; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-red .b-sch-event.b-sch-event-selected:hover {
    border-color: #aa2415; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-pink .b-sch-event {
  border-color: #fe3183;
  color: #fe3183; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-pink .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-pink .b-sch-event.b-sch-event-selected {
    border-color: #e2015b; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-pink .b-sch-event.b-sch-event-selected:hover {
    border-color: #d30155; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-purple .b-sch-event {
  border-color: #fe31ea;
  color: #fe31ea; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-purple .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-purple .b-sch-event.b-sch-event-selected {
    border-color: #e201cc; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-purple .b-sch-event.b-sch-event-selected:hover {
    border-color: #d301bf; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-violet .b-sch-event {
  border-color: #ac31fe;
  color: #ac31fe; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-violet .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-violet .b-sch-event.b-sch-event-selected {
    border-color: #8801e2; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-violet .b-sch-event.b-sch-event-selected:hover {
    border-color: #7f01d3; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-indigo .b-sch-event {
  border-color: #4531fe;
  color: #4531fe; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-indigo .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-indigo .b-sch-event.b-sch-event-selected {
    border-color: #1701e2; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover {
    border-color: #1601d3; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-blue .b-sch-event {
  border-color: #3183fe;
  color: #3183fe; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-blue .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-blue .b-sch-event.b-sch-event-selected {
    border-color: #015be2; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-blue .b-sch-event.b-sch-event-selected:hover {
    border-color: #0155d3; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-cyan .b-sch-event {
  border-color: #31e9fe;
  color: #31e9fe; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-cyan .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-cyan .b-sch-event.b-sch-event-selected {
    border-color: #01cbe2; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover {
    border-color: #01bed3; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-teal .b-sch-event {
  border-color: #31feac;
  color: #31feac; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-teal .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-teal .b-sch-event.b-sch-event-selected {
    border-color: #01e288; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-teal .b-sch-event.b-sch-event-selected:hover {
    border-color: #01d37f; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-green .b-sch-event {
  border-color: #27ca37;
  color: #27ca37; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-green .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-green .b-sch-event.b-sch-event-selected {
    border-color: #1d9829; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-green .b-sch-event.b-sch-event-selected:hover {
    border-color: #1b8d27; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-lime .b-sch-event {
  border-color: #76e62c;
  color: #76e62c; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-lime .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-lime .b-sch-event.b-sch-event-selected {
    border-color: #56b816; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-lime .b-sch-event.b-sch-event-selected:hover {
    border-color: #50ab14; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-yellow .b-sch-event {
  border-color: #FFEB3B;
  color: #FFEB3B; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-yellow .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-yellow .b-sch-event.b-sch-event-selected {
    border-color: #ecd300; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover {
    border-color: #dcc500; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-orange .b-sch-event {
  border-color: #feac31;
  color: #feac31; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-orange .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-orange .b-sch-event.b-sch-event-selected {
    border-color: #e28801; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-orange .b-sch-event.b-sch-event-selected:hover {
    border-color: #d37f01; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-gray .b-sch-event {
  border-color: #a0a0a0;
  color: #a0a0a0; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-gray .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-gray .b-sch-event.b-sch-event-selected {
    border-color: #787878; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-gray .b-sch-event.b-sch-event-selected:hover {
    border-color: #707070; }

.b-sch-event-wrap.b-sch-style-dashed .b-sch-event {
  background-color: transparent;
  border-color: #27ca37;
  border-width: 5px 0 0 0;
  border-style: dashed;
  padding: 5px 0 3px 0;
  color: #777;
  font-weight: 500;
  -ms-flex-pack: center;
      justify-content: center;
  overflow: visible;
  top: 5px;
  height: auto !important; }
  .b-sch-event-wrap.b-sch-style-dashed .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed .b-sch-event.b-sch-event-selected, .b-sch-event-wrap.b-sch-style-dashed .b-sch-event.b-sch-event-resizing {
    background-color: transparent;
    border-color: #1d9829;
    background-image: none; }
  .b-sch-event-wrap.b-sch-style-dashed .b-sch-event.b-sch-event-selected {
    font-weight: 500; }
  .b-sch-event-wrap.b-sch-style-dashed .b-sch-event.b-sch-event-selected:hover {
    background-color: transparent;
    border-color: #1b8d27;
    background-image: none; }
  .b-sch-event-wrap.b-sch-style-dashed .b-sch-event.b-milestone {
    top: auto;
    top: initial;
    border-width: 0; }
    .b-sch-event-wrap.b-sch-style-dashed .b-sch-event.b-milestone:before {
      background-color: transparent;
      border-width: 5px;
      border-style: dashed; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-red .b-sch-event {
  border-color: #e53f2c;
  color: #e53f2c; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-red .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-red .b-sch-event.b-sch-event-selected {
    border-color: #b62716; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-red .b-sch-event.b-sch-event-selected:hover {
    border-color: #aa2415; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-pink .b-sch-event {
  border-color: #fe3183;
  color: #fe3183; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-pink .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-pink .b-sch-event.b-sch-event-selected {
    border-color: #e2015b; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-pink .b-sch-event.b-sch-event-selected:hover {
    border-color: #d30155; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-purple .b-sch-event {
  border-color: #fe31ea;
  color: #fe31ea; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-purple .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-purple .b-sch-event.b-sch-event-selected {
    border-color: #e201cc; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-purple .b-sch-event.b-sch-event-selected:hover {
    border-color: #d301bf; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-violet .b-sch-event {
  border-color: #ac31fe;
  color: #ac31fe; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-violet .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-violet .b-sch-event.b-sch-event-selected {
    border-color: #8801e2; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-violet .b-sch-event.b-sch-event-selected:hover {
    border-color: #7f01d3; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-indigo .b-sch-event {
  border-color: #4531fe;
  color: #4531fe; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-indigo .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-indigo .b-sch-event.b-sch-event-selected {
    border-color: #1701e2; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover {
    border-color: #1601d3; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-blue .b-sch-event {
  border-color: #3183fe;
  color: #3183fe; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-blue .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-blue .b-sch-event.b-sch-event-selected {
    border-color: #015be2; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-blue .b-sch-event.b-sch-event-selected:hover {
    border-color: #0155d3; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-cyan .b-sch-event {
  border-color: #31e9fe;
  color: #31e9fe; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-cyan .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-cyan .b-sch-event.b-sch-event-selected {
    border-color: #01cbe2; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover {
    border-color: #01bed3; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-teal .b-sch-event {
  border-color: #31feac;
  color: #31feac; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-teal .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-teal .b-sch-event.b-sch-event-selected {
    border-color: #01e288; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-teal .b-sch-event.b-sch-event-selected:hover {
    border-color: #01d37f; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-green .b-sch-event {
  border-color: #27ca37;
  color: #27ca37; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-green .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-green .b-sch-event.b-sch-event-selected {
    border-color: #1d9829; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-green .b-sch-event.b-sch-event-selected:hover {
    border-color: #1b8d27; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-lime .b-sch-event {
  border-color: #76e62c;
  color: #76e62c; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-lime .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-lime .b-sch-event.b-sch-event-selected {
    border-color: #56b816; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-lime .b-sch-event.b-sch-event-selected:hover {
    border-color: #50ab14; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-yellow .b-sch-event {
  border-color: #FFEB3B;
  color: #FFEB3B; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-yellow .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-yellow .b-sch-event.b-sch-event-selected {
    border-color: #ecd300; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover {
    border-color: #dcc500; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-orange .b-sch-event {
  border-color: #feac31;
  color: #feac31; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-orange .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-orange .b-sch-event.b-sch-event-selected {
    border-color: #e28801; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-orange .b-sch-event.b-sch-event-selected:hover {
    border-color: #d37f01; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-gray .b-sch-event {
  border-color: #a0a0a0;
  color: #a0a0a0; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-gray .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-gray .b-sch-event.b-sch-event-selected {
    border-color: #787878; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-gray .b-sch-event.b-sch-event-selected:hover {
    border-color: #707070; }

.b-sch-style-minimal:not(.b-milestone) .b-sch-event {
  background-color: transparent;
  border-color: #27ca37;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 0;
  color: #777;
  font-weight: 500;
  overflow: visible;
  font-size: 10px;
  -ms-flex-align: end;
      align-items: flex-end; }
  .b-sch-style-minimal:not(.b-milestone) .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-sch-event-selected, .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-sch-event-resizing {
    background-color: transparent;
    border-color: #1d9829;
    background-image: none; }
  .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-sch-event-selected {
    font-weight: 500; }
  .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-sch-event-selected:hover {
    background-color: transparent;
    border-color: #1b8d27;
    background-image: none; }
  .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-milestone {
    border-width: 0; }
    .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-milestone:before {
      border-width: 1px;
      border-style: solid; }
    .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-milestone label {
      font-size: 10px; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-red .b-sch-event {
  border-color: #e53f2c; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-red .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-red .b-sch-event.b-sch-event-selected {
    border-color: #b62716; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-red .b-sch-event.b-sch-event-selected:hover {
    border-color: #aa2415; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-pink .b-sch-event {
  border-color: #fe3183; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-pink .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-pink .b-sch-event.b-sch-event-selected {
    border-color: #e2015b; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-pink .b-sch-event.b-sch-event-selected:hover {
    border-color: #d30155; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-purple .b-sch-event {
  border-color: #fe31ea; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-purple .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-purple .b-sch-event.b-sch-event-selected {
    border-color: #e201cc; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-purple .b-sch-event.b-sch-event-selected:hover {
    border-color: #d301bf; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-violet .b-sch-event {
  border-color: #ac31fe; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-violet .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-violet .b-sch-event.b-sch-event-selected {
    border-color: #8801e2; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-violet .b-sch-event.b-sch-event-selected:hover {
    border-color: #7f01d3; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-indigo .b-sch-event {
  border-color: #4531fe; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-indigo .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-indigo .b-sch-event.b-sch-event-selected {
    border-color: #1701e2; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover {
    border-color: #1601d3; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-blue .b-sch-event {
  border-color: #3183fe; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-blue .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-blue .b-sch-event.b-sch-event-selected {
    border-color: #015be2; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-blue .b-sch-event.b-sch-event-selected:hover {
    border-color: #0155d3; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-cyan .b-sch-event {
  border-color: #31e9fe; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-cyan .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-cyan .b-sch-event.b-sch-event-selected {
    border-color: #01cbe2; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover {
    border-color: #01bed3; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-teal .b-sch-event {
  border-color: #31feac; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-teal .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-teal .b-sch-event.b-sch-event-selected {
    border-color: #01e288; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-teal .b-sch-event.b-sch-event-selected:hover {
    border-color: #01d37f; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-green .b-sch-event {
  border-color: #27ca37; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-green .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-green .b-sch-event.b-sch-event-selected {
    border-color: #1d9829; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-green .b-sch-event.b-sch-event-selected:hover {
    border-color: #1b8d27; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-lime .b-sch-event {
  border-color: #76e62c; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-lime .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-lime .b-sch-event.b-sch-event-selected {
    border-color: #56b816; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-lime .b-sch-event.b-sch-event-selected:hover {
    border-color: #50ab14; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-yellow .b-sch-event {
  border-color: #FFEB3B; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-yellow .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-yellow .b-sch-event.b-sch-event-selected {
    border-color: #ecd300; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover {
    border-color: #dcc500; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-orange .b-sch-event {
  border-color: #feac31; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-orange .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-orange .b-sch-event.b-sch-event-selected {
    border-color: #e28801; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-orange .b-sch-event.b-sch-event-selected:hover {
    border-color: #d37f01; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-gray .b-sch-event {
  border-color: #a0a0a0; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-gray .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-gray .b-sch-event.b-sch-event-selected {
    border-color: #787878; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-gray .b-sch-event.b-sch-event-selected:hover {
    border-color: #707070; }
/*# sourceMappingURL=scheduler.stockholm.css.map*/
/* .daterangepicker {
  right: 10px!important;
} */


/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

 .rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

